import React from "react";
import { customProductSchema } from "../../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import { amountTypeDropdown, customTypeDropdown } from "src/utils/constants";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { modifyDropDownValues, filterDropdownData } from "src/utils/helper";
import {
  searchProducts,
  searchDetailedSubCategories,
  searchSubCategories,
  searchCategories,
} from "src/utils";

const type = "customProducts";
const defaultFormValues = {
  rule_name: "",
  rule_description: "",
  is_front: true,
  is_back: false,
  price_type: amountTypeDropdown[0],
  price: "",
  type: customTypeDropdown[0],
  product_ids: "",
  category_ids: "",
  sub_category_ids: "",
  detailed_sub_category_ids: "",
  product_not_included: "",
};

const AddEditCustomProduct = ({
  isEdit,
  history,
  location: { state: customProduct },
}) => {
  if (isEdit && !customProduct) {
    history.push("/custom-products");
  }
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);

  let defaultValues = isEdit ? cloneDeep(customProduct) : defaultFormValues;
  if (isEdit) {
    defaultValues = {
      ...defaultValues,
      price_type: amountTypeDropdown.find(
        (v) => v.value === defaultValues.price_type
      ),
      type: customTypeDropdown.find((v) => v.value === defaultValues.type),
      category_ids: modifyDropDownValues(
        defaultValues.category_ids,
        "category_name",
        "_id"
      ),
      product_ids: filterDropdownData(defaultValues?.product_ids, [
        "category_id.category_name",
        "item_name",
        "product_sku",
      ]),
      sub_category_ids: filterDropdownData(defaultValues.sub_category_ids, [
        "category_id.category_name",
        "sub_category_name",
      ]),
      detailed_sub_category_ids: filterDropdownData(
        defaultValues.detailed_sub_category_ids,
        [
          "category_id.category_name",
          "sub_category_id.sub_category_name",
          "detailed_sub_category_name",
        ]
      ),
      product_not_included: filterDropdownData(
        defaultValues?.product_not_included,
        ["category_id.category_name", "item_name", "product_sku"]
      ),
    };
  }

  const onSubmit = (values) => {
    let data = {
      ...values,
      type: values.type.value,
      price_type: values.price_type.value,
      product_ids: map(values.product_ids, "value"),
      category_ids: map(values.category_ids, "value"),
      sub_category_ids: map(values.sub_category_ids, "value"),
      detailed_sub_category_ids: map(values.detailed_sub_category_ids, "value"),
      product_not_included: map(values.product_not_included, "value"),
    };
    if (isEdit) {
      delete data.status;
      delete data.createdAt;
    }
    switch (data.type) {
      case 1:
        data.category_ids = [];
        data.sub_category_ids = [];
        data.detailed_sub_category_ids = [];
        data.product_not_included = [];
        break;
      case 2:
        data.product_ids = [];
        data.sub_category_ids = [];
        data.detailed_sub_category_ids = [];
        break;
      case 3:
        data.category_ids = [];
        data.product_ids = [];
        data.detailed_sub_category_ids = [];
        break;
      case 4:
        data.category_ids = [];
        data.product_ids = [];
        data.sub_category_ids = [];
        break;
      default:
    }
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer
      lg={12}
      title={isEdit ? "Edit Personalized Product" : "Add Personalized Product"}
    >
      <Formik
        initialValues={defaultValues}
        validationSchema={customProductSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"Rule Name"}
              name={"rule_name"}
              value={values["rule_name"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["rule_name"] && touched["rule_name"]}
              error={errors["rule_name"]}
              placeholder={"Enter Rule name"}
            />
            <FormInput
              type="textarea"
              label={"Description"}
              name={"rule_description"}
              value={values["rule_description"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                errors["rule_description"] && touched["rule_description"]
              }
              error={errors["rule_description"]}
              placeholder={"Enter Description"}
            />
            <FormInput
              type="switch"
              label={"On Front"}
              name={"is_front"}
              value={values["is_front"]}
              onBlur={handleBlur}
              onChange={handleChange}
              invalid={errors["is_front"] && touched["is_front"]}
              error={errors["is_front"]}
            />
            <FormInput
              type="switch"
              label={"On Back"}
              name={"is_back"}
              value={values["is_back"]}
              onBlur={handleBlur}
              onChange={handleChange}
              invalid={errors["is_back"] && touched["is_back"]}
              error={errors["is_back"]}
            />
            <FormInput
              type="select"
              label={"Price Type"}
              name={"price_type"}
              value={values["price_type"]}
              onChange={(v) => setFieldValue("price_type", v)}
              options={amountTypeDropdown}
              invalid={errors["price_type"] && touched["price_type"]}
              error={errors["price_type"]}
              placeholder={"Select Price type"}
            />
            <FormInput
              type="number"
              label={"Price"}
              name={"price"}
              value={values["price"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["price"] && touched["price"]}
              error={errors["price"]}
              placeholder={"Enter Price"}
            />
            <FormInput
              type="select"
              label={"Select Type"}
              name={"type"}
              value={values["type"]}
              onChange={(v) => setFieldValue("type", v)}
              options={customTypeDropdown}
              invalid={errors["type"] && touched["type"]}
              error={errors["type"]}
              placeholder={"Select type"}
            />

            {values["type"].value === 1 ? (
              <FormInput
                type="select-async"
                label={"Select Products"}
                name={"product_ids"}
                value={values["product_ids"]}
                isMulti
                // cacheOptions
                loadOptions={searchProducts}
                onChange={(v) => setFieldValue("product_ids", v)}
                invalid={errors["product_ids"] && touched["product_ids"]}
                error={errors["product_ids"]}
                placeholder={"Select Products"}
              />
            ) : values["type"].value === 2 ? (
              <FormInput
                type="select-async"
                label={"Select Categories"}
                name={"category_ids"}
                value={values["category_ids"]}
                isMulti
                loadOptions={searchCategories}
                onChange={(v) => setFieldValue("category_ids", v)}
                invalid={errors["category_ids"] && touched["category_ids"]}
                error={errors["category_ids"]}
                placeholder={"Select Categories"}
              />
            ) : values["type"].value === 3 ? (
              <FormInput
                type="select-async"
                label={"Select Sub Categories"}
                name={"sub_category_ids"}
                value={values["sub_category_ids"]}
                isMulti
                loadOptions={searchSubCategories}
                onChange={(v) => setFieldValue("sub_category_ids", v)}
                invalid={
                  errors["sub_category_ids"] && touched["sub_category_ids"]
                }
                error={errors["sub_category_ids"]}
                placeholder={"Select"}
              />
            ) : (
              <FormInput
                type="select-async"
                label={"Select Detailed Sub Categories"}
                name={"detailed_sub_category_ids"}
                value={values["detailed_sub_category_ids"]}
                isMulti
                loadOptions={searchDetailedSubCategories}
                onChange={(v) => setFieldValue("detailed_sub_category_ids", v)}
                invalid={
                  errors["detailed_sub_category_ids"] &&
                  touched["detailed_sub_category_ids"]
                }
                error={errors["detailed_sub_category_ids"]}
                placeholder={"Select"}
              />
            )}

            {values["type"].value !== 1 && (
              <FormInput
                type="select-async"
                label={"Exclude Products"}
                name={"product_not_included"}
                value={values["product_not_included"]}
                isMulti
                loadOptions={searchProducts}
                onChange={(v) => setFieldValue("product_not_included", v)}
                invalid={
                  errors["product_not_included"] &&
                  touched["product_not_included"]
                }
                error={errors["product_not_included"]}
                placeholder={"Select Products"}
              />
            )}

            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditCustomProduct;
