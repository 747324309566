import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/auth";
import adminReducer from "./reducers/admin";
import sideBarReducer from "./reducers/sideBar";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["admin", "sideBar"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  sideBar: sideBarReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export const getState = () => {
  return store.getState();
};

const persistor = persistStore(store);
const dispatchAction = store.dispatch;

export { store, persistor, dispatchAction };
