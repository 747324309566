import { getState } from "../store";
import axios from "../config/axios-config"; 

const addCommonHeaders = (headers) => {
  return {
    ...headers,
    authorization: `Bearer ${getState().auth.user.data.access_token}`,
  };
};

export const POST = (url, data, headers) =>
  axios({
    method: "POST",
    url: url,
    headers: addCommonHeaders(headers),
    data: data,
  }).then((res) => res.data);

export const GET = (url, data, headers) =>
  axios
    .get(url, {
      headers: addCommonHeaders(headers),
      params: data,
    })
    .then((res) => res.data);
