import Axios from "axios";
import { dispatchAction, store } from "../store";
import { baseUrl } from "../config/api";
import { toast } from "react-toastify";
import { LOGOUT, SUCCESS } from "../store/actions/actionTypes";

const defaultOptions = () => ({
  headers: {
    authorization: store.getState().auth?.user?.data?.access_token
      ? `Bearer ${store.getState().auth?.user?.data?.access_token}`
      : "",
  },
  transformResponse: Axios.defaults.transformResponse.concat((response) => {
    if (response?.statusCode === 401) {
      dispatchAction({ type: LOGOUT[SUCCESS] });
    }
    return response;
  }),
});

export const AxiosInstance = () => {
  const instance = Axios.create({
    baseURL: `${baseUrl}`,
    ...defaultOptions(),
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      toast.error(error?.response?.data?.message || error?.message);
      throw error;
    }
  );
  return instance;
};
