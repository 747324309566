export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";
export const RESET = "RESET";

export const createRequestTypes = base => {
    return [RESET, REQUEST, SUCCESS, FAIL].reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

//auth action types
export const LOGIN = createRequestTypes("LOGIN");
export const LOGOUT = createRequestTypes("LOGIN");
export const FORGOT = createRequestTypes("FORGOT");

//admin action types
export const LIST = createRequestTypes("LIST");
export const MODAL = createRequestTypes("MODAL");


