import React from "react";
import ImageUploading from "react-images-uploading";
import { CRow, CCol, CButton } from "@coreui/react";

const ImageUploader = (props) => {
  return (
    <ImageUploading
      multiple={props.multiple}
      value={props.value}
      onChange={props.onChange}
      maxNumber={props.maxNumber}
      dataURLKey={props.dataURLKey}
      acceptType={["jpg", "jpeg", "png"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <CRow>
            <CCol>
              <div
                style={isDragging ? { color: "red" } : null}
                className="upload-image-area"
                onClick={onImageUpload}
                {...dragProps}
              >
                {props.placeholder}
              </div>
              {props.invalid && <p className="field-error">{props.error}</p>}
            </CCol>
          </CRow>
          {imageList.map((image, index) => (
            <div key={index} className="image-item">
              <img
                src={image[props.dataURLKey]}
                alt="..."
                width="150"
                onClick={() => window.open(image[props.dataURLKey], "_blank")}
                style={{ cursor: "pointer" }}
              />
              <div className="image-item__btn-wrapper">
                {props.showAddButton && (
                  <CButton
                    color="primary"
                    variant="outline"
                    className="mb-2 mr-1"
                    onClick={() => onImageUpdate(index)}
                  >
                    +
                  </CButton>
                )}
                <CButton
                  color="danger"
                  variant="outline"
                  className="mb-2 mr-1"
                  onClick={() => onImageRemove(index)}
                >
                  -
                </CButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
};

export default ImageUploader;
