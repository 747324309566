import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { getBadge, confirmModal, hasPermission } from "src/utils/helper";
import PageContainer from "src/components/PageContainer";
import CIcon from "@coreui/icons-react";
import user from "../../assets/icons/user.svg";
import _get from "lodash/get";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import { ROUTES } from "src/router/routes";

const type = "categories";

const Categories = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: categoriesList, count: total },
  } = useSelector((state) => state.admin.categories);

  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.CATEGORIES, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
      })
    );
    setSelectedItems([]);
  }, [dispatch, message, page, limitPerPage, searchFilter]);

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      (status) =>
        dispatch(blockUnblockRequest(type, { category_id: item._id, status })),
      "Publish",
      "Unpublish"
    );
  };
  const deleteCategory = (e, item) => {
    e.stopPropagation();
    confirmModal(
      0,
      () =>
        dispatch(
          blockUnblockRequest(type, {
            category_id: item._id,
            status: 2,
          })
        ),
      "Delete"
    );
  };

  const selectRow = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems((v) => [...v, item._id]);
    } else {
      setSelectedItems((v) => v.filter((i) => i !== item._id));
    }
  };

  const multiRowsSelect = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems(categoriesList.map((v) => v._id));
    } else {
      setSelectedItems([]);
    }
  };

  const bulkActions = (actiontype) => {
    if (actiontype === "delete") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              category_ids: selectedItems,
              status: 2,
            })
          ),
        "Delete"
      );
    } else if (actiontype === "block") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              category_ids: selectedItems,
              status: 0,
            })
          ),
        "Unpublish"
      );
    } else if (actiontype === "unblock") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              category_ids: selectedItems,
              status: 1,
            })
          ),
        "Publish"
      );
    }
  };

  return (
    <PageContainer
      title="Categories"
      rightButtons={
        writeAccess
          ? [
              {
                label: "Add Category",
                onClick: () =>
                  history.push(`${ROUTES.CATEGORIES}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setSearch("");
              setSearchFilter("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      {!!selectedItems.length && (
        <CRow className={"mt-0 mb-3"}>
          <CCol>
            <CButton
              color="primary"
              variant="outline"
              className="resetButton"
              onClick={() => bulkActions("unblock")}
            >
              Publish
            </CButton>

            <CButton
              color="primary"
              variant="outline"
              className="ml-2 resetButton"
              onClick={() => bulkActions("block")}
            >
              Unpublish
            </CButton>
            <CButton
              color="danger"
              variant="outline"
              className="ml-2 resetButton"
              onClick={() => bulkActions("delete")}
            >
              Delete
            </CButton>
          </CCol>
        </CRow>
      )}

      <CDataTable
        items={categoriesList}
        fields={[
          {
            key: "select",
            sorter: false,
            filter: false,
            label: writeAccess ? (
              <input
                type="checkbox"
                className="multiSelect"
                onChange={multiRowsSelect}
              />
            ) : (
              "Sr No"
            ),
          },
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "category_image",
          "category_name",
          "category_slug",
          "product_count",
          "createdAt",
        ]}
        hover
        sorter
        itemsPerPage={limitPerPage}
        activePage={page}
        pagination
        clickableRows
        loading={loading}
        addTableClasses={"default"}
        onRowClick={(item) => {
          if (writeAccess) {
            history.push(`${ROUTES.CATEGORIES}${ROUTES.EDIT}`, item);
          }
        }}
        scopedSlots={{
          // sr_no: (item, index) => (
          //   <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          // ),
          select: (item, index) =>
            writeAccess ? (
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="multiSelect"
                  checked={selectedItems.includes(item._id)}
                  onChange={(e) => selectRow(e, item, index)}
                />
              </td>
            ) : (
              <td>{limitPerPage * (page - 1) + (index + 1)}</td>
            ),
          category_image: (item) => (
            <td>
              {item.category_image ? (
                <img
                  alt="default"
                  className="c-avatar-img avatar"
                  src={_get(item, "category_image.thumbnail_url") || user}
                />
              ) : (
                <img
                  className="c-avatar-img avatar"
                  src={"avatars/default.jpeg"}
                  alt="default"
                />
              )}
            </td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD/MM/YYYY")}</td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),

          actions: (item) =>
            writeAccess ? (
              <td className="py-2">
                <CButton
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Unpublish" : " Publish"}
                </CButton>
                <CButton
                  color="danger"
                  variant="outline"
                  size="sm"
                  className={"ml-2"}
                  onClick={(e) => deleteCategory(e, item)}
                >
                  <CIcon name="cil-trash" /> Delete
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default Categories;
