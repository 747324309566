import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { subCategorySchema } from "../../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { Formik } from "formik";
import { CForm, CButton, CSpinner } from "@coreui/react";
import FormInput from "src/components/FormInput";
import _get from "lodash/get";
import moment from "moment";
import { searchCategories } from "src/utils";

const type = "subCategories";
const defaultFormValues = {
  sub_category_name: "",
  sub_category_slug: "",
  category_id: "",
  createdAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
};

const AddEditSubCategory = ({ isEdit, history, location: { state } }) => {
  if (isEdit && !state) {
    history.push("/sub-categories");
  }
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.admin.modal.loading);

  const subCategory = isEdit
    ? {
        ...state,
        category_id: {
          label: _get(state, "category_id.category_name", ""),
          value: _get(state, "category_id._id", ""),
        },
        createdAt: moment(_get(state, "createdAt")).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      }
    : defaultFormValues;

  const onSubmit = (values) => {
    let data = {};
    if (isEdit) {
      data.id = values._id;
      data.sub_category_name = values.sub_category_name;
      data.sub_category_slug = values.sub_category_slug;
      data.category_id = values.category_id.value;
      data.createdAt = moment(values.createdAt).format();
    } else {
      data = {
        ...values,
        category_id: values.category_id.value,
        createdAt: moment(values.createdAt).format(),
      };
    }

    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer
      lg={6}
      title={isEdit ? "Edit Sub Category" : "Add Sub Category"}
      loading={loading}
    >
      <Formik
        initialValues={isEdit ? subCategory : defaultFormValues}
        validationSchema={subCategorySchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="select-async"
              label="Category"
              placeholder="Search Category"
              loadOptions={searchCategories}
              value={values["category_id"]}
              onChange={(v) => setFieldValue("category_id", v)}
              invalid={errors["category_id"] && touched["category_id"]}
              error={errors["category_id"]}
            />

            <FormInput
              type="text"
              label={"Name"}
              name={"sub_category_name"}
              value={values["sub_category_name"]}
              onChange={(e) => {
                handleChange(e);
                setFieldValue(
                  "sub_category_slug",
                  e.target.value
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/[^\w-]+/g, "")
                );
              }}
              onBlur={handleBlur}
              invalid={
                errors["sub_category_name"] && touched["sub_category_name"]
              }
              error={errors["sub_category_name"]}
              placeholder={"Name"}
            />
            <FormInput
              type="text"
              label={"Slug"}
              name={"sub_category_slug"}
              value={values["sub_category_slug"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                errors["sub_category_slug"] && touched["sub_category_slug"]
              }
              error={errors["sub_category_slug"]}
              placeholder={"Slug"}
            />
            <FormInput
              type="datetime-local"
              label={"Created At"}
              name={"createdAt"}
              value={values["createdAt"]}
              onChange={handleChange}
              invalid={errors["createdAt"] && touched["createdAt"]}
              error={errors["createdAt"]}
              placeholder={"Created At"}
            />
            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditSubCategory;
