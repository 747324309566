import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import { ROUTES } from "./routes";
import { LoginPage } from "../pages";

const Router = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.APP}
        render={() => <Redirect to={ROUTES.DASHBOARD} />}
      />
      <PublicRoute exact path={ROUTES.LOGIN} component={LoginPage} />
      <PrivateRoute />
    </Switch>
  );
};

export default Router;
