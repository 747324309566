import { REQUEST, SUCCESS, FAIL, LIST, MODAL } from "../actions/actionTypes";
import { reducerTypes } from "../../utils/constants";

const INITIAL_VALUES = {
  loading: false,
  error: null,
  data: { data: [], count: 0 },
};
const typeState = {};
Object.values(reducerTypes).forEach((type) => {
  typeState[type] = { ...INITIAL_VALUES };
});

const initialState = {
  menuVisible: false,
  modal: { loading: false, error: null, message: null },
  ...typeState,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST[REQUEST]:
      return {
        ...state,
        [action.name]: { loading: true, error: null, data: {} },
      };
    case LIST[FAIL]:
      return {
        ...state,
        [action.name]: { loading: false, error: action.error, data: {} },
      };
    case LIST[SUCCESS]:
      return {
        ...state,
        [action.name]: {
          loading: false,
          error: null,
          data: action[action.name],
        },
      };

    case MODAL[REQUEST]:
      return { ...state, modal: { loading: true, error: null, message: null } };
    case MODAL[SUCCESS]:
      return {
        ...state,
        modal: { loading: false, error: null, message: action.message },
      };
    case MODAL[FAIL]:
      return {
        ...state,
        modal: { loading: false, error: action.error, message: null },
      };

    default:
      return state;
  }
};

export default reducer;
