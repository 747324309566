import debounce from "lodash/debounce";
import { filterDropdownData } from "./helper";
import {
  getUsers,
  getProducts,
  getCategories,
  getSubCategories,
  getTags,
  getSizeGuides,
  getDetailedSubCategories,
  getCoupons,
} from "src/apis";

const delay = 300;

// Users
export const searchUsers = debounce((search, resolve) => {
  getUsers({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, [
      "first_name",
      "last_name",
      "email_address",
    ],true);
    resolve(filter);
  });
}, delay);

// products
export const searchProducts = debounce((search, resolve) => {
  getProducts({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, [
      "category_id.category_name",
      "sub_category_id.sub_category_name",
      "item_name",
      "product_sku",
    ],true);
    resolve(filter);
  });
}, delay);

// Categories
export const searchCategories = debounce((search, resolve) => {
  getCategories({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, ["category_name"]);
    resolve(filter);
  });
}, delay);

// Sub Categories
export const searchSubCategories = debounce((search, resolve) => {
  getSubCategories({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, [
      "category_id.category_name",
      "sub_category_name",
    ]);
    resolve(filter);
  });
}, delay);

//  Deatiled Sub Categories
export const searchDetailedSubCategories = debounce((search, resolve) => {
  getDetailedSubCategories({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, [
      "category_id.category_name",
      "sub_category_id.sub_category_name",
      "detailed_sub_category_name",
    ]);
    resolve(filter);
  });
}, delay);

// Tags
export const searchTags = debounce((search, resolve) => {
  getTags({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, ["tag_name"]);
    resolve(filter);
  });
}, delay);

// SizeGuides
export const searchSizeGuides = debounce((search, resolve) => {
  getSizeGuides({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, ["name"]);
    resolve(filter);
  });
}, delay);

// Coupons
export const searchCoupons = debounce((search, resolve) => {
  getCoupons({ search }).then((res) => {
    const filter = filterDropdownData(res?.data, [
      "coupan_code",
      "coupan_amount",
    ]);
    resolve(filter);
  });
}, delay);
