import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { categorySchema } from "../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import ImageUploader from "src/components/ImageUploader";
import { uploadImage } from "../../utils/helper";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

const type = "categories";
const defaultFormValues = {
  category_name: "",
  category_slug: "",
  category_image: [],
  category_image_mobile: [],
  createdAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
};

const AddEditDetailedSubCategory = ({
  isEdit,
  history,
  location: { state },
}) => {
  if (isEdit && !state) {
    history.push("/categories");
  }
  const category = cloneDeep(state);
  if (isEdit) {
    category.createdAt = moment(category.createdAt).format("YYYY-MM-DD");
    category.category_image = [category.category_image];
    category.category_image_mobile = category.category_image_mobile
      ? [category.category_image_mobile]
      : [];
  }
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    setLoading(true);
    let img, imgMobile;
    if (
      !values.category_image[0].file_id &&
      values.category_image[0].file_id !== ""
    ) {
      let imgData = new FormData();
      imgData.append("files", values.category_image[0].file);
      img = await uploadImage(imgData);
      img = img[0];
    } else img = values.category_image[0];

    if (
      !values.category_image_mobile[0].file_id &&
      values.category_image_mobile[0].file_id !== ""
    ) {
      let imgData = new FormData();
      imgData.append("files", values.category_image_mobile[0].file);
      imgMobile = await uploadImage(imgData);
      imgMobile = imgMobile[0];
    } else imgMobile = values.category_image_mobile[0];

    let data = {
      category_name: values.category_name,
      category_slug: values.category_slug,
      category_image: img,
      category_image_mobile: imgMobile,
      createdAt: moment(values.createdAt).format(),
    };
    if (isEdit) {
      data.id = values._id;
    }

    dispatch(addEditRequest(type, data, history));
    setLoading(false);
  };

  return (
    <PageContainer lg={8} title={isEdit ? "Edit Category" : "Add Category"}>
      <Formik
        initialValues={isEdit ? category : defaultFormValues}
        validationSchema={categorySchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"Category Name"}
              name={"category_name"}
              value={values["category_name"]}
              onChange={(e) => {
                handleChange(e);
                setFieldValue(
                  "category_slug",
                  e.target.value
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/[^\w-]+/g, "")
                );
              }}
              onBlur={handleBlur}
              invalid={errors["category_name"] && touched["category_name"]}
              error={errors["category_name"]}
              placeholder={"Enter Category Name"}
            />
            <FormInput
              type="text"
              label={"Category Slug"}
              name={"category_slug"}
              value={values["category_slug"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["category_slug"] && touched["category_slug"]}
              error={errors["category_slug"]}
              placeholder={"Category Slug"}
            />
            <FormInput
              type="date"
              label={"Created At"}
              name={"createdAt"}
              value={values["createdAt"]}
              onChange={handleChange}
              invalid={errors["createdAt"] && touched["createdAt"]}
              error={errors["createdAt"]}
              placeholder={"Created At"}
            />
            Image for Desktop :
            <ImageUploader
              multiple={false}
              value={values["category_image"]}
              onChange={(files) => setFieldValue("category_image", files)}
              dataURLKey="image_url"
              placeholder={"Choose Category Image or Drop Here"}
              invalid={errors["category_image"] && touched["category_image"]}
              error={errors["category_image"]}
            />
            Image for Mobile :
            <ImageUploader
              multiple={false}
              value={values["category_image_mobile"]}
              onChange={(files) =>
                setFieldValue("category_image_mobile", files)
              }
              dataURLKey="image_url"
              placeholder={"Choose Category Image or Drop Here"}
              invalid={
                errors["category_image_mobile"] &&
                touched["category_image_mobile"]
              }
              error={errors["category_image_mobile"]}
            />
            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditDetailedSubCategory;
