import React, { useState, useEffect } from "react";
import {
  CButton,
  CBadge,
  CDataTable,
  CRow,
  CCol,
  CInput,
  CModal,
  CModalHeader,
  CModalTitle,
  CCardBody,
  CForm,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import {
  fetchList,
  blockUnblockRequest,
  addEditRequest,
} from "src/store/actions/admin";
import {
  getBadge,
  confirmModal,
  priceString,
  handleFileExport,
  hasPermission,
} from "src/utils/helper";
import PageContainer from "src/components/PageContainer";
import Avatar from "../../assets/icons/user.svg";
import FormInput from "src/components/FormInput";
import AES from "crypto-js/aes";
import _get from "lodash/get";
import { secretKey, endpoints } from "src/config/api";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import { ROUTES } from "src/router/routes";

const type = "users";

const Users = ({ history }) => {
  const dispatch = useDispatch();
  const {
    data: { data: users, count },
    loading,
  } = useSelector((s) => s.admin.users);
  const { message } = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [amountToAdd, setAmountToAdd] = useState(0);
  const [walletModal, setWalletModal] = useState();
  const [page, setPage] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(count / limitPerPage);

  const writeAccess = hasPermission(MODULES.USERS, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
      })
    );
  }, [dispatch, message, page, limitPerPage, searchFilter]);

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(item.status, (status) =>
      dispatch(blockUnblockRequest(type, { user_id: item._id, status }))
    );
  };

  const deleteUser = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      (status) =>
        dispatch(blockUnblockRequest(type, { user_id: item._id, status: 2 })),
      "Delete",
      "Delete"
    );
  };

  const addAmountTowallet = (e) => {
    e.preventDefault();
    let data = {
      user_id: walletModal._id,
      wallet_amount: e.target[0].value,
    };
    dispatch(
      addEditRequest("addMoneyToWallet", {
        data: AES.encrypt(JSON.stringify(data), secretKey).toString(),
      })
    );
    setAmountToAdd(0);
    setWalletModal("");
  };

  const fileExport = async () => {
    setExportLoading(true);
    await handleFileExport(endpoints.exportUsersToExcel);
    setExportLoading(false);
  };

  const actionButtons = () => {
    const buttons = [
      {
        label: "Export",
        variant: "outline",
        onClick: fileExport,
        loading: exportLoading,
      },
    ];
    if (writeAccess) {
      buttons.push({
        label: "Add New User",
        onClick: () => history.push(`${ROUTES.USERS}${ROUTES.ADD}`),
      });
    }
    return buttons;
  };

  return (
    <PageContainer
      title="Users"
      rightButtons={actionButtons()}
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setSearch("");
              setSearchFilter("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={users}
        fields={[
          "sr_no",
          "actions",
          "status",
          "profile_image",
          "wallet_amount",
          "mobile",
          "first_name",
          "last_name",
          "email_address",
          "createdAt",
        ]}
        hover
        sorter
        responsive
        itemsPerPage={limitPerPage}
        clickableRows
        loading={loading}
        addTableClasses={"users"}
        onRowClick={(item) => {
          if (writeAccess) {
            history.push(
              `${ROUTES.USERS}${ROUTES.EDIT}/${_get(item, "_id", "")}`
            );
          }
        }}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          wallet_amount: (item) => (
            <td>{priceString(_get(item, "wallet_amount", 0))}</td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD/MM/YYYY hh:mm a")}</td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          profile_image: (item) => (
            <td>
              {_get(item, "profile_image") ? (
                <img
                  className="c-avatar-img avatar"
                  src={_get(item, "profile_image.thumbnail_url") || Avatar}
                  alt="user"
                />
              ) : (
                <img
                  className="c-avatar-img avatar"
                  src={"avatars/default.jpeg"}
                  alt="default"
                />
              )}
            </td>
          ),
          actions: (item) =>
            writeAccess ? (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setWalletModal(item);
                  }}
                >
                  <CIcon name="cil-cash" /> Add
                </CButton>
                <CButton
                  className={"ml-2"}
                  color="danger"
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon name="cil-x-circle" />
                  {item.status ? " Block" : " Unblock"}
                </CButton>
                <CButton
                  className={"ml-2"}
                  color="danger"
                  variant="outline"
                  size="sm"
                  onClick={(e) => deleteUser(e, item)}
                >
                  <CIcon name="cil-x" />
                  Delete
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
      <CModal
        show={walletModal ? true : false}
        onClose={() => {
          setWalletModal("");
          setAmountToAdd(0);
        }}
      >
        <CModalHeader closeButton>
          <CModalTitle>{`${walletModal?.first_name}'s wallet`}</CModalTitle>
        </CModalHeader>
        <CCardBody>
          <CForm onSubmit={addAmountTowallet}>
            <CRow>
              <CCol xs={8}>
                <FormInput
                  type="number"
                  label="Amount"
                  required={true}
                  value={amountToAdd}
                  onChange={(e) => setAmountToAdd(e.target.value)}
                />
              </CCol>
              <CCol>
                <CButton color="primary" type="submit">
                  Add money
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CModal>
    </PageContainer>
  );
};

export default Users;
