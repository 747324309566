import { confirmAlert } from "react-confirm-alert";
import { POST } from "src/config/services";
import { endpoints, baseUrl } from "src/config/api";
import { toast } from "react-toastify";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import { getState } from "src/store";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

export const getBadge = (status) => {
  switch (status) {
    case "Active":
      return "success";
    case "Inactive":
      return "secondary";
    case "Pending":
      return "warning";
    case "Banned":
      return "danger";
    case 1:
      return "success";
    case 0:
      return "secondary";
    case 2:
      return "success";
    case 3:
      return "danger";
    default:
      return "primary";
  }
};

export const getOrderStatus = (status) => {
  switch (status) {
    case 0:
      return "PENDING";
    case 1:
      return "PROCESSING";
    // case 2:
    //   return "ACCEPTED";
    case 3:
      return "SHIPPED";
    case 4:
      return "DELIVERED";
    case 5:
      return "PARTIAL REFUND";
    case 6:
      return "FULL REFUND";
    case 7:
      return "CANCELLED"; // by admin
    case 8:
      return "CANCELLED"; // by user
    default:
      return "";
  }
};

export const getPaymentMethod = (status) => {
  switch (status) {
    case 0:
      return "DIGITAL";
    case 1:
      return "COD";
    case 2:
      return "EXCHANGE";
    case 3:
      return "WALLET";
    case 4:
      return "DIGITAL + WALLET";
    case 5:
      return "COD + WALLET";
    default:
      return "";
  }
};

export const getOrderBadge = (status) => {
  switch (status) {
    case 0:
      return "secondary";
    case 1:
      return "warning";
    case 2:
      return "success";
    case 3:
      return "info";
    case 4:
      return "success";
    case 5:
      return "primary";
    case 6:
      return "dark";
    case 7:
      return "danger";
    case 8:
      return "danger";
    default:
      return "";
  }
};

export const getBlockButtonText = (status) => {
  return status ? ` Unpublish` : ` Publish`;
};

export const confirmModal = (
  status,
  onConfirm,
  type = "unblock",
  type2 = "block"
) => {
  confirmAlert({
    title: (
      <h3>
        {status === 0
          ? `Are you sure you want to ${type}?`
          : `Are you sure you want to ${type2}?`}
      </h3>
    ),
    buttons: [
      {
        label: "Yes",
        onClick: () => onConfirm(status === 0 ? 1 : 0),
      },
      {
        label: "No",
      },
    ],
  });
};

export const uploadImage = (data) => {
  return POST(endpoints.productImageUpload, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })
    .catch((err) => {
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
};

export const handleFileExport = async (url, data) => {
  await POST(url, data).then((res) => {
    if (res.status === 200) {
      toast.success("File Downloaded", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      window.open(`${baseUrl}/${_get(res, "data", "")}`, "_blank");
    } else {
      toast.error(res.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  });
};

export const getFetchUrl = (type) => {
  let url;
  switch (type) {
    case "dashboard":
      url = endpoints.dashboard;
      break;
    case "subAdmins":
      url = endpoints.listSubAdmins;
      break;
    case "users":
      url = endpoints.listUsers;
      break;
    case "detailedSubCategories":
      url = endpoints.listDetailedSubcategories;
      break;
    case "subCategories":
      url = endpoints.listSubcategories;
      break;
    case "categories":
      url = endpoints.listCategories;
      break;
    case "orders":
      url = endpoints.listOrders;
      break;
    case "products":
      url = endpoints.listProducts;
      break;
    case "taxes":
      url = endpoints.listTaxes;
      break;
    case "sizeGuides":
      url = endpoints.listSizeGuides;
      break;
    case "exchangeReturns":
      url = endpoints.listExchangeReturn;
      break;
    case "coupons":
      url = endpoints.listCoupons;
      break;
    case "cart":
      url = endpoints.viewCart;
      break;
    case "variants":
      url = endpoints.listVariants;
      break;
    case "customProducts":
      url = endpoints.listCustomProducts;
      break;
    case "promotions":
      url = endpoints.listPromotions;
      break;
    case "settings":
      url = endpoints.listSettings;
      break;
    case "tags":
      url = endpoints.listTags;
      break;
    case "mailLogs":
      url = endpoints.listMailLogs;
      break;
    case "mailFormats":
      url = endpoints.listMailFormats;
      break;
    case "orderStats":
      url = endpoints.orderStats;
      break;
    case "walletLogs":
      url = endpoints.listWalletLogs;
      break;
    default:
      url = "";
  }
  return url;
};

export const getAddEditUrl = (type) => {
  let url;
  switch (type) {
    case "users":
      url = endpoints.addEditUser;
      break;
    case "subAdmins":
      url = endpoints.addEditAdmins;
      break;
    case "categories":
      url = endpoints.addEditCategory;
      break;
    case "subCategories":
      url = endpoints.addEditSubCategories;
      break;
    case "detailedSubCategories":
      url = endpoints.addEditDetailedSubcategories;
      break;
    case "taxes":
      url = endpoints.addEditTax;
      break;
    case "sizeGuides":
      url = endpoints.addEditSizeGuides;
      break;
    case "addToCart":
      url = endpoints.addProductToCard;
      break;
    case "coupons":
      url = endpoints.addEditCoupon;
      break;
    case "products":
      url = endpoints.addEditProduct;
      break;
    case "promocode":
      url = endpoints.promoCode;
      break;
    case "order":
      url = endpoints.addOrder;
      break;
    case "editOrder":
      url = endpoints.editOrder;
      break;
    case "customProducts":
      url = endpoints.addEditCustomProducts;
      break;
    case "promotions":
      url = endpoints.AddEditPromotions;
      break;
    case "settings":
      url = endpoints.editSettings;
      break;
    case "variants":
      url = endpoints.addEditVariants;
      break;
    case "tags":
      url = endpoints.addEditTags;
      break;
    case "addMoneyToWallet":
      url = endpoints.addMoneyToWallet;
      break;
    case "importFromExcel":
      url = endpoints.importFromExcel;
      break;
    case "editCart":
      url = endpoints.editCart;
      break;
    case "mailFormats":
      url = endpoints.addEditMailFormats;
      break;
    case "exchangeReturns":
      url = endpoints.addExchangeReturn;
      break;
    case "forgotPassword":
      url = endpoints.forgotPassword;
      break;
    case "changePassword":
      url = endpoints.changePassword;
      break;
    default:
      url = "";
  }
  return url;
};

export const getBlockUrl = (type) => {
  let url;
  switch (type) {
    case "users":
      url = endpoints.blockUsers;
      break;
    case "subAdmins":
      url = endpoints.blockAdmins;
      break;
    case "categories":
      url = endpoints.blockCategories;
      break;
    case "detailedSubCategories":
      url = endpoints.blockDetailedSubcategories;
      break;
    case "subCategories":
      url = endpoints.blockSubcategories;
      break;
    case "sizeGuides":
      url = endpoints.blockSizeGuides;
      break;
    case "exchangeReturns":
      url = endpoints.exchangeReturnActions;
      break;
    case "coupons":
      url = endpoints.blockUnblockCoupon;
      break;
    case "variants":
      url = endpoints.blockUnblockVariants;
      break;
    case "customProducts":
      url = endpoints.blockUnblockCustomProducts;
      break;
    case "promotions":
      url = endpoints.BlockUnblockPromotions;
      break;
    case "products":
      url = endpoints.blockProducts;
      break;
    case "tags":
      url = endpoints.blockTags;
      break;
    case "taxes":
      url = endpoints.blockTax;
      break;
    case "orders":
      url = endpoints.multiOrderUpdate;
      break;
    default:
      url = "";
  }
  return url;
};

export const modifyDropDownValues = (array, label, value) => {
  for (let obj of array) {
    obj.label = obj[label] || "";
    obj.value = obj[value] || "";
  }
  return array;
};

export const getAddress = (object) => {
  return `Full Name - ${_get(object, "first_name", "")} ${_get(
    object,
    "last_name",
    ""
  )} 
      Phone - ${_get(object, "phone_number", "")}
      Address - ${_get(object, "address_line_one", "")}, ${_get(
    object,
    "address_line_second",
    ""
  )} 
      City - ${_get(object, "city", "")}
      State - ${_get(object, "state_country", "")}
      Zipcode - ${_get(object, "postcode_zip", "")}
    `;
};

export const filterDropdownData = (array, lables = [""], data = false) => {
  const list = [];
  for (let i = 0; i < array.length; i++) {
    let label = "";
    for (let j = 0; j < lables.length; j++) {
      label += `${_get(array[i], lables[j], "")}${
        lables[lables.length - 1] === lables[j] ? "" : ` - `
      }`;
    }
    list.push({
      label,
      value: _get(array[i], "_id", ""),
      [data ? "data" : ""]: data ? _cloneDeep(array[i]) : "",
    });
  }
  return list;
};

export const priceString = (price) => {
  return `₹ ${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const downloadHtmlToPDF = (invoiceHtml) => {
  if (invoiceHtml) {
    const w = window.open();
    w.document.write(invoiceHtml);
    w.window.print();
    w.document.close();
  }
};

export const hasPermission = (module, permission) => {
  const user = getState()?.auth?.user?.data;
  if (user?.super_admin) {
    return true;
  }

  const userRole = user?.role?.find((item) => item.module === module);
  if (userRole?.permission?.includes(permission)) {
    return true;
  }

  return false;
};
