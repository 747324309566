import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { sizeGuideSchema } from "../../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { Formik, FieldArray } from "formik";
import FormInput from "src/components/FormInput";
import {
  CForm,
  CButton,
  CSpinner,
  CRow,
  CCardBody,
  CCard,
  CCardHeader,
} from "@coreui/react";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";

const type = "sizeGuides";
const defaultFormValues = {
  name: "",
  table_title: "",
  table_caption: "",
  table_data: [
    ["size", "chest", "hips"],
    ["4", "5", "6"],
    ["7", "8", "9"],
  ],
  table_data2: [],
};

const AddEditSizeGuide = ({
  isEdit,
  history,
  location: { state: sizeGuide },
}) => {
  if (isEdit && !sizeGuide) {
    history.push("/size-guides");
  }

  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);

  const onSubmit = (values) => {
    let data = {};
    if (isEdit) {
      data.id = _get(values, "_id", "");
      data.name = _get(values, "name", "");
      data.table_caption = _get(values, "table_caption", "");
      data.table_title = _get(values, "table_title", "");
      data.table_data = _cloneDeep(values.table_data);
      data.table_data2 = _cloneDeep(values.table_data2);
    } else data = _cloneDeep(values);
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer
      sm={12}
      title={isEdit ? "Edit Size Guide" : "Add New Size Guide"}
    >
      <Formik
        initialValues={isEdit ? sizeGuide : defaultFormValues}
        validationSchema={sizeGuideSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"Name"}
              name={"name"}
              id={"name"}
              value={values["name"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["name"] && touched["name"]}
              error={errors["name"]}
              placeholder={"Enter Name"}
            />
            <FormInput
              type="text"
              label={"Table Title"}
              name={"table_title"}
              id={"table_title"}
              value={values["table_title"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["table_title"] && touched["table_title"]}
              error={errors["table_title"]}
              placeholder={"Enter Table Title"}
            />
            <FormInput
              type="text"
              label={"Table Caption"}
              name={"table_caption"}
              id={"table_caption"}
              value={values["table_caption"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["table_caption"] && touched["table_caption"]}
              error={errors["table_caption"]}
              placeholder={"Enter Table Caption"}
            />
            <CCard>
              <CCardHeader>
                Size Table
                {!_get(values, "table_data2.length") > 0 && (
                  <CButton
                    className="float-right"
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      setFieldValue(
                        "table_data2",
                        _cloneDeep(defaultFormValues.table_data)
                      )
                    }
                  >
                    Add More table
                  </CButton>
                )}
              </CCardHeader>
              <CCardBody>
                <FieldArray
                  name={"table_data"}
                  render={(helper) => (
                    <table className={"size-guide-table"}>
                      <thead>
                        <tr>
                          {_get(values, "table_data[0]", []).map((_, i) => (
                            <th key={i}>
                              <CRow className="justify-content-center">
                                <CButton
                                  color="primary"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    const temp = [...values.table_data];
                                    temp.forEach((row) => {
                                      row.splice(i, 0, "");
                                    });
                                    setFieldValue("table_data", temp);
                                  }}
                                >
                                  +
                                </CButton>
                                <CButton
                                  color="danger"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    const temp = [...values.table_data];
                                    temp.forEach((row) => {
                                      if (row.length !== 1) row.splice(i, 1);
                                    });
                                    setFieldValue("table_data", temp);
                                  }}
                                >
                                  x
                                </CButton>
                              </CRow>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {_get(values, "table_data", []).map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, rowi) => (
                              <td key={rowi}>
                                <FormInput
                                  type="text"
                                  name={`table_data.${i}.${rowi}`}
                                  value={cell}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="mb-0"
                                />
                              </td>
                            ))}
                            <td className="w-55">
                              <CButton
                                color="primary"
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                  helper.insert(
                                    i,
                                    new Array(values.table_data[0].length).fill(
                                      ""
                                    )
                                  )
                                }
                              >
                                +
                              </CButton>
                              <CButton
                                color="danger"
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                  if (values.table_data.length !== 1)
                                    helper.remove(i);
                                }}
                              >
                                x
                              </CButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                />
              </CCardBody>
            </CCard>

            {_get(values, "table_data2.length") > 0 && (
              <CCard>
                <CCardHeader>
                  Size Table 2{" "}
                  <CButton
                    className="float-right"
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => setFieldValue("table_data2", [])}
                  >
                    Remove Table
                  </CButton>
                </CCardHeader>
                <CCardBody>
                  <FieldArray
                    name={"table_data2"}
                    render={(helper2) => (
                      <table className={"size-guide-table"}>
                        <thead>
                          <tr>
                            {_get(values, "table_data2[0]", []).map((_, i) => (
                              <th key={`th-${i}`}>
                                <CRow className="justify-content-center">
                                  <CButton
                                    color="primary"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => {
                                      const temp = [
                                        ..._get(values, "table_data2"),
                                      ];
                                      temp.forEach((row) => {
                                        row.splice(i, 0, "");
                                      });
                                      setFieldValue("table_data2", temp);
                                    }}
                                  >
                                    +
                                  </CButton>
                                  <CButton
                                    color="danger"
                                    variant="outline"
                                    size="sm"
                                    onClick={() => {
                                      const temp = [
                                        ..._get(values, "table_data2"),
                                      ];
                                      temp.forEach((row) => {
                                        if (row.length !== 1) row.splice(i, 1);
                                      });
                                      setFieldValue("table_data2", temp);
                                    }}
                                  >
                                    x
                                  </CButton>
                                </CRow>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {_get(values, "table_data2", []).map((row, i) => (
                            <tr key={`tb2-${i}`}>
                              {row.map((cell, rowi) => (
                                <td key={rowi}>
                                  <FormInput
                                    type="text"
                                    name={`table_data2.${i}.${rowi}`}
                                    value={cell}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="mb-0"
                                  />
                                </td>
                              ))}
                              <td className="w-55">
                                <CButton
                                  color="primary"
                                  variant="outline"
                                  size="sm"
                                  onClick={() =>
                                    helper2.insert(
                                      i,
                                      new Array(
                                        values.table_data2[0].length
                                      ).fill("")
                                    )
                                  }
                                >
                                  +
                                </CButton>
                                <CButton
                                  color="danger"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    if (values.table_data2.length !== 1)
                                      helper2.remove(i);
                                  }}
                                >
                                  x
                                </CButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  />
                </CCardBody>
              </CCard>
            )}

            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditSizeGuide;
