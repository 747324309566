import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import {
  getBadge,
  confirmModal,
  priceString,
  hasPermission,
} from "src/utils/helper";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import _get from "lodash/get";
import { ROUTES } from "src/router/routes";

const type = "coupons";

const Coupons = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: couponsList, count: total },
  } = useSelector((state) => state.admin.coupons);

  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.MARKETING, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
        startDate,
        endDate,
      })
    );
  }, [dispatch, page, message, startDate, endDate, limitPerPage, searchFilter]);

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(item.status, (status) =>
      dispatch(
        blockUnblockRequest(type, {
          coupan_id: item._id,
          status,
        })
      )
    );
  };

  const resetFilter = () => {
    setSearch("");
    setSearchFilter("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <PageContainer
      title="Coupons"
      rightButtons={
        writeAccess
          ? [
              {
                label: "Add Coupon",
                onClick: () => history.push(`${ROUTES.COUPONS}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
      loading={loading}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={3}>
          Start Date
          <CInput
            type="date"
            value={startDate}
            name="startDate"
            onChange={(e) => setStartDate(e.target.value)}
          />
        </CCol>
        <CCol sm={3}>
          End Date
          <CInput
            type="date"
            value={endDate}
            name="startDate"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={resetFilter}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={couponsList}
        fields={[
          "Sr_no",
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          {
            key: "coupan_code",
            label: "Coupon Code",
          },
          {
            key: "coupan_amount",
            label: "Coupon Discount",
          },
          "min_amount",
          "start_date",
          "end_date",
          "createdAt",
        ]}
        hover
        itemsPerPage={limitPerPage}
        activePage={page}
        clickableRows
        pagination
        loading={loading}
        addTableClasses={"default"}
        onRowClick={(item) => {
          if (writeAccess) {
            history.push(`${ROUTES.COUPONS}${ROUTES.EDIT}`, item);
          }
        }}
        scopedSlots={{
          Sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          min_amount: (item) => (
            <td>{priceString(_get(item, "min_amount", ""))}</td>
          ),
          start_date: (item) => (
            <td>{Moment(item.start_date).format("DD/MM/YYYY HH:mm A")}</td>
          ),
          end_date: (item) => (
            <td>{Moment(item.end_date).format("DD/MM/YYYY HH:01 A")}</td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD/MM/YYYY HH:MM A")}</td>
          ),
          coupan_amount: (item) => (
            <td>
              {item.coupan_amount}
              {item.type === 1 ? "% Off" : ` Flat`}
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          actions: (item) =>
            writeAccess ? (
              <td className="py-2">
                <CButton color="primary" variant="outline" size="sm">
                  <CIcon name="cil-pencil" /> Edit
                </CButton>
                <CButton
                  className={"ml-2"}
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Block" : " Unblock"}
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default Coupons;
