import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { productSchema } from "../../config/formSchema";
import {
  addEditRequest,
  fetchList,
  blockUnblockRequest,
} from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import {
  CForm,
  CButton,
  CSpinner,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
} from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import ImageUploader from "src/components/ImageUploader";
import {
  uploadImage,
  confirmModal,
  filterDropdownData,
} from "../../utils/helper";
import cloneDeep from "lodash/cloneDeep";
import _map from "lodash/map";
import _get from "lodash/get";
import { Link, useParams } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { ROUTES } from "src/router/routes";
import { GET } from "src/config/services";
import { endpoints } from "src/config/api";
import {
  searchCategories,
  searchTags,
  searchProducts,
  searchSizeGuides,
} from "src/utils";

const type = "products";
const defaultFormValues = {
  item_name: "",
  description_name: "",
  price: "",
  product_slug: "",
  product_sku: "",
  refund: "",
  category_id: "",
  sub_category_id: "",
  detailed_sub_category_id: "",
  related_product_id: [],
  product_image: [],
  additional_info: "",
  seo_title: "",
  seo_description: "",
  size_id: "",
  tag_id: [],
};

const AddEditProducts = ({ isEdit, history }) => {
  const { id } = useParams();
  if (isEdit && !id) {
    history.push("/products");
  }
  const message = useSelector((s) => s.admin.modal.message);
  const { data: subCategories } = useSelector(
    (s) => s.admin.subCategories.data
  );
  const { data: detailedSubCategories } = useSelector(
    (s) => s.admin.detailedSubCategories.data
  );
  const {
    variantsLoading,
    data: { data: variantsList },
  } = useSelector((s) => s.admin.variants);
  const subCategoriesList = filterDropdownData(subCategories, [
    "sub_category_name",
  ]);
  const detailedSubCategoriesList = filterDropdownData(detailedSubCategories, [
    "detailed_sub_category_name",
  ]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    GET(endpoints.listProducts, { product_id: id }).then((res) => {
      const tempProduct = cloneDeep(res.data.data);
      tempProduct.category_id = {
        label: _get(tempProduct, "category_id.category_name"),
        value: _get(tempProduct, "category_id._id"),
      };
      tempProduct.sub_category_id = {
        label: _get(tempProduct, "sub_category_id.sub_category_name"),
        value: _get(tempProduct, "sub_category_id._id"),
      };
      tempProduct.detailed_sub_category_id = {
        label: _get(
          tempProduct,
          "detailed_sub_category_id.detailed_sub_category_name"
        ),
        value: _get(tempProduct, "detailed_sub_category_id._id"),
      };
      tempProduct.size_id = {
        label: _get(tempProduct, "size_id.name"),
        value: _get(tempProduct, "size_id._id"),
      };
      for (let key of _get(tempProduct, "related_product_id", [])) {
        key.label = `${key.item_name} - ${key.product_sku}`;
        key.value = key._id;
      }
      for (let key of _get(tempProduct, "tag_id", [])) {
        key.label = key.tag_name;
        key.value = key._id;
      }

      setProduct(tempProduct);
    });
  }, [id]);

  useEffect(() => {
    dispatch(fetchList("categories"));
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      dispatch(fetchList("variants", { product_id: id }));
      dispatch(
        fetchList("subCategories", {
          id: _get(product, "category_id._id", ""),
        })
      );
      dispatch(
        fetchList("detailedSubCategories", {
          sub_category_id: _get(product, "sub_category_id._id", ""),
        })
      );
    }
  }, [dispatch, isEdit, message, id, product]);

  const onSubmit = async (values) => {
    setLoading(true);
    let data = cloneDeep(values);
    data.category_id = data.category_id.value;
    data.sub_category_id = data.sub_category_id.value;
    data.detailed_sub_category_id = data.detailed_sub_category_id.value;
    data.size_id = data.size_id.value;
    data.related_product_id = _map(data.related_product_id, "value");
    data.tag_id = _map(data.tag_id, "value");
    if (isEdit) {
      data.id = data._id;
      delete data._id;
      delete data.status;
      if (data.sale_status) {
        delete data.sale_id;
        delete data.sale_type;
        delete data.sale_name;
        delete data.price_type;
        delete data.sale_price;
      }
      delete data.sale_status;
      delete data.variant_details;
      let imgs = [];
      let flag = false;

      for (let i = 0; i < data.product_image.length; i++) {
        if (data.product_image[i].file) {
          flag = true;
          data.product_image[i].index = i;
          imgs.push(data.product_image[i].file);
          data.product_image.splice(i, 1);
          i--;
        }
      }
      if (flag) {
        let imgData = new FormData();
        for (const key of imgs) {
          imgData.append("files", key);
        }
        let img = await uploadImage(imgData);
        data.product_image = [...data.product_image, ...img];
      }
    } else {
      data.product_image = _map(data.product_image, "file");
      let imgData = new FormData();
      for (const key of Object.keys(data.product_image)) {
        imgData.append("files", data.product_image[key]);
      }
      let img = await uploadImage(imgData);
      data.product_image = img;
    }
    dispatch(addEditRequest(type, data, history));
    setLoading(false);
  };

  const deleteVariant = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      (status) =>
        dispatch(
          blockUnblockRequest("variants", {
            id: item._id,
            status: 2,
          })
        ),
      "Delete",
      "Delete"
    );
  };

  return (
    <Formik
      initialValues={isEdit ? product : defaultFormValues}
      validationSchema={productSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <CForm onSubmit={handleSubmit}>
          <CRow>
            <CCol lg={7}>
              <CCard>
                <CCardHeader className="d-flex justify-content-between align-items-center">
                  {isEdit ? "Edit Product" : "Add Product"}
                  <CButton
                    type="submit"
                    color="primary"
                    disabled={!dirty || loading}
                  >
                    {loading && (
                      <CSpinner
                        component="span"
                        className="mr-2"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    Submit
                  </CButton>
                </CCardHeader>
                <CCardBody>
                  <FormInput
                    type="text"
                    label={"Name"}
                    name={"item_name"}
                    value={values["item_name"]}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "product_slug",
                        e.target.value
                          .toLowerCase()
                          .replace(/ /g, "-")
                          .replace(/[^\w-]+/g, "")
                      );
                    }}
                    onBlur={handleBlur}
                    invalid={errors["item_name"] && touched["item_name"]}
                    error={errors["item_name"]}
                    placeholder={"Enter Product Name"}
                  />
                  <FormInput
                    type="text"
                    label={"Slug"}
                    name={"product_slug"}
                    value={values["product_slug"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["product_slug"] && touched["product_slug"]}
                    error={errors["product_slug"]}
                    placeholder={"Enter Product Slug"}
                  />
                  <FormInput
                    type="text"
                    label={"SKU"}
                    name={"product_sku"}
                    value={values["product_sku"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["product_sku"] && touched["product_sku"]}
                    error={errors["product_sku"]}
                    placeholder={"Enter Product SKU"}
                  />

                  <FormInput
                    type="html-editor"
                    label={"Description"}
                    name={"description_name"}
                    value={values["description_name"]}
                    onChange={(v) => setFieldValue("description_name", v)}
                    onBlur={handleBlur}
                    invalid={
                      errors["description_name"] && touched["description_name"]
                    }
                    error={errors["description_name"]}
                    placeholder={"Enter Description"}
                  />
                  <FormInput
                    type="html-editor"
                    label={"Additional Info"}
                    name={"additional_info"}
                    value={values["additional_info"]}
                    onChange={(v) => setFieldValue("additional_info", v)}
                    onBlur={handleBlur}
                    invalid={
                      errors["additional_info"] && touched["additional_info"]
                    }
                    error={errors["additional_info"]}
                    placeholder={"Enter Additional Info"}
                  />
                  <FormInput
                    type="text"
                    label={"Price"}
                    name={"price"}
                    value={values["price"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["price"] && touched["price"]}
                    error={errors["price"]}
                    placeholder={"Enter Product Price"}
                  />

                  <FormInput
                    type="select-async"
                    label={"Related Products"}
                    cacheOptions
                    loadOptions={searchProducts}
                    value={values["related_product_id"]}
                    onChange={(v) => setFieldValue("related_product_id", v)}
                    isMulti
                    invalid={
                      errors["related_product_id"] &&
                      touched["related_product_id"]
                    }
                    error={errors["related_product_id"]}
                  />
                  <FormInput
                    type="select-async"
                    label={"Tags"}
                    cacheOptions
                    loadOptions={searchTags}
                    value={values["tag_id"]}
                    onChange={(v) => setFieldValue("tag_id", v)}
                    isMulti
                    invalid={errors["tag_id"] && touched["tag_id"]}
                    error={errors["tag_id"]}
                  />

                  <FormInput
                    type="text"
                    label={"Refund Days"}
                    name={"refund"}
                    value={values["refund"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["refund"] && touched["refund"]}
                    error={errors["refund"]}
                    placeholder={"Enter Refund Days"}
                  />
                </CCardBody>
              </CCard>
              {isEdit && (
                <CCard>
                  <CCardHeader>
                    Variants{" "}
                    <Link
                      to={{
                        pathname: `${ROUTES.VARIANTIONS}${ROUTES.ADD}`,
                        state: {
                          product_id: { _id: _get(product, "_id", "") },
                          sku: _get(product, "product_sku", ""),
                        },
                      }}
                      className="float-right"
                    >
                      Add Variant
                    </Link>
                  </CCardHeader>
                  <CCardBody>
                    <CDataTable
                      items={variantsList}
                      fields={[
                        "#",
                        "actions",
                        "quantity",
                        "price",
                        "size",
                        "color",
                        "sku",
                      ]}
                      hover
                      onRowClick={(item) => {
                          if(item.price === null){
                            item.price = values["price"]
                          }
                          history.push(
                            `${ROUTES.VARIANTIONS}${ROUTES.EDIT}`,
                            item
                          )
                        }
                      }
                      addTableClasses={"product-variants"}
                      loading={variantsLoading}
                      scopedSlots={{
                        "#": (_, index) => <td>{index + 1}</td>,
                        actions: (item, index) => (
                          <td>
                            <CButton
                              color="primary"
                              variant="outline"
                              size="sm"
                            >
                              <CIcon name="cil-pencil" />
                            </CButton>
                            <CButton
                              color="danger"
                              variant="outline"
                              size="sm"
                              className="ml-2"
                              onClick={(e) => deleteVariant(e, item)}
                            >
                              <CIcon name={"cil-x-circle"} />
                            </CButton>
                          </td>
                        ),
                      }}
                    />
                  </CCardBody>
                </CCard>
              )}
            </CCol>
            <CCol lg={5}>
              <CCard>
                <CCardHeader>Product Category</CCardHeader>
                <CCardBody>
                  <FormInput
                    type="select-async"
                    label={"Category"}
                    loadOptions={searchCategories}
                    value={values["category_id"]}
                    onChange={(v) => {
                      setFieldValue("category_id", v);
                      setFieldValue("sub_category_id", "");
                      dispatch(fetchList("subCategories", { id: v.value }));
                    }}
                    invalid={errors["category_id"] && touched["category_id"]}
                    error={errors["category_id"]}
                  />
                  <FormInput
                    type="select"
                    label={"Sub Category"}
                    options={subCategoriesList}
                    value={values["sub_category_id"]}
                    onChange={(v) => {
                      setFieldValue("sub_category_id", v);
                      setFieldValue("detailed_sub_category_id", "");
                      dispatch(
                        fetchList("detailedSubCategories", {
                          sub_category_id: v.value,
                        })
                      );
                    }}
                    invalid={
                      errors["sub_category_id"] && touched["sub_category_id"]
                    }
                    error={errors["sub_category_id"]}
                  />
                  <FormInput
                    type="select"
                    label={"Detailed Sub Category"}
                    options={detailedSubCategoriesList}
                    value={values["detailed_sub_category_id"]}
                    onChange={(v) =>
                      setFieldValue("detailed_sub_category_id", v)
                    }
                    invalid={
                      errors["detailed_sub_category_id"] &&
                      touched["detailed_sub_category_id"]
                    }
                    error={errors["detailed_sub_category_id"]}
                  />
                  <FormInput
                    type="select-async"
                    label={"Size Guide"}
                    loadOptions={searchSizeGuides}
                    value={values["size_id"]}
                    onChange={(v) => setFieldValue("size_id", v)}
                    invalid={errors["size_id"] && touched["size_id"]}
                    error={errors["size_id"]}
                  />
                </CCardBody>
              </CCard>
              <CCard>
                <CCardHeader>SEO</CCardHeader>
                <CCardBody>
                  <FormInput
                    type="text"
                    label={"Title"}
                    name={"seo_title"}
                    value={values["seo_title"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["seo_title"] && touched["seo_title"]}
                    error={errors["seo_title"]}
                    placeholder={"Enter Seo Title"}
                  />
                  <FormInput
                    type="textarea"
                    label={"Description"}
                    name={"seo_description"}
                    value={values["seo_description"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={
                      errors["seo_description"] && touched["seo_description"]
                    }
                    error={errors["seo_description"]}
                    placeholder={"Enter Seo Description"}
                  />
                </CCardBody>
              </CCard>
              <CCard>
                <CCardHeader>Product Images</CCardHeader>
                <CCardBody>
                  <ImageUploader
                    multiple={true}
                    value={values["product_image"]}
                    onChange={(files) => setFieldValue("product_image", files)}
                    dataURLKey="image_url"
                    placeholder={"Choose Product Images or Drop Here"}
                    invalid={
                      errors["product_image"] && touched["product_image"]
                    }
                    error={errors["product_image"]}
                    showAddButton={false}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CForm>
      )}
    </Formik>
  );
};

export default AddEditProducts;
