import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { logout } from "src/store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../assets/icons/user.svg";
import { Link } from "react-router-dom";

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user?.data);
  const logoutHandler = () => dispatch(logout());

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg src={Avatar} className="c-avatar-img avatar" alt="user" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem>{`${user?.first_name || ""} ${
          user?.last_name || ""
        }`}</CDropdownItem>
        <CDropdownItem as={Link} to="/account">
          <CIcon name="cil-user" className="mfe-2" />
          Account
        </CDropdownItem>

        <CDropdownItem divider />
        <CDropdownItem onClick={logoutHandler}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
