import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { fetchList } from "src/store/actions/admin";
import { priceString } from "src/utils/helper";
import { defaultLimitPerPage } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import get from "lodash/get";
import FormInput from "src/components/FormInput";
import { searchUsers } from "src/utils";

const type = "walletLogs";

const getLogType = (type) => {
  switch (type) {
    case 1:
      return "Refund";
    case 2:
      return "Order";
    case 3:
      return "Recharge";
    case 4:
      return "Deducted";
    default:
      return "";
  }
};
const userFilterDefault = {
  label: "Search User",
  value: "",
};

const WalletLogs = ({ history }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.subAdmins.loading);
  const walletLogsList = useSelector(
    (state) => state.admin.walletLogs.data.data
  );
  const total = useSelector((state) => state.admin.subAdmins.data.count);
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);
  const [userFilter, setUserFilter] = useState(userFilterDefault);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        user_id: userFilter?.value || "",
      })
    );
  }, [dispatch, page, limitPerPage, userFilter]);

  return (
    <PageContainer
      title="Wallet Logs"
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          Filter by User
          <FormInput
            type="select-async"
            loadOptions={searchUsers}
            value={userFilter}
            onChange={(v) => setUserFilter(v)}
          />
        </CCol>
        <CCol sm={2}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => setUserFilter(userFilterDefault)}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={walletLogsList}
        fields={[
          "sr_no",
          "type",
          "user_name",
          "amount",
          "transaction_type",
          "description",
          "createdAt",
        ]}
        hover
        sorter
        clickableRows
        responsive
        loading={loading}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          type: (item) => <td>{getLogType(item?.type)}</td>,
          amount: (item) => <td>{priceString(get(item, "amount", 0))}</td>,
          transaction_type: (item) => (
            <td>
              <CBadge color={item?.amount_type === 1 ? "success" : "danger"}>
                {item?.amount_type === 1 ? "Credit" : "Debit"}
              </CBadge>
            </td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
          ),
          user_name: (item) => (
            <td>{`${get(item, "user_id.first_name")} ${get(
              item,
              "user_id.last_name"
            )}`}</td>
          ),
        }}
      />
    </PageContainer>
  );
};

export default WalletLogs;
