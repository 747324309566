import React from "react";
import { promotionSchema } from "../../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import {
  amountTypeDropdown,
  customProductTypeDropdown,
  saleTypeDropdown,
} from "src/utils/constants";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import _get from "lodash/get";
import { modifyDropDownValues, filterDropdownData } from "src/utils/helper";
import {
  searchProducts,
  searchSubCategories,
  searchCategories,
} from "src/utils";

const type = "promotions";
const defaultFormValues = {
  sale_type: saleTypeDropdown[0],
  buy_quantity: "",
  get_quantity: "",
  group_quantity: "",
  rule_name: "",
  rule_description: "",
  price_type: amountTypeDropdown[0],
  price: "",
  type: customProductTypeDropdown[0],
  product_ids: "",
  category_ids: "",
  product_not_included: [],
  sub_category_not_included: [],
};

const AddEditPromotion = ({
  isEdit,
  history,
  location: { state: promotion },
}) => {
  if (isEdit && !promotion) {
    history.push("/promotions");
  }
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);

  let defaultValues = isEdit ? cloneDeep(promotion) : defaultFormValues;
  if (isEdit) {
    defaultValues = {
      ...defaultFormValues,
      ...defaultValues,
      price_type: amountTypeDropdown.find(
        (v) => v.value === defaultValues.price_type
      ),
      type: customProductTypeDropdown.find(
        (v) => v.value === defaultValues.type
      ),
      sale_type: saleTypeDropdown.find(
        (v) => v.value === defaultValues.sale_type
      ),
      category_ids: modifyDropDownValues(
        _get(defaultValues, "category_ids", []),
        "category_name",
        "_id"
      ),
      product_ids: filterDropdownData(
        _get(defaultValues, "product_ids", []),
        ["category_id.category_name","sub_category_id.sub_category_name", "item_name", "product_sku"]
      ),
      product_not_included: filterDropdownData(
        _get(defaultValues, "product_not_included", []),
        ["category_id.category_name", "sub_category_id.sub_category_name", "item_name", "product_sku"]
      ),
      sub_category_not_included: filterDropdownData(
        _get(defaultValues, "sub_category_not_included", []),
        ["category_id.category_name", "sub_category_name"]
      ),
    };
  }

  const onSubmit = (values) => {
    let data = {
      ...values,
      type: values.type.value,
      sale_type: values.sale_type.value,
      price_type: values.price_type.value,
      product_ids: map(values.product_ids, "value"),
      category_ids: map(values.category_ids, "value"),
      product_not_included: map(values.product_not_included, "value"),
      sub_category_not_included: map(values.sub_category_not_included, "value"),
    };
    if (isEdit) {
      delete data.status;
      delete data.createdAt;
    }
    if (data.sale_type === 3) {
      delete data.get_quantity;
      delete data.buy_quantity;
    } else if (data.sale_type === 2) {
      delete data.group_quantity;
    } else {
      delete data.get_quantity;
      delete data.group_quantity;
    }
    data.type === 1 ? (data.category_ids = []) : (data.product_ids = []);
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer lg={8} title={isEdit ? "Edit Promotions" : "Add Promotions"}>
      <Formik
        initialValues={defaultValues}
        validationSchema={promotionSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="select"
              label={"Promotion Type"}
              name={"sale_type"}
              value={values["sale_type"]}
              onChange={(v) => setFieldValue("sale_type", v)}
              options={saleTypeDropdown}
              invalid={errors["sale_type"] && touched["sale_type"]}
              error={errors["sale_type"]}
              placeholder={"Select Sale type"}
            />
            {values.sale_type.value !== 3 && (
              <FormInput
                type="number"
                label={"Buy Quantity"}
                name={"buy_quantity"}
                value={values["buy_quantity"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["buy_quantity"] && touched["buy_quantity"]}
                error={errors["buy_quantity"]}
                placeholder={"Enter Buy Quantity"}
              />
            )}
            {values.sale_type.value === 2 && (
              <FormInput
                type="number"
                label={"Get Quantity"}
                name={"get_quantity"}
                value={values["get_quantity"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["get_quantity"] && touched["get_quantity"]}
                error={errors["get_quantity"]}
                placeholder={"Enter Get Quantity"}
              />
            )}
            {values.sale_type.value === 3 && (
              <FormInput
                type="number"
                label={"Group Quantity"}
                name={"group_quantity"}
                value={values["group_quantity"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["group_quantity"] && touched["group_quantity"]}
                error={errors["group_quantity"]}
                placeholder={"Enter Group Quantity"}
              />
            )}
            <FormInput
              type="text"
              label={"Promotion Name"}
              name={"rule_name"}
              value={values["rule_name"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["rule_name"] && touched["rule_name"]}
              error={errors["rule_name"]}
              placeholder={"Enter Rule name"}
            />
            <FormInput
              type="textarea"
              label={"Description"}
              name={"rule_description"}
              value={values["rule_description"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                errors["rule_description"] && touched["rule_description"]
              }
              error={errors["rule_description"]}
              placeholder={"Enter Description"}
            />
            <FormInput
              type="select"
              label={"Discount Type"}
              name={"price_type"}
              value={values["price_type"]}
              onChange={(v) => setFieldValue("price_type", v)}
              options={amountTypeDropdown}
              invalid={errors["price_type"] && touched["price_type"]}
              error={errors["price_type"]}
              placeholder={"Select Price type"}
            />
            <FormInput
              type="number"
              label={"Discount"}
              name={"price"}
              value={values["price"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["price"] && touched["price"]}
              error={errors["price"]}
              placeholder={"Enter Price"}
            />
            <FormInput
              type="select"
              label={"Select Type"}
              name={"type"}
              value={values["type"]}
              onChange={(v) => setFieldValue("type", v)}
              options={customProductTypeDropdown}
              invalid={errors["type"] && touched["type"]}
              error={errors["type"]}
              placeholder={"Select type"}
            />
            {values["type"].value === 1 ? (
              <FormInput
                type="select-async"
                label={"Select Products"}
                name={"product_ids"}
                value={values["product_ids"]}
                isMulti
                loadOptions={searchProducts}
                onChange={(v) => setFieldValue("product_ids", v)}
                invalid={errors["product_ids"] && touched["product_ids"]}
                error={errors["product_ids"]}
                placeholder={"Select Products"}
              />
            ) : (
              <FormInput
                type="select-async"
                label={"Select Categories"}
                name={"category_ids"}
                value={values["category_ids"]}
                isMulti
                // cacheOptions
                loadOptions={searchCategories}
                onChange={(v) => setFieldValue("category_ids", v)}
                invalid={errors["category_ids"] && touched["category_ids"]}
                error={errors["category_ids"]}
                placeholder={"Select Categories"}
              />
            )}
            {values["type"].value !== 1 && (
              <>
                <FormInput
                  type="select-async"
                  label={"Exclude Sub Categories"}
                  name={"sub_category_not_included"}
                  value={values["sub_category_not_included"]}
                  isMulti
                  loadOptions={searchSubCategories}
                  onChange={(v) =>
                    setFieldValue("sub_category_not_included", v)
                  }
                  invalid={
                    errors["sub_category_not_included"] &&
                    touched["sub_category_not_included"]
                  }
                  error={errors["sub_category_not_included"]}
                  placeholder={"Select Sub Categories"}
                />
                <FormInput
                  type="select-async"
                  label={"Exclude Products"}
                  name={"product_not_included"}
                  value={values["product_not_included"]}
                  isMulti
                  loadOptions={searchProducts}
                  onChange={(v) => setFieldValue("product_not_included", v)}
                  invalid={
                    errors["product_not_included"] &&
                    touched["product_not_included"]
                  }
                  error={errors["product_not_included"]}
                  placeholder={"Select Products"}
                />
              </>
            )}
            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditPromotion;
