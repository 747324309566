import React from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CBadge,
} from "@coreui/react";

import _get from "lodash/get";
import { getPaymentMethod } from "src/utils/helper";

const CustomerCard = ({ order }) => {
  return (
    <CCard>
      <CCardHeader>
        <CCardTitle className="cardtitle">Customer</CCardTitle>
      </CCardHeader>
      <CCardBody>
        <p>
          Name :
          <span className="txt">{` ${_get(
            order,
            "user_id.first_name",
            ""
          )} ${_get(order, "user_id.last_name", "")}  `}</span>
        </p>
        <hr />
        <p>
          Email :
          <span className="txt">
            {` ${_get(order, "user_id.email_address", "")}`}
          </span>
        </p>
        <hr />
        <p>
          Mobile No. :
          <span className="txt">{` ${_get(order, "user_id.mobile", "")}`}</span>
        </p>
        <hr />

        <p>
          Payment Method :
          <CBadge color="primary" className="ml-1">
            <span className="txt">
              {` ${getPaymentMethod(_get(order, "payment_method"))}`}
            </span>
          </CBadge>
        </p>
      </CCardBody>
    </CCard>
  );
};

export default CustomerCard;
