// export const baseUrl = "http://165.232.176.77:4008";
export const baseUrl = "https://api.koaala.co";
export const secretKey = "0293UUID*@(@SJ@(@)ND";
export const admin = "/admin";
export const tinyEditorApiKey =
  "dxjo213hjt1wshbuab85nevoef38tclcr9gmx69p83f3r7ms";

export const endpoints = {
  //auth
  login: "/admin/admin_login",
  logout: "/admin/admin_logout",
  forgotPassword: "/admin/forgot_password",
  changePassword: "/admin/change_password",

  //admin
  dashboard: "/admin/dashboard_details",
  uploadImage: "/api/uploadImage",
  listBookings: "/admin/listBookings",
  removeUserFromBooking: "/admin/removeUserFromBooking",
  listEvents: "/admin/listEvents",
  addEditEvents: "/admin/addEditEvents",
  listTeams: "/admin/listTeams",
  listVenues: "/admin/listVenues",
  addEditVenues: "/admin/addEditVenues",
  listOrganisers: "/admin/listOrganisers",
  addEditOrganisers: "/admin/addEditOrganisers",
  listSports: "/admin/listSports",
  addEditSports: "/admin/addEditSports",
  listLevels: "/admin/listLevels",
  addEditLevels: "/admin/addEditLevels",
  listAmenities: "/admin/listAmenities",
  addEditAmenities: "/admin/addEditAmenities",
  blockData: "/admin/blockData",
  listCategories: "/admin/list_all_category",
  listUsers: "/admin/admin_user_list",
  addEditUser: "/admin/add_edit_user",
  blockUsers: "/admin/admin_block_delete_user",
  addMoneyToWallet: "/admin/wallet_user",
  addEditCategory: "/admin/add_edit_category",
  blockCategories: "admin/admin_block_category",
  listSubcategories: "/admin/list_sub_category",
  addEditSubCategories: "/admin/add_edit_sub_category",
  blockSubcategories: "admin/admin_block_sub_category",
  listDetailedSubcategories: "/admin/list_detailed_sub_category",
  blockDetailedSubcategories: "/admin/admin_block_detailed_sub_category",
  addEditDetailedSubcategories: "/admin/add_edit_detailed_sub_category",
  listProducts: "/admin/list_product",
  addEditProduct: "/admin/add_edit_product",
  blockProducts: "/admin/block_unblock_product",
  listVariants: "/admin/list_inventory",
  addEditVariants: "/admin/add_product_inventory",
  blockUnblockVariants: "/admin/admin_block_inventory",
  listCustomProducts: "/admin/list_custom_product_rule",
  addEditCustomProducts: "/admin/add_edit_custom_product_rule",
  blockUnblockCustomProducts: "/admin/block_unblock_custom_product",
  productImageUpload: "/admin/product_image_upload",
  deleteProductImage: "/admin/delete_uploaded_image",
  listTaxes: "/admin/list_tax",
  addEditTax: "/admin/add_edit_tax",
  blockTax: "/admin/block_unblock_tax",
  listCoupons: "/admin/list_coupan",
  addEditCoupon: "/admin/add_edit_coupan",
  blockUnblockCoupon: "/admin/block_unblock_coupans",
  listDetailedProduct: "/admin/list_detailed_product",
  listSubAdmins: "/admin/list_sub_admin",
  addEditAdmins: "/admin/add_edit_admin",
  blockAdmins: `/admin/block_unblock_admin`,
  listOrders: "/admin/list_order",
  addOrder: "/admin/place_order",
  editOrder: "/admin/edit_order_details",
  orderInvoice: "/admin/invoice_order_pdf",
  orderStats: "/admin/order_stats",
  multiOrderUpdate: "/admin/multi_order_update",
  listSizeGuides: "/admin/list_size_guides",
  addEditSizeGuides: "/admin/add_edit_size_guide",
  blockSizeGuides: "/admin/block_unblock_size_guide",
  listExchangeReturn: "/admin/list_user_request",
  exchangeReturnActions: "/admin/admin_action_on_order",
  addExchangeReturn: "/admin/changes_order_by_admin",
  addProductToCard: "/admin/add_product_to_cart",
  viewCart: "/admin/view_cart",
  editCart: "/admin/edit_cart_by_admin",
  promoCode: "/admin/apply_promo_code",
  listPromotions: "/admin/list_sale_rule",
  AddEditPromotions: "/admin/add_edit_sale_rule",
  BlockUnblockPromotions: "/admin/block_unblock_sale_rule",
  listSettings: "/admin/list_default_details",
  editSettings: "/admin/add_edit_default_details",
  listTags: "/admin/list_tags",
  addEditTags: "/admin/add_edit_tags",
  blockTags: "/admin/block_unblock_tags",
  importFromExcel: "/admin/import_from_excel",
  exportProductsToExcel: "/admin/export_to_csv",
  exportOrdersToExcel: "/admin/export_order",
  exportUsersToExcel: "/admin/export_user_details",
  listMailLogs: "/admin/list_mail_logs",
  listMailFormats: "/admin/list_mail_formats",
  addEditMailFormats: "/admin/add_edit_mail_formats",
  listWalletLogs: "/admin/list_wallet_logs",
};
