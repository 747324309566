import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CRow, CCol, CInput } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import {
  getBadge,
  confirmModal,
  priceString,
  hasPermission,
} from "src/utils/helper";
import {
  defaultLimitPerPage,
  saleTypeDropdown,
  MODULES,
  PERMISSIONS,
} from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import _get from "lodash/get";
import { ROUTES } from "src/router/routes";

const type = "promotions";

const Promotions = ({ history }) => {
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.admin.promotions.loading);
  const promotionsList = useSelector((s) => s.admin.promotions.data.data);
  const total = useSelector((s) => s.admin.promotions.data.count);
  const message = useSelector((s) => s.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.MARKETING, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
      })
    );
  }, [dispatch, message, page, limitPerPage, searchFilter]);

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(item.status, (status) =>
      dispatch(blockUnblockRequest(type, { sale_id: item._id, status }))
    );
  };

  return (
    <PageContainer
      title="Promotions"
      rightButtons={
        writeAccess
          ? [
              {
                label: "Add Promotion",
                onClick: () =>
                  history.push(`${ROUTES.PROMOTIONS}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setSearch("");
              setSearchFilter("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={promotionsList}
        fields={[
          "sr_no",
          {
            key: "Edit",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "promotion_type",
          "promotion_name",
          "description",
          "type",
          "price",
          "price_type",
          "createdAt",
        ]}
        hover
        sorter
        clickableRows
        responsive
        loading={loading}
        addTableClasses={"default"}
        onRowClick={(item) =>
          history.push(`${ROUTES.PROMOTIONS}${ROUTES.EDIT}`, item)
        }
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          promotion_name: (item) => <td>{_get(item, "rule_name", "-")}</td>,
          promotion_type: (item) => (
            <td>
              {saleTypeDropdown.find((o) => o.value === item.sale_type).label}
            </td>
          ),
          description: (item) => <td>{_get(item, "rule_description", "-")}</td>,
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          type: (item) => (
            <td>{item.type === 1 ? "Products" : "Categories"}</td>
          ),
          price: (item) => <td>{priceString(_get(item, "price", ""))}</td>,
          price_type: (item) => (
            <td>{item.price_type === 1 ? "Percentage" : "Flat"}</td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
          ),
          Edit: (item, index) =>
            writeAccess ? (
              <td className="py-2">
                <CButton color="primary" variant="outline" size="sm">
                  <CIcon name="cil-pencil" /> Edit
                </CButton>
                <CButton
                  className={"ml-2"}
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Block" : " Unblock"}
                </CButton>
              </td>
            ) : (
              <tad>-</tad>
            ),
        }}
      />
    </PageContainer>
  );
};

export default Promotions;
