import React from "react";
import {
  CCol,
  CInput,
  CSwitch,
  CFormGroup,
  CInvalidFeedback,
  CInputRadio,
  CLabel,
  CTextarea,
} from "@coreui/react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Editor } from "@tinymce/tinymce-react";
import { tinyEditorApiKey } from "src/config/api";

const FormInput = ({
  name,
  label,
  onChange,
  type,
  value,
  options,
  onBlur,
  className,
  placeholder,
  invalid,
  error,
  touched,
  loadOptions,
  cacheOptions,
  getOptionLabel,
  getOptionValue,
  disabled = false,
  required,
  xs = "12",
  md = "9",
  labelsize = "3",
  ...rest
}) => {
  const field =
    type === "switch" ? (
      <CSwitch
        className={"mx-1 switchoverride"}
        variant={"3d"}
        color={"primary"}
        labelOn={"\u2713"}
        labelOff={"\u2715"}
        onChange={onChange}
        name={name}
        checked={value}
        defaultChecked={value}
        disabled={disabled}
      />
    ) : type === "select" ? (
      <>
        <Select
          options={options}
          value={value}
          name={name}
          id={name}
          onChange={onChange}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isDisabled={disabled}
          {...rest}
        />
        {invalid && <p className="field-error">{error}</p>}
      </>
    ) : type === "select-async" ? (
      <>
        <AsyncSelect
          placeholder={placeholder}
          cacheOptions={cacheOptions}
          loadOptions={loadOptions}
          value={value}
          name={name}
          id={name}
          onChange={onChange}
          isDisabled={disabled}
          {...rest}
        />
        {invalid && <p className="field-error">{error}</p>}
      </>
    ) : type === "radio" ? (
      <>
        {options.map((op, i) => (
          <CFormGroup variant="custom-radio" inline key={i}>
            <CInputRadio
              custom
              id={op.label}
              name={op.name}
              value={op.value}
              onChange={onChange}
            />
            <CLabel variant="custom-checkbox" htmlFor={op.label}>
              {op.label}
            </CLabel>
          </CFormGroup>
        ))}
        {invalid && <p className="field-error">{error}</p>}
      </>
    ) : type === "textarea" ? (
      <>
        <CTextarea
          rows={"4"}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          invalid={invalid}
          className={"field-input"}
          {...rest}
        />
        {invalid && <p className="field-error">{error}</p>}
      </>
    ) : type === "html-editor" ? (
      <>
        <Editor
          apiKey={tinyEditorApiKey}
          value={value}
          onEditorChange={onChange}
          init={{
            menubar: false,
            plugins: "link image code paste lists wordcount table advlist",
            toolbar: [
              "undo redo | fontsizeselect | formatselect | forecolor backcolor | bold italic underline strikethrough",
              "alignleft aligncenter alignright alignjustify | bullist numlist | image | table | code | link unlink",
            ],
            paste_as_text: true,
            min_height: 400,
          }}
        />
        {invalid && <p className="field-error">{error}</p>}
      </>
    ) : (
      <>
        <CInput
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          invalid={invalid}
          className={"field-input"}
          disabled={disabled}
          required={required}
          {...rest}
        />
        {invalid && (
          <CInvalidFeedback className="help-block">
            {invalid && error}
          </CInvalidFeedback>
        )}
      </>
    );

  return (
    <CFormGroup row={!!label} className={className}>
      {!!label && (
        <CCol md={labelsize} className="d-flex align-items-center mb-0">
          <label htmlFor="state_name" className="mb-0">
            {label}
          </label>
        </CCol>
      )}
      {!!label ? (
        <CCol xs={xs} md={md} {...rest}>
          {field}
        </CCol>
      ) : (
        field
      )}
    </CFormGroup>
  );
};

export default FormInput;
