import React, { useState, useEffect } from "react";
import { CButton, CRow, CCol, CForm } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchList, addEditRequest } from "src/store/actions/admin";
import { mailFormatsTypeDropdown, MODULES, PERMISSIONS } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import _get from "lodash/get";
import FormInput from "src/components/FormInput";
import { Formik } from "formik";
import { mailFormatSchema } from "../../../config/formSchema";
import { hasPermission } from "src/utils/helper";

const type = "mailFormats";

const MailFormats = ({ history }) => {
  const dispatch = useDispatch();
  const data = useSelector((s) => s.admin.mailFormats.data.data);
  const message = useSelector((state) => state.admin.modal);
  const [mailType, setMailType] = useState(mailFormatsTypeDropdown[0]);

  useEffect(() => {
    dispatch(
      fetchList(type, {
        type: mailType.value,
      })
    );
  }, [dispatch, message, mailType]);

  const updateEmailFormat = (values) => {
    dispatch(
      addEditRequest(type, {
        type: mailType.value,
        subject: _get(values, "subject", ""),
        header: _get(values, "header", ""),
        footer: _get(values, "footer", ""),
      })
    );
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <PageContainer title="Email Formats">
      <Formik
        initialValues={data}
        onSubmit={updateEmailFormat}
        validationSchema={mailFormatSchema}
        enableReinitialize
      >
        {({ values, dirty, errors, touched, handleChange, handleSubmit }) => (
          <CForm onSubmit={handleSubmit} onKeyDown={onKeyDown}>
            <CRow className={"mb-2"}>
              <CCol sm={6}>
                <FormInput
                  label={"Email Type"}
                  type="select"
                  options={mailFormatsTypeDropdown}
                  value={mailType}
                  onChange={(v) => setMailType(v)}
                />
              </CCol>
              <CCol sm={3}>
                {!!hasPermission(MODULES.EMAILS, PERMISSIONS.WRITE) && (
                  <CButton type="submit" color="primary" disabled={!dirty}>
                    Update
                  </CButton>
                )}
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol>
                <FormInput
                  label={"Subject"}
                  type={"text"}
                  value={values["subject"] || ""}
                  name={"subject"}
                  onChange={handleChange}
                  invalid={errors["subject"] && touched["subject"]}
                  error={errors["subject"]}
                />
                <FormInput
                  label={"Header"}
                  type={"textarea"}
                  value={values["header"] || ""}
                  name={"header"}
                  onChange={handleChange}
                  invalid={errors["header"] && touched["header"]}
                  error={errors["header"]}
                />
                <FormInput
                  label={"Footer"}
                  type={"textarea"}
                  value={values["footer"] || ""}
                  name={"footer"}
                  onChange={handleChange}
                  invalid={errors["footer"] && touched["footer"]}
                  error={errors["footer"]}
                />
              </CCol>
            </CRow>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default MailFormats;
