export const defaultLimitPerPage = 15;
export const limitPerPage = 15;

export const PERMISSIONS = {
  READ: "read",
  WRITE: "write",
};

export const MODULES = {
  DASHBOARD: "Dashboard",
  PRODUCTS: "Products",
  CATEGORIES: "Categories",
  ORDERS: "Orders",
  MARKETING: "Marketing",
  USERS: "Users",
  WALLET_LOGS: "Wallet_Logs",
  SUB_ADMINS: "Sub_Admins",
  EMAILS: "Emails",
  SETTINGS: "Settings",
};

export const reducerTypes = [
  "dashboard",
  "users",
  "coupons",
  "categories",
  "subCategories",
  "detailedSubCategories",
  "subAdmins",
  "products",
  "taxes",
  "orders",
  "sizeGuides",
  "cart",
  "promocode",
  "variants",
  "customProducts",
  "exchangeReturns",
  "promotions",
  "settings",
  "tags",
  "mailLogs",
  "mailFormats",
  "orderStats",
  "walletLogs",
];

export const orderStatusDropdown = [
  { label: "PENDING", value: 0 },
  { label: "PROCESSING", value: 1 },
  // { label: "ACCEPTED", value: 2 },
  { label: "SHIPPED", value: 3 },
  { label: "DELIVERED", value: 4 },
  { label: "PARTIAL REFUND", value: 5 },
  { label: "FULL REFUND", value: 6 },
  { label: "CANCELLED", value: 7 }, // cancel by admin change to cancel
  // { label: "CANCELLED BY USER", value: 8 },
  { label: "CREATED FROM EXCHANGE", value: 9 },
];

export const payemntMethodDropdown = [
  { label: "DIGITAL", value: 0 },
  { label: "COD", value: 1 },
];

export const amountTypeDropdown = [
  { label: "FLAT", value: 2 },
  { label: "PERCENTAGE", value: 1 },
];

export const customProductTypeDropdown = [
  { label: "FOR PRODUCTS", value: 1 },
  { label: "FOR CATEGORY", value: 2 },
];

export const customTypeDropdown = [
  { label: "FOR PRODUCTS", value: 1 },
  { label: "FOR CATEGORY", value: 2 },
  { label: "FOR SUB-CATEGORY", value: 3 },
  { label: "FOR DETAILED-SUB-CATEGORY", value: 4 },
];

export const saleTypeDropdown = [
  { label: "NORMAL", value: 1 },
  { label: "BUY X GET Y", value: 2 },
  { label: "GROUP OF PRODUCTS", value: 3 },
];

export const mailFormatsTypeDropdown = [
  { label: "WELCOME", value: 1 },
  { label: "NEW ORDER", value: 2 },
  { label: "DELIVER ORDER", value: 3 },
  { label: "CANCEL ORDER", value: 4 },
  { label: "SHIPPED ORDER", value: 5 },
  { label: "REFUND ORDER COMPLETE", value: 6 },
  { label: "EXCHANGE ORDER COMPLETE", value: 7 },
  { label: "RESET PASSWORD", value: 8 },
  { label: "EXCHANGE REQUESTED", value: 9},
  { label: "RETURN REQUESTED", value: 10 },
];

export const defaultRoles = [
  {
    module: MODULES.DASHBOARD,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.PRODUCTS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.CATEGORIES,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.ORDERS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.MARKETING,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.USERS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.WALLET_LOGS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.SUB_ADMINS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.EMAILS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
  {
    module: MODULES.SETTINGS,
    permission: [PERMISSIONS.READ, PERMISSIONS.WRITE],
  },
];
