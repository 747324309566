import { AxiosInstance } from "./client";
import { CategoriesApis } from "./endpoints";

export const getCategories = (params) => {
  return AxiosInstance()
    .get(CategoriesApis.LIST_CATEGORIES, { params })
    .then((response) => response?.data?.data);
};

export const getSubCategories = (params) => {
  return AxiosInstance()
    .get(CategoriesApis.LIST_SUB_CATEGORIES, { params })
    .then((response) => response?.data?.data);
};

export const getDetailedSubCategories = (params) => {
  return AxiosInstance()
    .get(CategoriesApis.LIST_DETAILED_SUB_CATEGORIES, { params })
    .then((response) => response?.data?.data);
};
