import React from "react";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import { Formik } from "formik";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { accountPassSchema } from "src/config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";

const defaultFormValues = {
  old_password: "",
  new_password: "",
  confirm_new_password: "",
};

const Account = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.modal.loading);

  const onSubmit = ({ old_password, new_password }) => {
    dispatch(addEditRequest("changePassword", { old_password, new_password }));
  };

  return (
    <PageContainer xs={4} title="Change Password">
      <Formik
        initialValues={defaultFormValues}
        validationSchema={accountPassSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="password"
              name={"old_password"}
              id={"old_password"}
              value={values["old_password"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["old_password"] && touched["old_password"]}
              error={errors["old_password"]}
              placeholder={"Enter old password"}
            />
            <FormInput
              type="password"
              name={"new_password"}
              id={"new_password"}
              value={values["new_password"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["new_password"] && touched["new_password"]}
              error={errors["new_password"]}
              placeholder={"Enter new password"}
            />
            <FormInput
              type="password"
              name={"confirm_new_password"}
              id={"confirm_new_password"}
              value={values["confirm_new_password"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                errors["confirm_new_password"] &&
                touched["confirm_new_password"]
              }
              error={errors["confirm_new_password"]}
              placeholder={"Confirm new password"}
            />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default Account;
