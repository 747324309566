import * as Yup from "yup";

//////////////////////////////////// Login /////////////////////////////////////////
export const loginFields = [
  {
    type: "text",
    name: "email",
    icon: "cil-user",
    placeholder: "Email Address",
    label: "Email",
  },
  {
    type: "password",
    name: "pass",
    icon: "cil-lock-locked",
    placeholder: "Password",
    label: "Password",
  },
];
export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  pass: Yup.string().required("Required"),
});

//////////////////////////////////// Sub Admin /////////////////////////////////////////
export const subAdminSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  role: Yup.array().required("Required"),
});

//////////////////////////////////// Sub Category /////////////////////////////////////////
export const categorySchema = Yup.object().shape({
  category_name: Yup.string().required("Required"),
  category_slug: Yup.string().required("Required"),
  category_image: Yup.array().min(1, "Image is required").required("Required"),
  category_image_mobile: Yup.array()
    .min(1, "Image is required")
    .required("Required"),
});

//////////////////////////////////// Sub Category /////////////////////////////////////////
export const subCategorySchema = Yup.object().shape({
  category_id: Yup.object().required("Required"),
  sub_category_name: Yup.string().required("Required"),
  sub_category_slug: Yup.string().required("Required"),
});

//////////////////////////////////// Sub Category /////////////////////////////////////////
export const detailedSubCategorySchema = Yup.object().shape({
  category_id: Yup.object().required("Required"),
  sub_category_id: Yup.object().required("Required"),
  detailed_sub_category_name: Yup.string().required("Required"),
  detailed_sub_category_slug: Yup.string().required("Required"),
});

//////////////////////////////////// Order /////////////////////////////////////////
export const orderFields = [
  {
    type: "select",
    name: "customer_id",
    placeholder: "Customer",
    label: "Customer",
  },
  {
    type: "select",
    name: "status",
    placeholder: "Status",
    label: "Status",
  },
  {
    type: "text",
    name: "sub_category_name",
    placeholder: "Sub Category Name",
    label: "Sub Category Name",
  },
];

export const addProductToOrderSchema = Yup.object().shape({
  product_id: Yup.object().required("Required"),
  quantity: Yup.number().min(1).required("Required"),
  variant_details: Yup.object(),
});

//////////////////////////////////// taxes /////////////////////////////////////////
export const taxSchema = Yup.object().shape({
  tax_label: Yup.string().required("Required"),
  special: Yup.bool().required("Required"),
  state_name: Yup.string().when(["special"], (special, schema) =>
    special ? schema.required("Required") : schema.optional()
  ),
  state_code: Yup.string().when(["special"], (special, schema) =>
    special ? schema.required("Required") : schema.optional()
  ),
  min_amount: Yup.number().required("Required"),
  less_than_min_amount: Yup.object().shape({
    sgst: Yup.number().min(1, "must be > 1").required("Required"),
    cgst: Yup.number().min(1, "must be > 1").required("Required"),
    igst: Yup.number().min(1, "must be > 1").required("Required"),
  }),
  greater_than_min_amount: Yup.object().shape({
    sgst: Yup.number().min(1, "must be > 1").required("Required"),
    cgst: Yup.number().min(1, "must be > 1").required("Required"),
    igst: Yup.number().min(1, "must be > 1").required("Required"),
  }),
});

//////////////////////////////////// Size Guide /////////////////////////////////////////
export const sizeGuideSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  // category_id: Yup.object().required("Required"),
  table_title: Yup.string().optional(),
  table_caption: Yup.string().optional(),
  table_data: Yup.array().required(),
});

//////////////////////////////////// Users /////////////////////////////////////////
export const userSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email_address: Yup.string().email("Invalid email").required("Required"),
  mobile: Yup.string().required("Required"),
  country_code: Yup.string().optional(),
  country: Yup.string().optional(),
  profile_image: Yup.array().optional(),
  both_address_same: Yup.bool().optional(),
  shipping_address: Yup.object().shape({
    first_name: Yup.string().optional(),
    last_name: Yup.string().optional(),
    company: Yup.string().optional(),
    address_line_one: Yup.string().optional(),
    address_line_second: Yup.string().optional(),
    city: Yup.object().optional(),
    postcode_zip: Yup.string().optional(),
    country_region: Yup.string().optional(),
    state_country: Yup.object().optional(),
    country_code: Yup.string().optional(),
    phone_number: Yup.string().optional(),
    email_address: Yup.string().email("Invalid email").optional(),
  }),
  billing_address: Yup.object().shape({
    first_name: Yup.string().optional(),
    last_name: Yup.string().optional(),
    company: Yup.string().optional(),
    address_line_one: Yup.string().optional(),
    address_line_second: Yup.string().optional(),
    city: Yup.object().optional(),
    postcode_zip: Yup.string().optional(),
    country_region: Yup.string().optional(),
    state_country: Yup.object().optional(),
    country_code: Yup.string().optional(),
    phone_number: Yup.string().optional(),
    email_address: Yup.string().email("Invalid email").optional(),
  }),
});

//////////////////////////////////// Address /////////////////////////////////////////
export const addressSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  company: Yup.string(),
  address_line_one: Yup.string(),
  address_line_second: Yup.string(),
  city: Yup.mixed(),
  postcode_zip: Yup.number().required("Required"),
  country_region: Yup.string(),
  state_country: Yup.mixed(),
  country_code: Yup.string(),
  phone_number: Yup.string().optional(),
  email_address: Yup.string().email("Invalid email"),
});

//////////////////////////////////// Coupon /////////////////////////////////////////
export const couponSchema = Yup.object().shape({
  product_not_included: Yup.array().optional(),
  category_not_included: Yup.array().optional(),
  type: Yup.object().typeError("Required").required("Required"),
  min_amount: Yup.number().typeError("must be a number"),
  limit_count: Yup.number().typeError("must be a number"),
  user_limit_count: Yup.number().typeError("must be a number"),
  coupan_code: Yup.string().required("Required"),
  coupan_amount: Yup.number()
    .min(1, "must be greater than 0")
    .typeError("must be a number"),
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
  apply_on_sale: Yup.boolean().required("Required"),
});

//////////////////////////////////// Product /////////////////////////////////////////
export const productSchema = Yup.object().shape({
  item_name: Yup.string().required("Required"),
  product_slug: Yup.string().required("Required"),
  product_sku: Yup.string().required("Required"),
  refund: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  description_name: Yup.string().required("Required"),
  additional_info: Yup.string(),
  category_id: Yup.object().typeError("Required").required("Required"),
  sub_category_id: Yup.object(),
  detailed_sub_category_id: Yup.object(),
  size_id: Yup.mixed(),
  related_product_id: Yup.array().required("Required"),
  tag_id: Yup.array().required("Required"),
  product_image: Yup.array().min(1, "Image is required").required("Required"),
});

export const customProductSchema = Yup.object().shape({
  rule_name: Yup.string().required("Required"),
  rule_description: Yup.string().optional(),
  is_front: Yup.bool().required("Required"),
  is_back: Yup.bool().required("Required"),
  price: Yup.string().required("Required"),
  price_type: Yup.object().typeError("Required").required("Required"),
  type: Yup.object().typeError("Required").required("Required"),
  product_ids: Yup.array().when(["type"], (type, schema) =>
    type.value === 1
      ? schema.typeError("Required").required("Required")
      : schema.optional()
  ),
  category_ids: Yup.array().when(["type"], (type, schema) =>
    type.value === 2
      ? schema.typeError("Required").required("Required")
      : schema.optional()
  ),
  sub_category_ids: Yup.array().when(["type"], (type, schema) =>
    type.value === 3
      ? schema.typeError("Required").required("Required")
      : schema.optional()
  ),
  detailed_sub_category_ids: Yup.array().when(["type"], (type, schema) =>
    type.value === 4
      ? schema.typeError("Required").required("Required")
      : schema.optional()
  ),
  product_not_included: Yup.array().optional(),
});

export const promotionSchema = Yup.object().shape({
  sale_type: Yup.object().typeError("Required").required("Required"),
  rule_name: Yup.string().required("Required"),
  rule_description: Yup.string().optional(),
  price: Yup.number().required("Required"),
  price_type: Yup.object().typeError("Required").required("Required"),
  type: Yup.object().typeError("Required").required("Required"),
  product_ids: Yup.array().when(["type"], (type, schema) =>
    type.value === 1
      ? schema.typeError("Required").required("Required")
      : schema.optional()
  ),
  category_ids: Yup.array().when(["type"], (type, schema) =>
    type.value === 2
      ? schema.typeError("Required").min(1, "Required").required("Required")
      : schema.optional()
  ),
  sub_category_not_included: Yup.array(),
  product_not_included: Yup.array(),
  buy_quantity: Yup.number()
    .min(1, "min. 1")
    .when(["sale_type"], (type, schema) =>
      type.value !== 3 ? schema.required("Required") : schema.optional()
    ),
  get_quantity: Yup.number()
    .min(1, "min. 1")
    .when(["sale_type"], (type, schema) =>
      type.value === 2 ? schema.required("Required") : schema.optional()
    ),
  group_quantity: Yup.number()
    .min(1, "min. 1")
    .when(["sale_type"], (type, schema) =>
      type.value === 3 ? schema.required("Required") : schema.optional()
    ),
});

//////////////////////////////////// Tags /////////////////////////////////////////
export const tagSchema = Yup.object().shape({
  tag_name: Yup.string().required("Required"),
});

//////////////////////////////////// Variants /////////////////////////////////////////
export const variantSchema = Yup.object().shape({
  size: Yup.string().required("Required"),
  sku: Yup.string().required("Required"),
  color: Yup.string(),
  price: Yup.number().required("Required"),
  quantity: Yup.number().required("Required"),
});

//////////////////////////////////// Mail Formats /////////////////////////////////////////
export const mailFormatSchema = Yup.object().shape({
  subject: Yup.string().required("Required"),
  header: Yup.string().required("Required"),
  footer: Yup.string().required("Required"),
});

//////////////////////////////////// Exchange product /////////////////////////////////////////
export const exchangeSchema = Yup.object().shape({
  variant: Yup.object().required("Required"),
  quantity: Yup.number().required("Required"),
  originalQuantity: Yup.number(),
});
export const returnSchema = Yup.object().shape({
  quantity: Yup.number().required("Required"),
  originalQuantity: Yup.number(),
});

//////////////////////////////////// Exchange product /////////////////////////////////////////
export const accountPassSchema = Yup.object().shape({
  old_password: Yup.string().required("Required"),
  new_password: Yup.string().required("Required"),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Required"),
});
