import React from "react";
import {
  CForm,
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import { useSelector, useDispatch } from "react-redux";
import { addEditRequest } from "src/store/actions/admin";
import { variantSchema } from "src/config/formSchema";
import _get from "lodash/get";

const defaultValues = {
  sku: "",
  size: "",
  color: "",
  price: 0,
  quantity: 0,
};
const type = "variants";
const Variant = ({ isEdit, history, location: { state: variant } }) => {
  if (isEdit && !variant) {
    history.push("/variants");
  }

  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);
  const onSubmit = (values) => {
    const data = {
      sku: values.sku,
      size: values.size,
      color: values.color,
      price: values.price,
      quantity: values.quantity,
      product_id: _get(values, "product_id._id", ""),
    };
    if (isEdit) {
      data.id = values._id;
    }
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <CRow>
      <CCol lg={8}>
        <CCard>
          <CCardHeader>Add Variant</CCardHeader>

          <CCardBody>
            <Formik
              initialValues={
                isEdit ? variant : { ...defaultValues, ...variant }
              }
              onSubmit={onSubmit}
              validationSchema={variantSchema}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <CForm onSubmit={handleSubmit}>
                  <FormInput
                    type="text"
                    label={"SKU"}
                    name={"sku"}
                    disabled
                    value={values["sku"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["sku"] && touched["sku"]}
                    error={errors["sku"]}
                    placeholder={"Enter SKU"}
                  />
                  <FormInput
                    type="text"
                    label={"Size"}
                    name={"size"}
                    value={values["size"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["size"] && touched["size"]}
                    error={errors["size"]}
                    placeholder={"Enter Size"}
                  />
                  <FormInput
                    type="text"
                    label={"Color"}
                    name={"color"}
                    value={values["color"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["color"] && touched["color"]}
                    error={errors["color"]}
                    placeholder={"Enter Color"}
                  />
                  <FormInput
                    type="number"
                    label={"Quantity"}
                    name={"quantity"}
                    value={values["quantity"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["quantity"] && touched["quantity"]}
                    error={errors["quantity"]}
                    placeholder={"Enter Quantity"}
                  />
                  <FormInput
                    type="number"
                    label={"Price"}
                    name={"price"}
                    value={variant['price'] || values["price"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors["price"] && touched["price"]}
                    error={errors["price"]}
                    placeholder={"Enter Price"}
                  />

                  <hr />
                  <CButton
                    type="submit"
                    color="primary"
                    disabled={!dirty || loading}
                  >
                    {loading && (
                      <CSpinner
                        component="span"
                        className="mr-2"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    Submit
                  </CButton>
                </CForm>
              )}
            </Formik>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Variant;
