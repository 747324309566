import { ROUTES } from "./routes";

const routes = [
  {
    path: ROUTES.DASHBOARD,
    name: "Dashboard",
    exact: true,
  },
  {
    path: ROUTES.USERS,
    name: "Users",
    exact: true,
  },
  {
    path: ROUTES.CATEGORIES,
    name: "Categories",
    exact: true,
  },
  {
    path: `${ROUTES.CATEGORIES}${ROUTES.ADD}`,
    name: "Add Category",
    exact: true,
  },
  {
    path: `${ROUTES.CATEGORIES}${ROUTES.EDIT}`,
    name: "Edit Category",
    exact: true,
  },
  {
    path: `${ROUTES.SUB_CATEGORIES}`,
    name: "Sub-Categories",
    exact: true,
  },
  {
    path: `${ROUTES.SUB_CATEGORIES}${ROUTES.ADD}`,
    name: "Add Sub-Category",
    exact: true,
  },
  {
    path: `${ROUTES.SUB_CATEGORIES}${ROUTES.EDIT}`,
    name: "Edit Sub-Category",
    exact: true,
  },
  {
    path: `${ROUTES.DETAILED_SUB_CATEGORIES}`,
    name: "Detailed Sub Categories",
    exact: true,
  },
  {
    path: `${ROUTES.DETAILED_SUB_CATEGORIES}${ROUTES.ADD}`,
    name: "Add Detailed Sub Category",
    exact: true,
  },
  {
    path: `${ROUTES.DETAILED_SUB_CATEGORIES}${ROUTES.EDIT}`,
    name: "Edit Detailed Sub Category",
    exact: true,
  },
  {
    path: `${ROUTES.PRODUCTS}`,
    name: "Products",
    exact: true,
  },
  {
    path: `${ROUTES.PRODUCTS}${ROUTES.ADD}`,
    name: "Add Product",
    exact: true,
  },
  {
    path: `${ROUTES.PRODUCTS}${ROUTES.EDIT}`,
    name: "Edit Product",
    exact: true,
  },
  { path: `${ROUTES.TAX_SLABS}`, name: "Tax Slabs", exact: true },
  {
    path: `${ROUTES.TAX_SLABS}${ROUTES.ADD}`,
    name: "Add Tax Slab",
    exact: true,
  },
  {
    path: `${ROUTES.TAX_SLABS}${ROUTES.EDIT}`,
    name: "Edit Tax",
    exact: true,
  },
  {
    path: `${ROUTES.USERS}${ROUTES.ADD}`,
    name: "Add User",
    exact: true,
  },
  {
    path: `${ROUTES.USERS}${ROUTES.EDIT}${ROUTES.ID}`,
    name: "Edit User",
    exact: true,
  },
  {
    path: `${ROUTES.COUPONS}`,
    name: "Coupons",
    exact: true,
  },
  {
    path: `${ROUTES.COUPONS}${ROUTES.ADD}`,
    name: "Add Coupon",
    exact: true,
  },
  {
    path: `${ROUTES.COUPONS}${ROUTES.EDIT}`,
    name: "Edit Coupon",
    exact: true,
  },
  {
    path: `${ROUTES.PROMOTIONS}`,
    name: "Promotions",
    exact: true,
  },
  {
    path: `${ROUTES.PROMOTIONS}${ROUTES.ADD}`,
    name: "Add Promotion",
    exact: true,
  },
  {
    path: `${ROUTES.PROMOTIONS}${ROUTES.EDIT}`,
    name: "Edit Promotion",
  },
  {
    path: `${ROUTES.VARIANTIONS}`,
    name: "Product Variant",
    exact: true,
  },
  {
    path: `${ROUTES.VARIANTIONS}${ROUTES.ADD}`,
    name: "Add Product Variant",
    exact: true,
  },
  {
    path: `${ROUTES.VARIANTIONS}${ROUTES.EDIT}`,
    name: "Edit Product Variant",
    exact: true,
  },
  {
    path: `${ROUTES.PERSONALISATION}`,
    name: "Personalized Products",
    exact: true,
  },
  {
    path: `${ROUTES.PERSONALISATION}${ROUTES.ADD}`,
    name: "Add Personalized Product",
    exact: true,
  },
  {
    path: `${ROUTES.PERSONALISATION}${ROUTES.EDIT}`,
    name: "Edit Personalized Product",
    exact: true,
  },
  {
    path: `${ROUTES.SUB_ADMINS}`,
    name: "Sub Admins",
    exact: true,
  },
  {
    path: `${ROUTES.SUB_ADMINS}${ROUTES.ADD}`,
    name: "Add Sub Admin",
    exact: true,
  },
  {
    path: `${ROUTES.SUB_ADMINS}${ROUTES.EDIT}`,
    name: "Edit Sub Admin",
    exact: true,
  },
  {
    path: `${ROUTES.ORDERS}`,
    name: "Orders",
    exact: true,
  },
  {
    path: `${ROUTES.ORDERS}${ROUTES.ADD}`,
    name: "Add Order",
    exact: true,
  },
  {
    path: `${ROUTES.ORDERS}${ROUTES.EDIT}`,
    name: "Edit Order",
    exact: true,
  },
  {
    path: `${ROUTES.EXCHANGES}`,
    name: "Exchanges",
    exact: true,
  },
  {
    path: `${ROUTES.RETURNS}`,
    name: "Returns",
    exact: true,
  },
  {
    path: `${ROUTES.SIZE_GUIDES}`,
    name: "Size Guides",
    exact: true,
  },
  {
    path: `${ROUTES.EXCHANGES}${ROUTES.ADD}`,
    name: "Add Size Guide",
    exact: true,
  },
  {
    path: `${ROUTES.EXCHANGES}${ROUTES.EDIT}`,
    name: "Edit Size Guide",
    exact: true,
  },
  {
    path: `${ROUTES.TAGS}`,
    name: "Tags",
    exact: true,
  },
  {
    path: `${ROUTES.TAGS}${ROUTES.ADD}`,
    name: "Add Tags",
    exact: true,
  },
  {
    path: `${ROUTES.TAGS}${ROUTES.EDIT}`,
    name: "Edit Tags",
    exact: true,
  },
  {
    path: `${ROUTES.DEFAULTS}`,
    name: "Default Settings",
    exact: true,
  },
  {
    path: `${ROUTES.CONTENT}`,
    name: "Content",
    exact: true,
  },
  {
    path: `${ROUTES.MAIL_LOGS}`,
    name: "Email Logs",
    exact: true,
  },
  {
    path: `${ROUTES.MAIL_FORMATS}`,
    name: "Email Formats",
    exact: true,
  },
];

export default routes;
