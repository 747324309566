import { SUCCESS, REQUEST, FAIL, LIST, MODAL, LOGOUT } from "./actionTypes";
import { GET, POST } from "src/config/services";
import { toast } from "react-toastify";
import { getFetchUrl, getAddEditUrl, getBlockUrl } from "src/utils/helper";

///////////////////////////////////////////////////////////////////////////////////////////
// Fetch Listing from Api according to type ///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
export const fetchList = (type, params) => {
  return (dispatch) => {
    dispatch({ type: LIST[REQUEST] });
    GET(getFetchUrl(type), params)
      .then((res) => {
        dispatch({ type: LIST[SUCCESS], [type]: res.data, name: type });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.status === 401) {
            dispatch({ type: LOGOUT[SUCCESS] });
          } else {
            dispatch({ type: LIST[FAIL], error: err.response.data.message });
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          dispatch({ type: LIST[FAIL], error: err.message });
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////
//// Edit Listing from Api according to type ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
export const addEditRequest = (type, data, history) => {
  return (dispatch) => {
    dispatch({ type: MODAL[REQUEST] });
    POST(getAddEditUrl(type), data)
      .then((res) => {
        dispatch({ type: MODAL[SUCCESS], message: res.message });
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        if (history) history.goBack();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.status === 401) {
            dispatch({ type: LOGOUT[SUCCESS] });
          } else {
            dispatch({ type: MODAL[FAIL], error: err.response.data.message });
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          dispatch({ type: MODAL[FAIL], error: err.message });
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////
//// Edit Listing from Api according to type ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
export const blockUnblockRequest = (type, data, history) => {
  return (dispatch) => {
    dispatch({ type: MODAL[REQUEST] });
    POST(getBlockUrl(type), data)
      .then((res) => {
        dispatch({ type: MODAL[SUCCESS], message: res.message });
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        if (history) history.goBack();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.status === 401) {
            dispatch({ type: LOGOUT[SUCCESS] });
          } else {
            dispatch({ type: MODAL[FAIL], error: err.response.data.message });
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          dispatch({ type: MODAL[FAIL], error: err.message });
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };
};
