import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { getBadge, confirmModal, hasPermission } from "src/utils/helper";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import _get from "lodash/get";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import { ROUTES } from "src/router/routes";
import { searchCategories } from "src/utils";

const type = "subCategories";

const SubCategories = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: subCategoriesList, count: total },
  } = useSelector((state) => state.admin.subCategories);
  const message = useSelector((state) => state.admin.modal);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.CATEGORIES, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        id: _get(categoryFilter, "value", ""),
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
      })
    );
    setSelectedItems([]);
  }, [dispatch, message, search, page, categoryFilter, limitPerPage]);

  useEffect(() => {
    dispatch(fetchList("categories"));
  }, [dispatch]);

  const searchHandler = (e) => {
    e.preventDefault();
    dispatch(fetchList(type, { search }));
  };

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      (status) =>
        dispatch(
          blockUnblockRequest(type, { sub_category_id: item._id, status })
        ),
      "Publish",
      "Unpublish"
    );
  };

  const deleteSubCategory = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      (status) =>
        dispatch(
          blockUnblockRequest(type, { sub_category_id: item._id, status: 2 })
        ),
      "Delete",
      "Delete"
    );
  };

  const resetFilter = () => {
    setCategoryFilter("");
    setSearch("");
    dispatch(
      fetchList(type, {
        id: _get(categoryFilter, "value", ""),
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
      })
    );
  };

  const selectRow = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems((v) => [...v, item._id]);
    } else {
      setSelectedItems((v) => v.filter((i) => i !== item._id));
    }
  };

  const multiRowsSelect = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems(subCategoriesList.map((v) => v._id));
    } else {
      setSelectedItems([]);
    }
  };

  const bulkActions = (actiontype) => {
    if (actiontype === "delete") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              sub_category_ids: selectedItems,
              status: 2,
            })
          ),
        "Delete"
      );
    } else if (actiontype === "block") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              sub_category_ids: selectedItems,
              status: 0,
            })
          ),
        "Unpublish"
      );
    } else if (actiontype === "unblock") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              sub_category_ids: selectedItems,
              status: 1,
            })
          ),
        "Publish"
      );
    }
  };

  return (
    <PageContainer
      title="Sub Categories"
      rightButtons={
        writeAccess
          ? [
              {
                label: "Add Sub Categories",
                onClick: () =>
                  history.push(`${ROUTES.SUB_CATEGORIES}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-0"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={3}>
          Filter by Category
          <FormInput
            type="select-async"
            placeholder="Search Category"
            loadOptions={searchCategories}
            value={categoryFilter}
            onChange={(v) => setCategoryFilter(v)}
          />
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={resetFilter}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      {!!selectedItems.length && (
        <CRow className={"mt-0 mb-3"}>
          <CCol>
            <CButton
              color="primary"
              variant="outline"
              className="resetButton"
              onClick={() => bulkActions("unblock")}
            >
              Publish
            </CButton>

            <CButton
              color="primary"
              variant="outline"
              className="ml-2 resetButton"
              onClick={() => bulkActions("block")}
            >
              Unpublish
            </CButton>
            <CButton
              color="danger"
              variant="outline"
              className="ml-2 resetButton"
              onClick={() => bulkActions("delete")}
            >
              Delete
            </CButton>
          </CCol>
        </CRow>
      )}

      <CDataTable
        items={subCategoriesList}
        fields={[
          {
            key: "select",
            sorter: false,
            filter: false,

            label: writeAccess ? (
              <input
                type="checkbox"
                className="multiSelect"
                onChange={multiRowsSelect}
              />
            ) : (
              "#"
            ),
          },
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "sub_category_name",
          "category_name",
          "product_count",
          "createdAt",
        ]}
        hover
        itemsPerPage={limitPerPage}
        activePage={page}
        clickableRows
        pagination
        addTableClasses={"detailed-sub-cat"}
        loading={loading}
        onRowClick={(item) => {
          if (writeAccess) {
            history.push(`${ROUTES.SUB_CATEGORIES}${ROUTES.EDIT}`, item);
          }
        }}
        scopedSlots={{
          // sr_no: (_, index) => (
          //   <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          // ),
          select: (item, index) =>
            writeAccess ? (
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="multiSelect"
                  checked={selectedItems.includes(item._id)}
                  onChange={(e) => selectRow(e, item, index)}
                />
              </td>
            ) : (
              <td>{limitPerPage * (page - 1) + (index + 1)}</td>
            ),
          category_name: (item) => (
            <td>{_get(item, "category_id.category_name", "")}</td>
          ),
          product_count: (item) => <td>{_get(item, "product_count", 0)}</td>,
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD/MM/YYYY HH: MM A")}</td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          actions: (item, index) =>
            writeAccess ? (
              <td className="py-2">
                <CButton
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Unpubish" : " Publish"}
                </CButton>
                <CButton
                  className={"ml-2"}
                  color="danger"
                  variant="outline"
                  size="sm"
                  onClick={(e) => deleteSubCategory(e, item)}
                >
                  <CIcon name="cil-trash" /> Delete
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default SubCategories;
