import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { addressSchema } from "../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { orderStatusDropdown } from "src/utils/constants";
import {
  CForm,
  CButton,
  CSpinner,
  CCard,
  CCardHeader,
  CCardBody,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
  CDataTable,
  CRow,
  CCol,
  CBadge,
  CCardTitle,
} from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import { getAddress, priceString } from "src/utils/helper";
import _get from "lodash/get";
import Address from "src/components/Address";
import Moment from "moment";
import OrderSummary from "./orderDetails/orderSummary";
import CustomerCard from "./orderDetails/customerCard";

const AddEditOrder = ({ isEdit, history, location: { state: order } }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);
  const [orderStatus, setOrderStatus] = useState(orderStatusDropdown[1]);
  const [notes, setNotes] = useState("");
  const [giftWrap, setGiftWrap] = useState("");
  const [addressModal, setAddressModal] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [billingAddress, setBillingAddress] = useState();

  useEffect(() => {
    if (isEdit) {
      setShippingAddress(_get(order, "shipping_address"));
      setOrderStatus(
        orderStatusDropdown.find((v) => v.value === _get(order, "order_status"))
      );
      setNotes(_get(order, "order_notes", ""));
      setGiftWrap(_get(order, "gift_wrap_notes", ""));
    }
  }, [dispatch, isEdit, order]);

  const onAddressChange = (values) => {
    let data = {
      ...values,
      city: _get(values, "city.label", values.city),
      state_country: _get(values, "state_country.label", values.state_country),
    };
    if (addressModal === "shipping_address") {
      setShippingAddress(data);
    } else {
      setBillingAddress(data);
    }
    setAddressModal("");
  };

  const editOrder = () => {
    let data = {
      order_id: _get(order, "_id", ""),
      order_status: orderStatus.value,
      shipping_address: shippingAddress,
      order_notes: notes,
      gift_wrap_notes: giftWrap,
    };
    dispatch(addEditRequest("editOrder", data, history));
  };

  return (
    <>
      <PageContainer sm={12} title={"Edit Order"} loading={loading}>
        <CRow>
          <CCol>
            <h5 style={{ color: "blue" }}>#{_get(order, "order_id")}</h5>
            <p>
              {Moment(_get(order, "createdAt")).format(
                "DD/MM/YYYY  -  HH: MM A"
              )}
            </p>
            <FormInput
              type="select"
              xs={"2"}
              md={"4"}
              label={"Order Status"}
              options={orderStatusDropdown}
              value={orderStatus}
              name={"status"}
              onChange={(v) => setOrderStatus(v)}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard style={{ overflow: "scroll" }}>
              <CCardHeader>
                <CCardTitle>Products</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  items={_get(order, "product_details_id", [])}
                  fields={[
                    "item_name",
                    "quantity",
                    "total_price",
                    "tax",
                    "variant",
                    // "color",
                    {
                      key: "custom_front",
                      label: "Personalization (F)",
                    },
                    {
                      key: "custom_back",
                      label: "Personalization (B)",
                    },
                    "exchange_status",
                    "return_status",
                  ]}
                  hover
                  sorter
                  clickableRows
                  responsive
                  addTableClasses={"orders-details-products"}
                  scopedSlots={{
                    item_name: (item) => (
                      <td>{`${_get(item, "product_id.item_name", "-")} (${_get(
                        item,
                        "size_id.sku",
                        "-"
                      )})`}</td>
                    ),
                    total_price: (item) => (
                      <td>{priceString(_get(item, "total_price", 0))}</td>
                    ),

                    tax: (item) => (
                      <td>
                        {_get(item, "IGST.igst")
                          ? `IGST -  ${priceString(_get(item, "IGST.igst", 0))}
                                   (${_get(item, "IGST.igst_percentage", 0)}%)`
                          : ""}
                        {_get(item, "CGST.cgst")
                          ? `CGST - ${priceString(_get(item, "CGST.cgst", 0))}
                                   (${_get(item, "CGST.cgst_percentage", 0)}%)`
                          : ""}<br/>
                        {_get(item, "SGST.sgst")
                          ? `SGST - ${priceString(_get(item, "SGST.sgst", 0))}
                                   (${_get(item, "SGST.sgst_percentage", 0)}%)`
                          : ""}

                      </td>
                    ),
                    // color: (item) => (
                    //   <td>{_get(item, "size_id.color", "-")}</td>
                    // ),
                    variant: (item) => (
                      <td>{_get(item, "size_id.size", "-")}</td>
                    ),
                    custom_front: (item) => (
                      <td>{`${_get(item, "custom_on_front", "")} -
                          ${
                            _get(item, "custom_on_front_price", 0) > 0
                              ? priceString(
                                  _get(item, "custom_on_front_price", 0)
                                )
                              : ""
                          }`}</td>
                    ),
                    custom_back: (item) => (
                      <td>{`${_get(item, "custom_on_back", "")} -
                          ${
                            _get(item, "custom_on_back_price", 0) > 0
                              ? priceString(
                                  _get(item, "custom_on_back_price", 0)
                                )
                              : ""
                          }`}</td>
                    ),
                    exchange_status: (item) => (
                      <td>
                        {_get(item, "exchange_status") ? (
                          <CBadge color="warning">EXCHANGE</CBadge>
                        ) : (
                          "-"
                        )}
                      </td>
                    ),
                    return_status: (item) => (
                      <td>
                        {_get(item, "return_status") ? (
                          <CBadge color="danger">RETURN</CBadge>
                        ) : (
                          "-"
                        )}
                      </td>
                    ),
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={8}>
            <OrderSummary order={order} />
            <CCard>
              <CCardHeader>
                <CCardTitle className="cardtitle">Metadata</CCardTitle>
              </CCardHeader>
              <CCardBody>
                Gift Message:
                <FormInput
                  type="textarea"
                  value={giftWrap}
                  name={"gift_wrap_notes"}
                  maxLength={150}
                  onChange={(e) => setGiftWrap(e.target.value)}
                />
                Order Notes:
                <FormInput
                  type="textarea"
                  value={notes}
                  name={"notes"}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={4}>
            <CustomerCard order={order} />
            <CCard>
              <CCardHeader>
                <CCardTitle className="cardtitle">Shipping Address:</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <span className="txt" style={{ whiteSpace: "pre-line" }}>
                  {getAddress(shippingAddress)}
                </span>
                <CButton
                  color="primary"
                  size="sm"
                  variant="outline"
                  className="mt-3"
                  onClick={() => setAddressModal("shipping_address")}
                >
                  Edit
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <CButton color="primary" disabled={loading} onClick={editOrder}>
          {loading && (
            <CSpinner
              component="span"
              className="mr-2"
              size="sm"
              aria-hidden="true"
            />
          )}
          Edit Order
        </CButton>
      </PageContainer>

      <CModal
        show={addressModal ? true : false}
        onClose={() => setAddressModal("")}
      >
        <CModalHeader closeButton>
          <CModalTitle>Update Address</CModalTitle>
        </CModalHeader>
        <Formik
          initialValues={
            addressModal === "shipping_address"
              ? shippingAddress
              : billingAddress
          }
          enableReinitialize
          validationSchema={addressSchema}
          onSubmit={onAddressChange}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit}>
              <CModalBody>
                <Address
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="secondary"
                  onClick={() => setAddressModal(false)}
                >
                  cancel
                </CButton>
                <CButton type="submit" color="primary">
                  Save
                </CButton>
              </CModalFooter>
            </CForm>
          )}
        </Formik>
      </CModal>
    </>
  );
};

export default AddEditOrder;
