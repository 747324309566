import React from "react";
import { Route, Redirect } from "react-router-dom";
import { TheSidebar, TheHeader, TheFooter } from "src/containers";
import { CContainer } from "@coreui/react";
import { useSelector } from "react-redux";
import { ROUTES } from "./routes";
import { hasPermission } from "src/utils/helper";
import { MODULES, PERMISSIONS } from "src/utils/constants";

import {
  DashboardPage,
  UsersPage,
  MailFormatsPage,
  MailLogsPage,
  DefaultSettingsPage,
  ContentSettingsPage,
  AddEditUsersPage,
  TaxSlabsPage,
  AddEditTaxSlabsPage,
  SubAdminsPage,
  AddEditSubAdminsPage,
  WalletLogsPage,
  CouponsPage,
  AddEditCouponsPage,
  PromotionsPage,
  AddEditPromotionsPage,
  OrdersPage,
  AddOrderPage,
  EditOrderPage,
  ExchangeReturnsPage,
  AddEditExchangeReturnsPage,
  CategoriesPage,
  AddEditCategoriesPage,
  SubCategoriesPage,
  AddEditSubCategoriesPage,
  DetailedSubCategoriesPage,
  AddEditDetailedSubCategoriesPage,
  ProductsPage,
  AddEditProductsPage,
  VariantsPage,
  AddEditVariantsPage,
  TagsPage,
  AddEditTagsPage,
  PersonalisationPage,
  AddEditPersonalisationPage,
  SizeGuidesPage,
  AddEditSizeGuidesPage,
  AccountPage,
} from "../pages";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user.data);

  if (!user) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <main className="c-main">
            <CContainer fluid>
              <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
              {hasPermission(MODULES.DASHBOARD, PERMISSIONS.READ) && (
                <Route
                  exact
                  path={ROUTES.DASHBOARD}
                  component={DashboardPage}
                />
              )}

              {hasPermission(MODULES.PRODUCTS, PERMISSIONS.READ) && (
                <>
                  <Route
                    exact
                    path={ROUTES.PRODUCTS}
                    component={ProductsPage}
                  />
                  <Route
                    exact
                    path={ROUTES.VARIANTIONS}
                    component={VariantsPage}
                  />
                  <Route exact path={ROUTES.TAGS} component={TagsPage} />
                  <Route
                    exact
                    path={ROUTES.PERSONALISATION}
                    component={PersonalisationPage}
                  />
                  <Route
                    exact
                    path={ROUTES.SIZE_GUIDES}
                    component={SizeGuidesPage}
                  />
                </>
              )}
              {hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.PRODUCTS}${ROUTES.ADD}`}
                    component={AddEditProductsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.PRODUCTS}${ROUTES.EDIT}${ROUTES.ID}`}
                    render={(props) => (
                      <AddEditProductsPage {...props} isEdit />
                    )}
                  />
                  <Route
                    exact
                    path={`${ROUTES.VARIANTIONS}${ROUTES.ADD}`}
                    component={AddEditVariantsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.VARIANTIONS}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditVariantsPage {...props} isEdit />
                    )}
                  />
                  <Route
                    exact
                    path={`${ROUTES.TAGS}${ROUTES.ADD}`}
                    component={AddEditTagsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.TAGS}${ROUTES.EDIT}`}
                    render={(props) => <AddEditTagsPage {...props} isEdit />}
                  />
                  <Route
                    exact
                    path={`${ROUTES.PERSONALISATION}${ROUTES.ADD}`}
                    component={AddEditPersonalisationPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.PERSONALISATION}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditPersonalisationPage {...props} isEdit />
                    )}
                  />
                  <Route
                    exact
                    path={`${ROUTES.SIZE_GUIDES}${ROUTES.ADD}`}
                    component={AddEditSizeGuidesPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.SIZE_GUIDES}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditSizeGuidesPage {...props} isEdit />
                    )}
                  />
                </>
              )}

              {hasPermission(MODULES.CATEGORIES, PERMISSIONS.READ) && (
                <>
                  <Route
                    exact
                    path={ROUTES.CATEGORIES}
                    component={CategoriesPage}
                  />
                  <Route
                    exact
                    path={ROUTES.SUB_CATEGORIES}
                    component={SubCategoriesPage}
                  />
                  <Route
                    exact
                    path={ROUTES.DETAILED_SUB_CATEGORIES}
                    component={DetailedSubCategoriesPage}
                  />
                </>
              )}

              {hasPermission(MODULES.CATEGORIES, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.CATEGORIES}${ROUTES.ADD}`}
                    component={AddEditCategoriesPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.CATEGORIES}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditCategoriesPage {...props} isEdit />
                    )}
                  />
                  <Route
                    exact
                    path={`${ROUTES.SUB_CATEGORIES}${ROUTES.ADD}`}
                    component={AddEditSubCategoriesPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.SUB_CATEGORIES}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditSubCategoriesPage {...props} isEdit />
                    )}
                  />
                  <Route
                    exact
                    path={`${ROUTES.DETAILED_SUB_CATEGORIES}${ROUTES.ADD}`}
                    component={AddEditDetailedSubCategoriesPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.DETAILED_SUB_CATEGORIES}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditDetailedSubCategoriesPage {...props} isEdit />
                    )}
                  />
                </>
              )}

              {hasPermission(MODULES.ORDERS, PERMISSIONS.READ) && (
                <>
                  <Route exact path={ROUTES.ORDERS} component={OrdersPage} />
                  <Route
                    exact
                    path={ROUTES.EXCHANGES}
                    render={(props) => (
                      <ExchangeReturnsPage {...props} isExchange />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.RETURNS}
                    component={ExchangeReturnsPage}
                  />
                </>
              )}

              {hasPermission(MODULES.ORDERS, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.ORDERS}${ROUTES.ADD}`}
                    component={AddOrderPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.ORDERS}${ROUTES.EDIT}`}
                    render={(props) => <EditOrderPage {...props} isEdit />}
                  />
                  <Route
                    exact
                    path={`${ROUTES.EXCHANGES}${ROUTES.ADD}`}
                    component={AddEditExchangeReturnsPage}
                  />
                </>
              )}

              {hasPermission(MODULES.MARKETING, PERMISSIONS.READ) && (
                <>
                  <Route exact path={ROUTES.COUPONS} component={CouponsPage} />
                  <Route
                    exact
                    path={ROUTES.PROMOTIONS}
                    component={PromotionsPage}
                  />
                </>
              )}

              {hasPermission(MODULES.MARKETING, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.COUPONS}${ROUTES.ADD}`}
                    component={AddEditCouponsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.COUPONS}${ROUTES.EDIT}`}
                    render={(props) => <AddEditCouponsPage {...props} isEdit />}
                  />
                  <Route
                    exact
                    path={`${ROUTES.PROMOTIONS}${ROUTES.ADD}`}
                    component={AddEditPromotionsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.PROMOTIONS}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditPromotionsPage {...props} isEdit />
                    )}
                  />
                </>
              )}

              {hasPermission(MODULES.USERS, PERMISSIONS.READ) && (
                <Route exact path={ROUTES.USERS} component={UsersPage} />
              )}

              {hasPermission(MODULES.USERS, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.USERS}${ROUTES.ADD}`}
                    component={AddEditUsersPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.USERS}${ROUTES.EDIT}${ROUTES.ID}`}
                    render={(props) => <AddEditUsersPage {...props} isEdit />}
                  />
                </>
              )}

              {hasPermission(MODULES.WALLET_LOGS, PERMISSIONS.READ) && (
                <Route
                  exact
                  path={ROUTES.WALLET_LOGS}
                  component={WalletLogsPage}
                />
              )}

              {hasPermission(MODULES.SUB_ADMINS, PERMISSIONS.READ) && (
                <Route
                  exact
                  path={ROUTES.SUB_ADMINS}
                  component={SubAdminsPage}
                />
              )}
              {hasPermission(MODULES.SUB_ADMINS, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.SUB_ADMINS}${ROUTES.ADD}`}
                    component={AddEditSubAdminsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.SUB_ADMINS}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditSubAdminsPage {...props} isEdit />
                    )}
                  />
                </>
              )}

              {hasPermission(MODULES.EMAILS, PERMISSIONS.READ) && (
                <>
                  <Route
                    exact
                    path={ROUTES.MAIL_LOGS}
                    component={MailLogsPage}
                  />
                  <Route
                    exact
                    path={ROUTES.MAIL_FORMATS}
                    component={MailFormatsPage}
                  />
                </>
              )}

              {hasPermission(MODULES.SETTINGS, PERMISSIONS.READ) && (
                <>
                  <Route
                    exact
                    path={ROUTES.DEFAULTS}
                    component={DefaultSettingsPage}
                  />
                  <Route
                    exact
                    path={ROUTES.CONTENT}
                    component={ContentSettingsPage}
                  />
                  <Route
                    exact
                    path={ROUTES.TAX_SLABS}
                    component={TaxSlabsPage}
                  />
                </>
              )}

              {hasPermission(MODULES.SETTINGS, PERMISSIONS.WRITE) && (
                <>
                  <Route
                    exact
                    path={`${ROUTES.TAX_SLABS}${ROUTES.ADD}`}
                    component={AddEditTaxSlabsPage}
                  />
                  <Route
                    exact
                    path={`${ROUTES.TAX_SLABS}${ROUTES.EDIT}`}
                    render={(props) => (
                      <AddEditTaxSlabsPage {...props} isEdit />
                    )}
                  />
                </>
              )}
            </CContainer>
          </main>
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default PrivateRoute;
