import React, { useEffect, useState } from "react";
import { userSchema } from "../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "src/components/FormInput";
import {
  CForm,
  CButton,
  CSpinner,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardHeader,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CModal,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import { State, City } from "country-state-city";
import _cloneDeep from "lodash/cloneDeep";
import cloneDeep from "lodash/cloneDeep";
import ImageUploader from "src/components/ImageUploader";
import { uploadImage, priceString } from "src/utils/helper";
import _get from "lodash/get";
import CIcon from "@coreui/icons-react";
import { AES } from "crypto-js";
import { secretKey, endpoints } from "src/config/api";
import { useParams } from "react-router-dom";
import { GET } from "src/config/services";
import { toast } from "react-toastify";

const type = "users";
const defaultFormValues = {
  first_name: "",
  last_name: "",
  email_address: "",
  mobile: "",
  country_code: "+91",
  country: "India",
  profile_image: [],
  both_address_same: false,
  billing_address: {
    first_name: "",
    last_name: "",
    company: "",
    address_line_one: "",
    address_line_second: "",
    city: "",
    postcode_zip: "",
    country_region: "India",
    state_country: {},
    country_code: "+91",
    phone_number: "",
    email_address: "",
  },
  shipping_address: {
    first_name: "",
    last_name: "",
    company: "",
    address_line_one: "",
    address_line_second: "",
    city: "",
    postcode_zip: "",
    country_region: "India",
    state_country: {},
    country_code: "+91",
    phone_number: "",
    email_address: "",
  },
};

const AddEditUser = ({ isEdit, history }) => {
  const { id } = useParams();
  if (isEdit && !id) {
    history.push("/users");
  }

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [statesList, setStateList] = useState([]);
  const [amountToAdd, setAmountToAdd] = useState(0);
  const [walletModal, setWalletModal] = useState(false);
  const [user, setUser] = useState(defaultFormValues);
  const message = useSelector((s) => s.admin.modal.message);
  let citiesList = [];

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      GET(endpoints.listUsers, { user_id: id })
        .then((res) => {
          const userTemp = cloneDeep(res.data.data);
          userTemp.shipping_address.state_country = {
            label: userTemp.shipping_address.state_country,
            value: userTemp.shipping_address.state_country,
          };
          userTemp.shipping_address.city = {
            label: userTemp.shipping_address.city,
            value: userTemp.shipping_address.city,
          };
          userTemp.billing_address.state_country = {
            label: userTemp.billing_address.state_country,
            value: userTemp.billing_address.state_country,
          };
          userTemp.billing_address.city = {
            label: userTemp.billing_address.city,
            value: userTemp.billing_address.city,
          };
          if (userTemp.profile_image.file_id === "") {
            userTemp.profile_image = [];
          } else userTemp.profile_image = [userTemp.profile_image];
          setUser(userTemp);
        })
        .catch((err) => {
          toast.error("Error while fetching data");
        });
      setLoading(false);
    }
  }, [id, message, isEdit]);

  useEffect(() => {
    let states = State.getStatesOfCountry("IN");
    let list = [];
    for (let i = 0; i < states.length; i++) {
      list.push({
        label: states[i].name,
        value: states[i].isoCode,
      });
    }
    setStateList(list);
  }, []);

  const fetchCities = (code) => {
    let cities = City.getCitiesOfState("IN", code);
    let list = [];
    for (let i = 0; i < cities.length; i++) {
      list.push({
        label: cities[i].name,
        value: cities[i].isoCode,
      });
    }
    citiesList = list;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    let data = cloneDeep(values);
    let img;
    if (data.profile_image.length === 0) {
      delete data.profile_image;
    } else if (!data.profile_image[0].file_id) {
      let imgData = new FormData();
      imgData.append("files", data.profile_image[0].file);
      img = await uploadImage(imgData);
      img = img[0];
    } else img = data.profile_image[0];

    data.profile_image = img;
    data.billing_address.state_country =
      data.billing_address.state_country.label;
    data.shipping_address.state_country =
      data.shipping_address.state_country.label;
    data.shipping_address.city = data.shipping_address.city.label;
    data.billing_address.city = data.billing_address.city.label;

    if (isEdit) {
      data.id = data._id;
      delete data._id;
      delete data.status;
      delete data.createdAt;
      delete data.wallet_amount;
    }
    dispatch(addEditRequest(type, data, history));
    setLoading(false);
  };

  const addAmountTowallet = (e) => {
    e.preventDefault();
    let data = {
      user_id: _get(user, "_id", ""),
      wallet_amount: e.target[0].value,
    };
    dispatch(
      addEditRequest("addMoneyToWallet", {
        data: AES.encrypt(JSON.stringify(data), secretKey).toString(),
      })
    );
    setAmountToAdd(0);
    setWalletModal(false);
  };

  return (
    <>
      <Formik
        initialValues={isEdit ? user : defaultFormValues}
        validationSchema={userSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <CRow>
              <CCol lg={6}>
                <CCard>
                  <CCardHeader
                    className={
                      "d-flex justify-content-between align-items-center"
                    }
                  >
                    User Details
                    <CButton
                      type="submit"
                      color="primary"
                      disabled={!dirty || loading}
                    >
                      {loading && (
                        <CSpinner
                          component="span"
                          className="mr-2"
                          size="sm"
                          aria-hidden="true"
                        />
                      )}
                      Submit
                    </CButton>
                  </CCardHeader>
                  <CCardBody>
                    <FormInput
                      type="text"
                      label={"First Name"}
                      name={"first_name"}
                      value={values["first_name"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={errors["first_name"] && touched["first_name"]}
                      error={errors["first_name"]}
                      placeholder={"Enter first name"}
                    />
                    <FormInput
                      type="text"
                      label={"Last Name"}
                      name={"last_name"}
                      value={values["last_name"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={errors["last_name"] && touched["last_name"]}
                      error={errors["last_name"]}
                      placeholder={"Enter first name"}
                    />
                    <FormInput
                      type="text"
                      label={"Email"}
                      name={"email_address"}
                      value={values["email_address"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        errors["email_address"] && touched["email_address"]
                      }
                      error={errors["email_address"]}
                      placeholder={"Enter email address"}
                    />
                    <FormInput
                      type="text"
                      label={"Mobile Number"}
                      name={"mobile"}
                      value={values["mobile"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={errors["mobile"] && touched["mobile"]}
                      error={errors["mobile"]}
                      placeholder={"Enter Mobile"}
                    />
                    <ImageUploader
                      multiple={false}
                      value={values["profile_image"]}
                      onChange={(files) =>
                        setFieldValue("profile_image", files)
                      }
                      dataURLKey="image_url"
                      placeholder={"Choose Profile Image or Drop Here"}
                      invalid={
                        errors["profile_image"] && touched["profile_image"]
                      }
                      error={errors["profile_image"]}
                    />
                  </CCardBody>
                </CCard>
                <CCard>
                  <CCardHeader>Shipping Address</CCardHeader>
                  <CCardBody>
                    <FormInput
                      type="text"
                      label={"First Name"}
                      name={"shipping_address.first_name"}
                      value={values["shipping_address"].first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter first name"}
                      invalid={
                        (errors.shipping_address || {}).first_name &&
                        (touched.shipping_address || {}).first_name
                      }
                      error={(errors.shipping_address || {}).first_name}
                    />
                    <FormInput
                      type="text"
                      label={"Last Name"}
                      name={"shipping_address.last_name"}
                      value={values["shipping_address"].last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter last name"}
                      invalid={
                        (errors.shipping_address || {}).last_name &&
                        (touched.shipping_address || {}).last_name
                      }
                      error={(errors.shipping_address || {}).last_name}
                    />
                    <FormInput
                      type="text"
                      label={"Email"}
                      name={"shipping_address.email_address"}
                      value={values["shipping_address"].email_address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter email address"}
                      invalid={
                        (errors.shipping_address || {}).email_address &&
                        (touched.shipping_address || {}).email_address
                      }
                      error={(errors.shipping_address || {}).email_address}
                    />
                    <FormInput
                      type="text"
                      label={"Phone Number"}
                      name={"shipping_address.phone_number"}
                      value={values["shipping_address"].phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Phone Number"}
                      invalid={
                        (errors.shipping_address || {}).phone_number &&
                        (touched.shipping_address || {}).phone_number
                      }
                      error={(errors.shipping_address || {}).phone_number}
                    />
                    <FormInput
                      type="text"
                      label={"Company"}
                      name={"shipping_address.company"}
                      value={values["shipping_address"].company}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Company"}
                      invalid={
                        (errors.shipping_address || {}).company &&
                        (touched.shipping_address || {}).company
                      }
                      error={(errors.shipping_address || {}).company}
                    />
                    <FormInput
                      type="text"
                      label={"Address Line 1"}
                      name={"shipping_address.address_line_one"}
                      value={values["shipping_address"].address_line_one}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Address"}
                      invalid={
                        (errors.shipping_address || {}).address_line_one &&
                        (touched.shipping_address || {}).address_line_one
                      }
                      error={(errors.shipping_address || {}).address_line_one}
                    />
                    <FormInput
                      type="text"
                      label={"Address Line 1"}
                      name={"shipping_address.address_line_second"}
                      value={values["shipping_address"].address_line_second}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Address"}
                      invalid={
                        (errors.shipping_address || {}).address_line_second &&
                        (touched.shipping_address || {}).address_line_second
                      }
                      error={
                        (errors.shipping_address || {}).address_line_second
                      }
                    />

                    <FormInput
                      type="select"
                      label={"State"}
                      name={"shipping_address.state_country"}
                      value={values["shipping_address"].state_country}
                      options={statesList}
                      onChange={(v) => {
                        setFieldValue("shipping_address.state_country", v);
                        fetchCities(v.value);
                      }}
                      onBlur={handleBlur}
                      placeholder={"Select State"}
                      invalid={
                        (errors.shipping_address || {}).state_country &&
                        (touched.shipping_address || {}).state_country
                      }
                      error={(errors.shipping_address || {}).state_country}
                    />
                    <FormInput
                      type="select"
                      label={"City"}
                      name={"shipping_address.city"}
                      value={values["shipping_address"].city}
                      options={citiesList}
                      onChange={(v) =>
                        setFieldValue("shipping_address.city", v)
                      }
                      onBlur={handleBlur}
                      placeholder={"Select City"}
                      invalid={
                        (errors.shipping_address || {}).city &&
                        (touched.shipping_address || {}).city
                      }
                      error={(errors.shipping_address || {}).city}
                    />

                    <FormInput
                      type="text"
                      label={"Postal code"}
                      name={"shipping_address.postcode_zip"}
                      value={values["shipping_address"].postcode_zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Postal code"}
                      invalid={
                        (errors.shipping_address || {}).postcode_zip &&
                        (touched.shipping_address || {}).postcode_zip
                      }
                      error={(errors.shipping_address || {}).postcode_zip}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol lg={6}>
                <CCard>
                  <CCardHeader>Actions</CCardHeader>
                  <CCardBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        Wallet Amount:{" "}
                        <h5>{priceString(_get(values, "wallet_amount", 0))}</h5>
                      </span>
                      {isEdit && (
                        <CButton
                          color="primary"
                          variant="outline"
                          size="sm"
                          className="ml-2"
                          onClick={(e) => setWalletModal(true)}
                        >
                          <CIcon name="cil-cash" /> Add Funds
                        </CButton>
                      )}
                    </div>
                  </CCardBody>
                </CCard>
                <CCard>
                  <CCardHeader>
                    Billing Address
                    <div className="float-right">
                      <CFormGroup variant="custom-checkbox" inline>
                        <CInputCheckbox
                          custom
                          id="both_address_same"
                          name="both_address_same"
                          value={values["both_address_same"]}
                          onChange={(e) => {
                            setFieldValue(
                              "both_address_same",
                              e.target.checked
                            );
                            if (e.target.checked) {
                              setFieldValue(
                                "billing_address",
                                _cloneDeep(values["shipping_address"])
                              );
                            }
                          }}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="both_address_same"
                        >
                          Same as Shipping Address
                        </CLabel>
                      </CFormGroup>
                    </div>
                  </CCardHeader>
                  <CCardBody>
                    <FormInput
                      type="text"
                      label={"First Name"}
                      name={"billing_address.first_name"}
                      value={values["billing_address"].first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter first name"}
                      invalid={
                        (errors.billing_address || {}).first_name &&
                        (touched.billing_address || {}).first_name
                      }
                      error={(errors.billing_address || {}).first_name}
                    />
                    <FormInput
                      type="text"
                      label={"Last Name"}
                      name={"billing_address.last_name"}
                      value={values["billing_address"].last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter last name"}
                      invalid={
                        (errors.billing_address || {}).last_name &&
                        (touched.billing_address || {}).last_name
                      }
                      error={(errors.billing_address || {}).last_name}
                    />
                    <FormInput
                      type="text"
                      label={"Email"}
                      name={"billing_address.email_address"}
                      value={values["billing_address"].email_address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter email address"}
                      invalid={
                        (errors.billing_address || {}).email_address &&
                        (touched.billing_address || {}).email_address
                      }
                      error={(errors.billing_address || {}).email_address}
                    />
                    <FormInput
                      type="text"
                      label={"Phone Number"}
                      name={"billing_address.phone_number"}
                      value={values["billing_address"].phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Phone Number"}
                      invalid={
                        (errors.billing_address || {}).phone_number &&
                        (touched.billing_address || {}).phone_number
                      }
                      error={(errors.billing_address || {}).phone_number}
                    />
                    <FormInput
                      type="text"
                      label={"Company"}
                      name={"billing_address.company"}
                      value={values["billing_address"].company}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Company"}
                      invalid={
                        (errors.billing_address || {}).company &&
                        (touched.billing_address || {}).company
                      }
                      error={(errors.billing_address || {}).company}
                    />
                    <FormInput
                      type="text"
                      label={"Address Line 1"}
                      name={"billing_address.address_line_one"}
                      value={values["billing_address"].address_line_one}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Address"}
                      invalid={
                        (errors.billing_address || {}).address_line_one &&
                        (touched.billing_address || {}).address_line_one
                      }
                      error={(errors.billing_address || {}).address_line_one}
                    />
                    <FormInput
                      type="text"
                      label={"Address Line 1"}
                      name={"billing_address.address_line_second"}
                      value={values["billing_address"].address_line_second}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Address"}
                      invalid={
                        (errors.billing_address || {}).address_line_second &&
                        (touched.billing_address || {}).address_line_second
                      }
                      error={(errors.billing_address || {}).address_line_second}
                    />

                    <FormInput
                      type="select"
                      label={"State"}
                      name={"billing_address.state_country"}
                      value={values["billing_address"].state_country}
                      options={statesList}
                      onChange={(v) => {
                        setFieldValue("billing_address.state_country", v);
                        fetchCities(v.value);
                      }}
                      onBlur={handleBlur}
                      placeholder={"Select State"}
                      invalid={
                        (errors.billing_address || {}).state_country &&
                        (touched.billing_address || {}).state_country
                      }
                      error={(errors.billing_address || {}).state_country}
                    />
                    <FormInput
                      type="select"
                      label={"City"}
                      name={"billing_address.city"}
                      value={values["billing_address"].city}
                      options={citiesList}
                      onChange={(v) => setFieldValue("billing_address.city", v)}
                      onBlur={handleBlur}
                      placeholder={"Select City"}
                      invalid={
                        (errors.billing_address || {}).city &&
                        (touched.billing_address || {}).city
                      }
                      error={(errors.billing_address || {}).city}
                    />

                    <FormInput
                      type="text"
                      label={"Postal code"}
                      name={"billing_address.postcode_zip"}
                      value={values["billing_address"].postcode_zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Postal code"}
                      invalid={
                        (errors.billing_address || {}).postcode_zip &&
                        (touched.billing_address || {}).postcode_zip
                      }
                      error={(errors.billing_address || {}).postcode_zip}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CForm>
        )}
      </Formik>
      <CModal
        show={walletModal}
        onClose={() => {
          setWalletModal(false);
          setAmountToAdd(0);
        }}
      >
        <CModalHeader closeButton>
          <CModalTitle>{`Add funds to user's wallet`}</CModalTitle>
        </CModalHeader>
        <CCardBody>
          <CForm onSubmit={addAmountTowallet}>
            <CRow>
              <CCol xs={8}>
                <FormInput
                  type="number"
                  label="Amount"
                  required={true}
                  value={amountToAdd}
                  onChange={(e) => setAmountToAdd(e.target.value)}
                />
              </CCol>
              <CCol>
                <CButton color="primary" type="submit">
                  Add money
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CModal>
    </>
  );
};

export default AddEditUser;
