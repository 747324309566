import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CRow, CCol, CInput } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { getBadge, confirmModal, hasPermission } from "src/utils/helper";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import { ROUTES } from "src/router/routes";

const type = "subAdmins";

const SubAdmins = ({ history }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.subAdmins.loading);
  const subAdminsList = useSelector((state) => state.admin.subAdmins.data.data);
  const total = useSelector((state) => state.admin.subAdmins.data.count);
  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.SUB_ADMINS, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
      })
    );
  }, [dispatch, message, page, limitPerPage, searchFilter]);

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(item.status, (status) =>
      dispatch(blockUnblockRequest(type, { id: item._id, status }))
    );
  };

  return (
    <PageContainer
      title="Sub Admins"
      rightButtons={
        writeAccess
          ? [
              {
                label: "Add Sub Admin",
                onClick: () =>
                  history.push(`${ROUTES.SUB_ADMINS}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={2}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setSearch("");
              setSearchFilter("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={subAdminsList}
        fields={[
          { key: "sr_no" },
          {
            key: "Edit",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "first_name",
          "last_name",
          "email",
          "super_admin",
          "createdAt",
        ]}
        hover
        sorter
        clickableRows
        responsive
        loading={loading}
        addTableClasses={"default"}
        onRowClick={(item) => {
          if (writeAccess) {
            history.push(`${ROUTES.SUB_ADMINS}${ROUTES.EDIT}`, item);
          }
        }}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          super_admin: (item) => (
            <td>
              <CBadge color={getBadge(item.super_admin ? 1 : 0)}>
                {item.super_admin ? "True" : "False"}
              </CBadge>
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
          ),
          Edit: (item, index) =>
            writeAccess ? (
              <td className="py-2">
                <CButton color="primary" variant="outline" size="sm">
                  <CIcon name="cil-pencil" /> Edit
                </CButton>
                <CButton
                  className={"ml-2"}
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Block" : " Unblock"}
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default SubAdmins;
