import React from "react";

import {
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CCardTitle,
} from "@coreui/react";
import { priceString } from "src/utils/helper";
import _get from "lodash/get";

const OrderSummary = ({ order }) => {
  let taxValue =
    _get(order, "cart_total.CGST.cgst", 0) > 0
      ? _get(order, "cart_total.CGST.cgst", 0) +
        _get(order, "cart_total.SGST.sgst", 0)
      : _get(order, "cart_total.IGST.igst", 0);

  let percentageArray = [];
  for(let key of order.product_details_id){
    if(_get(order, "cart_total.CGST.cgst", 0) > 0){
      let index = percentageArray.findIndex( o=> o.name === 'SGST' && o.key === key['SGST'].sgst_percentage);
      if(index < 0 ){
        percentageArray.push({
          name : 'SGST',
          key :key['SGST'].sgst_percentage,
          total : key['SGST'].sgst
        })
      }
      else {
        percentageArray[index].total = percentageArray[index].total +  key['SGST'].sgst
      }

      let index1 = percentageArray.findIndex( o=>  o.name === 'CGST' && o.key === key['CGST'].cgst_percentage);
      if(index1 < 0 ){
        percentageArray.push({
          name : 'CGST',
          key :key['CGST'].cgst_percentage,
          total : key['CGST'].cgst
        })
      }
      else {
        percentageArray[index1].total = percentageArray[index1].total +  key['CGST'].cgst
      }
    }
  }

  return (
    <CCard>
      <CCardHeader>
        <CCardTitle className="cardtitle">Order Summary:</CCardTitle>
      </CCardHeader>

      <CCardBody>
        {!!_get(order, "cart_total.sub_total") && (
          <CRow className="mb-2">
            <CCol xs={9}>Sub Total : </CCol>
            <CCol>
              <span className={"walletAmount"}>
                {priceString(
                  (_get(order, "cart_total.sub_total", 0) - taxValue)?.toFixed(
                    2
                  )
                )}
              </span>
            </CCol>
          </CRow>
        )}
        {!!_get(order, "cart_total.free_shipping") && (
          <CRow className="mb-2">
            <CCol xs={9}>Shipping Charges: </CCol>
            <CCol>
              <span className={"walletAmount"}>
                {priceString(_get(order, "cart_total.free_shipping", 0))}
              </span>
            </CCol>
          </CRow>
        )}
        {!!_get(order, "cart_total.cash_free") && (
          <CRow className="mb-2">
            <CCol xs={9}>COD Fee : </CCol>
            <CCol>
              <span className={"walletAmount"}>
                {priceString(_get(order, "cart_total.cash_free", 0))}
              </span>
            </CCol>
          </CRow>
        )}
        {!!_get(order, "cart_total.gift_wrap") && (
          <CRow className="mb-2">
            <CCol xs={9}>Gift Wrap Fee : </CCol>
            <CCol>
              <span className={"walletAmount"}>
                {priceString(_get(order, "cart_total.gift_wrap", 0))}
              </span>
            </CCol>
          </CRow>
        )}
        {!!_get(order, "cart_total.IGST.igst") && (
          <CRow className="mb-2">
            <CCol xs={9}>
              IGST ({_get(order, "cart_total.IGST.igst_percentage", 0)}
              %)
            </CCol>
            <CCol>
              <span className={"walletAmount"}>
                {priceString(_get(order, "cart_total.IGST.igst", 0)?.toFixed(2))}
              </span>
            </CCol>
          </CRow>
        )}

        {!!percentageArray?.length > 0 &&

          percentageArray.map((percent,index) => (
            <CRow className="mb-2" key={index}>
              <CCol xs={9}>
                {`${percent.name} (${percent.key}%):`}
              </CCol>
              <CCol>
              <span className={"walletAmount"}>
                {priceString((percent?.total)?.toFixed(2))}
              </span>
              </CCol>
            </CRow>
          ))
        }

        {/*{!!_get(order, "cart_total.CGST.cgst") && (*/}
          {/*<CRow className="mb-2">*/}
            {/*<CCol xs={9}>*/}
              {/*CGST ({_get(order, "cart_total.CGST.cgst_percentage", 0)}*/}
              {/*%)*/}
            {/*</CCol>*/}
            {/*<CCol>*/}
              {/*<span className={"walletAmount"}>*/}
                {/*{priceString(_get(order, "cart_total.CGST.cgst", 0)?.toFixed(2))}*/}
              {/*</span>*/}
            {/*</CCol>*/}
          {/*</CRow>*/}
        {/*)}*/}

        {/*{!!_get(order, "cart_total.SGST.sgst") && (*/}
          {/*<CRow className="mb-2">*/}
            {/*<CCol xs={9}>*/}
              {/*SGST ({_get(order, "cart_total.SGST.sgst_percentage", 0)}*/}
              {/*%)*/}
            {/*</CCol>*/}
            {/*<CCol>*/}
              {/*<span className={"walletAmount"}>*/}
                {/*{priceString(_get(order, "cart_total.SGST.sgst", 0)?.toFixed(2))}*/}
              {/*</span>*/}
            {/*</CCol>*/}
          {/*</CRow>*/}
        {/*)}*/}

        {!!_get(order, "cart_total.discount") && (
          <CRow className="mb-2">
            <CCol xs={9}>
              Discount ({priceString(_get(order, "coupan_id.coupan_code", 0))}
              ):
            </CCol>
            <CCol>
              <span className={"walletAmount"}>
                -{priceString(_get(order, "cart_total.discount", 0))}
              </span>
            </CCol>
          </CRow>
        )}

        {!!_get(order, "wallet_amount") && (
          <CRow className="mb-2">
            <CCol xs={9}>Wallet Amount Used: </CCol>
            <CCol>
              <span className={"walletAmount"}>
                {priceString(_get(order, "wallet_amount", 0))}
              </span>
            </CCol>
          </CRow>
        )}
        <hr />
        <CRow className="mb-2">
          <CCol xs={9}>Total: </CCol>
          <CCol>
            <span className={"walletAmount"}>
              {priceString(_get(order, "cart_total.total", 0))}
            </span>
          </CCol>
        </CRow>
        <hr />

        <CRow className="mb-2">
          <CCol xs={9}>
            <b>Amount Paid by user:</b>
          </CCol>
          <CCol>
            <span className={"walletAmount"}>
              {priceString(_get(order, "cart_total.paid_by_user", 0))}
            </span>
          </CCol>
        </CRow>
        <hr />
      </CCardBody>
    </CCard>
  );
};

export default OrderSummary;
