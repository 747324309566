import { AxiosInstance } from "./client";
import { UserApis } from "./endpoints";

export const getUsers = (params) => {
  return AxiosInstance()
    .get(UserApis.LIST_USERS, { params })
    .then((response) => response?.data?.data);
};

// export const addEditProject = (payload) => {
//   return AxiosInstance()
//     .post(ProjectAPIs.ADD_EDIT_PROJECT, payload)
//     .then((response) => response.data);
// };
