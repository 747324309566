import React, { useState, useEffect } from "react";
import {
  CButton,
  CBadge,
  CDataTable,
  CCollapse,
  CRow,
  CCol,
  CInput,
  CWidgetDropdown,
  CTooltip,
  CSpinner,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import {
  getOrderStatus,
  getOrderBadge,
  handleFileExport,
  getPaymentMethod,
  priceString,
  confirmModal,
  hasPermission,
  // downloadHtmlToPDF,
} from "src/utils/helper";
import {
  defaultLimitPerPage,
  orderStatusDropdown,
  MODULES,
  PERMISSIONS,
} from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import _get from "lodash/get";
import { endpoints, baseUrl } from "src/config/api";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye-off.svg";
import CIcon from "@coreui/icons-react";

import OrderDetails from "./orderDetails";
import { confirmAlert } from "react-confirm-alert";
import { POST } from "src/config/services";
import { toast } from "react-toastify";
import { ROUTES } from "src/router/routes";
import { searchUsers } from "src/utils";

const type = "orders";
const userFilterDefault = {
  label: "Search User",
  value: "",
};
const statusFilterDefault = {
  label: "Select Status",
  value: "",
};
const Orders = ({ history }) => {
  const dispatch = useDispatch();
  const {
    data: { count, data: ordersList },
    loading,
  } = useSelector((state) => state.admin.orders);
  const orderStats = useSelector((state) => state.admin.orderStats.data.data);
  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [userFilter, setUserFilter] = useState(userFilterDefault);
  const [statusFilter, setStatusFilter] = useState(statusFilterDefault);
  const [details, setDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(statusFilterDefault);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const totalpage = Math.ceil(count / limitPerPage);

  const writeAccess = hasPermission(MODULES.ORDERS, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  //fetch order listing
  useEffect(() => {
    dispatch(
      fetchList(type, {
        search: searchFilter,
        user_id: _get(userFilter, "value", ""),
        status: _get(statusFilter, "value", ""),
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
      })
    );
    setSelectedItems([]);
  }, [
    dispatch,
    page,
    userFilter,
    statusFilter,
    limitPerPage,
    searchFilter,
    message,
  ]);

  //fetch order stats
  useEffect(() => {
    dispatch(fetchList("orderStats"));
  }, [dispatch, message]);

  //search handler
  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  //open order details
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  //reset all filters
  const resetFilters = () => {
    setSearch("");
    setSearchFilter("");
    setStatusFilter(statusFilterDefault);
    setUserFilter(userFilterDefault);
  };

  //export orders
  const fileExport = async () => {
    setExportLoading(true);
    await handleFileExport(endpoints.exportOrdersToExcel, {
      user_id: _get(userFilter, "value", ""),
    });
    setExportLoading(false);
  };

  const printInvoice = (e, id) => {
    e.stopPropagation();
    setInvoiceLoading(id);
    POST(`${baseUrl}${endpoints.orderInvoice}`, { id })
      .then((res) => {
        const arr = new Uint8Array(res?.data?.data);
        const blob = new Blob([arr], { type: "application/pdf" });
        const link = document.createElement("a");
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = "invoice.pdf";
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      })
      .catch((err) => toast.error("Error"))
      .finally(() => setInvoiceLoading(null));
  };

  const selectRow = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems((v) => [...v, item._id]);
    } else {
      setSelectedItems((v) => v.filter((i) => i !== item._id));
    }
  };

  const multiRowsSelect = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems(ordersList.map((v) => v._id));
    } else {
      setSelectedItems([]);
    }
  };

  const bulkActions = (actionType) => {
    if (actionType === "delete") {
      confirmAlert({
        title: (
          <h3>
            All order related records will be deleted forever, confirm delete?
          </h3>
        ),

        buttons: [
          {
            label: "Yes",
            className: "bg-danger",
            onClick: () =>
              dispatch(
                blockUnblockRequest(type, {
                  order_ids: selectedItems,
                  is_deleted: true,
                })
              ),
          },
          {
            label: "No",
          },
        ],
      });
    } else {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              order_ids: selectedItems,
              order_status: statusUpdate.value,
            })
          ),
        "Update Status"
      );
    }
  };

  const actionButtons = () => {
    const buttons = [
      {
        label: "Export",
        variant: "outline",
        onClick: fileExport,
        loading: exportLoading,
      },
    ];
    if (writeAccess) {
      buttons.push({
        label: "Add Order",
        onClick: () => history.push(`${ROUTES.ORDERS}${ROUTES.ADD}`),
      });
    }
    return buttons;
  };

  return (
    <>
      <CRow>
        {orderStats.map((stat, i) => (
          <CCol sm="6" lg="3">
            <CWidgetDropdown
              color={
                i === 0
                  ? "gradient-info"
                  : i === 1
                  ? "gradient-success"
                  : i === 2
                  ? "gradient-primary"
                  : "gradient-danger"
              }
              header={`${stat.value}`}
              text={stat.label}
              footerSlot={<div className="mb-2"></div>}
            ></CWidgetDropdown>
          </CCol>
        ))}
      </CRow>
      <PageContainer
        title="Orders"
        rightButtons={actionButtons()}
        pagination={{
          activePage: page,
          onActivePageChange: pageChange,
          pages: totalpage,
          setLimitPerPage,
        }}
      >
        <CRow className={"mb-2"}>
          <CCol sm={3}>
            <form onSubmit={searchHandler}>
              Search
              <CInput
                placeholder="press enter to search.."
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </CCol>
          <CCol sm={3}>
            Filter by User
            <FormInput
              type="select-async"
              loadOptions={searchUsers}
              value={userFilter}
              onChange={(v) => setUserFilter(v)}
            />
          </CCol>
          <CCol sm={3}>
            Filter by Status
            <FormInput
              type="select"
              placeholder={"Select"}
              options={orderStatusDropdown}
              value={statusFilter}
              onChange={(v) => setStatusFilter(v)}
            />
          </CCol>
          <CCol sm={2}>
            <CButton
              color="primary"
              variant="outline"
              size="sm"
              className="resetButton"
              onClick={resetFilters}
            >
              Reset
            </CButton>
          </CCol>
        </CRow>

        {!!selectedItems.length && (
          <CRow className={"mt-0 mb-3"}>
            <CCol sm={3}>
              Update Order Status
              <FormInput
                type="select"
                placeholder={"Select"}
                options={orderStatusDropdown}
                value={statusUpdate}
                onChange={(v) => setStatusUpdate(v)}
              />
            </CCol>
            <CCol>
              <CButton
                color="danger"
                variant="outline"
                className="resetButton"
                onClick={() => bulkActions("update")}
              >
                Update
              </CButton>
              <CButton
                color="danger"
                variant="outline"
                className="resetButton ml-2"
                onClick={() => bulkActions("delete")}
              >
                Delete
              </CButton>
            </CCol>
          </CRow>
        )}

        <CDataTable
          items={ordersList}
          fields={[
            {
              key: "select",
              sorter: false,
              filter: false,
              label: writeAccess ? (
                <input
                  type="checkbox"
                  className="multiSelect"
                  onChange={multiRowsSelect}
                />
              ) : (
                "#"
              ),
            },
            {
              key: "Edit",
              label: "Actions",
              sorter: false,
              filter: false,
            },
            "order_id",
            "date",
            "total",
            // "paid_by_user",
            "order_status",
            "payment_method",
            {
              key: "name",
              label: "User Details",
              sorter: false,
              filter: false,
            },
          ]}
          hover
          sorter
          clickableRows
          responsive
          loading={loading}
          addTableClasses={"orders"}
          scopedSlots={{
            select: (item, index) =>
              writeAccess ? (
                <td onClick={(e) => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    className="multiSelect"
                    checked={selectedItems.includes(item._id)}
                    onChange={(e) => selectRow(e, item, index)}
                  />
                </td>
              ) : (
                <td>{limitPerPage * (page - 1) + (index + 1)}</td>
              ),
            order_id: (item) => (
              <td
                className={
                  _get(item, "order_created_from_exchange", false)
                    ? "bgred"
                    : ""
                }
              >
                {_get(item, "order_id", "-")}
              </td>
            ),
            date: (item) => (
              <td
                className={
                  _get(item, "order_created_from_exchange", false)
                    ? "bgred"
                    : ""
                }
              >
                {Moment(item.createdAt).format("DD-MM-YYYY hh:mm a")}
              </td>
            ),
            order_status: (item) => (
              <td
                className={
                  _get(item, "order_created_from_exchange", false)
                    ? "bgred"
                    : ""
                }
              >
                <CBadge color={getOrderBadge(_get(item, "order_status", ""))}>
                  {getOrderStatus(_get(item, "order_status", ""))}
                </CBadge>
              </td>
            ),
            payment_method: (item) => (
              <td
                className={
                  _get(item, "order_created_from_exchange", false)
                    ? "bgred"
                    : ""
                }
              >
                <CBadge
                  color={
                    _get(item, "payment_method", false) ? "primary" : "dark"
                  }
                >
                  {getPaymentMethod(_get(item, "payment_method", ""))}
                </CBadge>
              </td>
            ),
            name: (item) => (
              <td
                className={
                  _get(item, "order_created_from_exchange", false)
                    ? "bgred"
                    : ""
                }
              >{`${_get(item, "user_id.first_name", "")} ${_get(
                item,
                "user_id.last_name",
                ""
              )} - ${_get(item, "user_id.email_address", "")} - ${_get(
                item,
                "user_id.mobile",
                ""
              )}`}</td>
            ),
            total: (item) => (
              <td
                className={
                  _get(item, "order_created_from_exchange", false)
                    ? "bgred"
                    : ""
                }
              >
                {priceString(_get(item, "cart_total.total", ""))}
              </td>
            ),
            // paid_by_user: (item) => (
            //   <td
            //     className={
            //       _get(item, "order_created_from_exchange", false)
            //         ? "bgred"
            //         : ""
            //     }
            //   >
            //     {priceString(_get(item, "cart_total.paid_by_user", 0))}
            //   </td>
            // ),

            Edit: (item, index) => {
              return (
                <td
                  className={`py-2 ${
                    _get(item, "order_created_from_exchange", false)
                      ? "bgred"
                      : ""
                  }`}
                >
                  <CTooltip content="Details">
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDetails(index);
                      }}
                    >
                      {details.includes(index) ? (
                        <EyeOffIcon className="eyeIcon" />
                      ) : (
                        <EyeIcon className="eyeIcon" />
                      )}
                    </CButton>
                  </CTooltip>

                  {!_get(item, "order_created_from_exchange", false) ? (
                    <CButton
                      color={"primary"}
                      variant="outline"
                      size="sm"
                      className="ml-2"
                      disabled={_get(
                        item,
                        "order_created_from_exchange",
                        false
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`${ROUTES.EXCHANGES}${ROUTES.ADD}`, item);
                      }}
                    >
                      Exchange/Return
                    </CButton>
                  ) : (
                    <span className="ml-4">
                      {_get(item, "exchange_return_request_id.unique_id")}
                    </span>
                  )}

                  {!!writeAccess && (
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      className="ml-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push("/orders/edit", item);
                      }}
                    >
                      Edit
                    </CButton>
                  )}

                  <CTooltip content="Invoice">
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      className="ml-2"
                      onClick={(e) => printInvoice(e, _get(item, "_id", ""))}
                    >
                      {_get(item, "_id", "") === invoiceLoading ? (
                        <CSpinner color="primary" size="sm" />
                      ) : (
                        <CIcon name="cil-notes" size="sm" />
                      )}
                    </CButton>
                  </CTooltip>
                </td>
              );
            },
            details: (item, index) => {
              return (
                <CCollapse
                  show={details.includes(index)}
                  style={{ padding: 20 }}
                >
                  <OrderDetails item={item} />
                </CCollapse>
              );
            },
          }}
        />
      </PageContainer>
    </>
  );
};

export default Orders;
