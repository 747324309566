export const ROUTES = {
  APP: "/",
  LOGIN: "/login",

  ADD: "/add",
  EDIT: "/edit",
  ID: "/:id",

  DASHBOARD: "/dashboard",
  ACCOUNT: "/account",
  USERS: "/users",

  PRODUCTS: "/products",
  VARIANTIONS: "/variants",
  TAGS: "/tags",
  PERSONALISATION: "/personalisation",
  SIZE_GUIDES: "/size-guides",

  CATEGORIES: "/categories",
  SUB_CATEGORIES: "/sub-categories",
  DETAILED_SUB_CATEGORIES: "/detailed-sub-categories",

  ORDERS: "/orders",
  EXCHANGES: "/exchanges",
  RETURNS: "/returns",

  COUPONS: "/coupons",
  PROMOTIONS: "/promotions",

  WALLET_LOGS: "/wallet-logs",
  SUB_ADMINS: "/sub-admins",

  MAIL_LOGS: "/mail-logs",
  MAIL_FORMATS: "/mail-formats",

  DEFAULTS: "/defaults",
  CONTENT: "/content",
  TAX_SLABS: "/tax-slabs",
};
