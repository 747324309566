import React, { useState, useEffect } from "react";
import {
  CButton,
  CDataTable,
  CRow,
  CCol,
  CInput,
  CModalHeader,
  CModal,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import { fetchList } from "src/store/actions/admin";
import { defaultLimitPerPage } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import _get from "lodash/get";
import FormInput from "src/components/FormInput";
import renderHTML from "react-render-html";
import { searchUsers } from "src/utils";

const type = "mailLogs";
const userDropdown = {
  label: "Search User",
  value: "",
};

const MailLogs = ({ history }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.mailLogs.loading);
  const mailLogs = useSelector((state) => state.admin.mailLogs.data.data);
  const total = useSelector((state) => state.admin.mailLogs.data.count);
  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState();
  const [userFilter, setUserFilter] = useState(userDropdown);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    dispatch(
      fetchList(type, {
        search,
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        user_id: _get(userFilter, "value", ""),
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        user_id: _get(userFilter, "value", ""),
      })
    );
  }, [dispatch, message, page, userFilter, limitPerPage, search]);

  return (
    <PageContainer
      title="Email Logs"
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={3}>
          Filter by User
          <FormInput
            type="select-async"
            cacheOptions
            loadOptions={searchUsers}
            value={userFilter}
            onChange={(v) => setUserFilter(v)}
          />
        </CCol>
        <CCol sm={2}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setUserFilter(userDropdown);
              setSearch("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={mailLogs}
        fields={[
          "sr_no",
          "actions",
          "receiver_name",
          "receiver_email",
          "subject",
          "sentAt",
        ]}
        hover
        sorter
        itemsPerPage={limitPerPage}
        activePage={page}
        clickableRows
        pagination
        responsive
        loading={loading}
        onRowClick={(item) => setModal(_get(item, "message"))}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          actions: (item) => (
            <td>
              <CButton color="primary" variant="outline" size="sm">
                View
              </CButton>
            </td>
          ),
          receiver_name: (item) => (
            <td>{`${_get(item, "user_id.first_name", "")} ${_get(
              item,
              "user_id.last_name",
              ""
            )}`}</td>
          ),
          receiver_email: (item) => (
            <td>{_get(item, "user_id.email_address", "")}</td>
          ),
          sentAt: (item) => (
            <td>{Moment(item.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
          ),
        }}
      />
      <CModal
        show={modal ? true : false}
        onClose={() => setModal(false)}
        style={{ width: "800px" }}
      >
        <CModalHeader closeButton>
          <CModalTitle>View Email Message</CModalTitle>
        </CModalHeader>
        <CModalBody style={{ overflow: "scroll" }}>
          {renderHTML(modal || "")}
        </CModalBody>
      </CModal>
    </PageContainer>
  );
};

export default MailLogs;
