import axios from "../../config/axios-config";
import { endpoints } from "../../config/api";
import { LOGIN, REQUEST, FAIL, SUCCESS, LOGOUT } from "./actionTypes";

// export const authModalReset = () => ({ type: actionTypes.AUTH_MODAL_RESET });

export const login = (email, password) => {
  return (dispatch) => {
    dispatch({ type: LOGIN[REQUEST] });
    axios
      .post(endpoints.login, { email, password })
      .then((res) => {
        dispatch({ type: LOGIN[SUCCESS], data: res.data.data });
      })
      .catch((err) => {
        if (err.response)
          dispatch({ type: LOGIN[FAIL], error: err.response.data.message });
        else dispatch({ type: LOGIN[FAIL], error: err.message });
      });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    axios({
      method: "POST",
      url: endpoints.logout,
      headers: {
        authorization: `Bearer ${getState().auth?.user?.data?.access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: LOGOUT[SUCCESS] });
      })
      .catch((err) => {
        localStorage.removeItem("persist:root");
      });
  };
};
