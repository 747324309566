import React, { lazy, useState, useEffect } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import MainChartExample from "../charts/MainChartExample.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "src/store/actions/admin.js";
import _get from "lodash/get";
import moment from "moment";

const WidgetsDropdown = lazy(() => import("../widgets/WidgetsDropdown.js"));
// const WidgetsBrand = lazy(() => import("../widgets/WidgetsBrand.js"));

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState();
  const data = useSelector((s) => s.admin.dashboard?.data);

  useEffect(() => {
    dispatch(
      fetchList("dashboard", {
        start_date: dateRange
          ? moment(dateRange?.[0]).format("YYYY-MM-DD")
          : "",
        end_date: dateRange ? moment(dateRange?.[1]).format("YYYY-MM-DD") : "",
      })
    );
  }, [dispatch, dateRange]);

  return (
    <>
      Date Range
      <CRow className={"mb-4 mt-1"}>
        <CCol>
          <DateRangePicker value={dateRange} onChange={setDateRange} />
        </CCol>
      </CRow>
      <WidgetsDropdown {..._get(data, "data[0]", {})} />
      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="5">
              <h4 id="traffic" className="card-title mb-0">
                Traffic
              </h4>
              <div className="small text-muted">November 2017</div>
            </CCol>
            <CCol sm="7" className="d-none d-md-block">
              <CButton color="primary" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButtonGroup className="float-right mr-3">
                {["Day", "Month", "Year"].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === "Month"}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <MainChartExample style={{ height: "300px", marginTop: "40px" }} />
        </CCardBody>
      </CCard>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <CCardTitle>Top Categories - Items Sold </CCardTitle>
            </CCardHeader>
            <CCardBody>
              <table className="table table-hover table-outline mb-0 d-none d-sm-table">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Category Name</th>
                    <th>Item Name</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {_get(data, "get_category_product_detail", []).map(
                    (row, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{`${_get(row, "category_name", "-")} > ${_get(
                          row,
                          "sub_category_name",
                          "-"
                        )} > ${_get(
                          row,
                          "detailed_sub_category_name",
                          "-"
                        )}`}</td>
                        <td>{_get(row, "item_name")}</td>
                        <td>{_get(row, "quantity")}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Dashboard;
