import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import Logo from "../assets/icons/main-logo.svg";

import { PERMISSIONS, MODULES } from "src/utils/constants";
import { ROUTES } from "src/router/routes";
import { hasPermission } from "src/utils/helper";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sideBar.sidebarShow);

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <img src={Logo} width={100} alt="logo" />
      </CSidebarBrand>
      <CSidebarNav>
        {hasPermission(MODULES.DASHBOARD, PERMISSIONS.READ) && (
          <CSidebarNavItem
            icon="cil-speedometer"
            to={ROUTES.DASHBOARD}
            name="Dashboard"
          />
        )}
        {hasPermission(MODULES.PRODUCTS, PERMISSIONS.READ) && (
          <CSidebarNavDropdown name="Products" icon="cil-cart">
            <CSidebarNavItem to={ROUTES.PRODUCTS} name="All Products" />
            <CSidebarNavItem to={ROUTES.VARIANTIONS} name="Variations" />
            <CSidebarNavItem to={ROUTES.TAGS} name="Tags" />
            <CSidebarNavItem
              to={ROUTES.PERSONALISATION}
              name="Personalisation"
            />
            <CSidebarNavItem to={ROUTES.SIZE_GUIDES} name="Size Guides" />
          </CSidebarNavDropdown>
        )}
        {hasPermission(MODULES.CATEGORIES, PERMISSIONS.READ) && (
          <CSidebarNavDropdown name="Product Categories" icon="cil-tags">
            <CSidebarNavItem to={ROUTES.CATEGORIES} name="Parent Category" />
            <CSidebarNavItem to={ROUTES.SUB_CATEGORIES} name="Sub Category" />
            <CSidebarNavItem
              to={ROUTES.DETAILED_SUB_CATEGORIES}
              name="Detailed Category"
            />
          </CSidebarNavDropdown>
        )}
        {hasPermission(MODULES.ORDERS, PERMISSIONS.READ) && (
          <CSidebarNavDropdown name="Orders" icon="cil-check">
            <CSidebarNavItem to={ROUTES.ORDERS} name="All Orders" />
            <CSidebarNavItem to={ROUTES.EXCHANGES} name="Exchange Requests" />
            <CSidebarNavItem to={ROUTES.RETURNS} name="Return Requests" />
          </CSidebarNavDropdown>
        )}

        {hasPermission(MODULES.MARKETING, PERMISSIONS.READ) && (
          <CSidebarNavDropdown name="Marketing" icon="cil-gift">
            <CSidebarNavItem to={ROUTES.COUPONS} name="Coupons" />
            <CSidebarNavItem to={ROUTES.PROMOTIONS} name="Promotions" />
          </CSidebarNavDropdown>
        )}
        {hasPermission(MODULES.USERS, PERMISSIONS.READ) && (
          <CSidebarNavItem icon="cil-user" to={ROUTES.USERS} name="Users" />
        )}
        {hasPermission(MODULES.WALLET_LOGS, PERMISSIONS.READ) && (
          <CSidebarNavItem
            icon="cil-cash"
            to={ROUTES.WALLET_LOGS}
            name="Wallet Logs"
          />
        )}
        {hasPermission(MODULES.SUB_ADMINS, PERMISSIONS.READ) && (
          <CSidebarNavItem
            icon="cil-people"
            to={ROUTES.SUB_ADMINS}
            name="Sub Admins"
          />
        )}
        {hasPermission(MODULES.EMAILS, PERMISSIONS.READ) && (
          <CSidebarNavDropdown name="Emails" icon="cil-inbox">
            <CSidebarNavItem to={ROUTES.MAIL_LOGS} name="Email Logs" />
            <CSidebarNavItem to={ROUTES.MAIL_FORMATS} name="Email Formats" />
          </CSidebarNavDropdown>
        )}
        {hasPermission(MODULES.SETTINGS, PERMISSIONS.READ) && (
          <CSidebarNavDropdown name="Settings" icon="cil-settings">
            <CSidebarNavItem to={ROUTES.DEFAULTS} name="Default Settings" />
            <CSidebarNavItem to={ROUTES.CONTENT} name="Content" />
            <CSidebarNavItem to={ROUTES.TAX_SLABS} name="Tax Slabs" />
          </CSidebarNavDropdown>
        )}
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
