import React, { useState, useEffect } from "react";
import { CModalBody } from "@coreui/react";
import FormInput from "../FormInput";
import { State, City } from "country-state-city";
import _isObject from "lodash/isObject";
import _get from "lodash/get";

const Address = ({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
}) => {
  const [statesList, setStateList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  useEffect(() => {
    let states = State.getStatesOfCountry("IN");
    let list = [];
    for (let i = 0; i < states.length; i++) {
      list.push({
        label: states[i].name,
        value: states[i].isoCode,
      });
    }
    setStateList(list);
  }, []);

  const fetchCities = (code) => {
    let cities = City.getCitiesOfState("IN", code);
    let list = [];
    for (let i = 0; i < cities.length; i++) {
      list.push({
        label: cities[i].name,
        value: cities[i].isoCode,
      });
    }
    setCitiesList(list);
  };
  return (
    <CModalBody>
      <FormInput
        type="text"
        label={"First Name"}
        name={"first_name"}
        value={values.first_name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter first name"}
        invalid={(errors || {}).first_name && (touched || {}).first_name}
        error={(errors || {}).first_name}
      />
      <FormInput
        type="text"
        label={"Last Name"}
        name={"last_name"}
        value={values.last_name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter last name"}
        invalid={(errors || {}).last_name && (touched || {}).last_name}
        error={(errors || {}).last_name}
      />
      <FormInput
        type="text"
        label={"Email"}
        name={"email_address"}
        value={values.email_address}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter email address"}
        invalid={(errors || {}).email_address && (touched || {}).email_address}
        error={(errors || {}).email_address}
      />
      <FormInput
        type="text"
        label={"Phone Number"}
        name={"phone_number"}
        value={values.phone_number}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter Phone Number"}
        invalid={(errors || {}).phone_number && (touched || {}).phone_number}
        error={(errors || {}).phone_number}
      />
      <FormInput
        type="text"
        label={"Company"}
        name={"company"}
        value={values.company}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter Company"}
        invalid={(errors || {}).company && (touched || {}).company}
        error={(errors || {}).company}
      />
      <FormInput
        type="text"
        label={"Address Line 1"}
        name={"address_line_one"}
        value={values.address_line_one}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter Address"}
        invalid={
          (errors || {}).address_line_one && (touched || {}).address_line_one
        }
        error={(errors || {}).address_line_one}
      />
      <FormInput
        type="text"
        label={"Address Line 1"}
        name={"address_line_second"}
        value={values.address_line_second}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter Address"}
        invalid={
          (errors || {}).address_line_second &&
          (touched || {}).address_line_second
        }
        error={(errors || {}).address_line_second}
      />

      <FormInput
        type="select"
        label={"State"}
        name={"state_country"}
        value={
          _isObject(values.state_country)
            ? values.state_country
            : {
                label: _get(values, "state_country", ""),
                value: _get(values, "state_country", ""),
              }
        }
        options={statesList}
        onChange={(v) => {
          setFieldValue("state_country", v);
          fetchCities(v.value);
        }}
        onBlur={handleBlur}
        placeholder={"Select State"}
        invalid={(errors || {}).state_country && (touched || {}).state_country}
        error={(errors || {}).state_country}
      />
      <FormInput
        type="select"
        label={"City"}
        name={"city"}
        value={
          _isObject(values.city)
            ? values.city
            : {
                label: _get(values, "city", ""),
                value: _get(values, "city", ""),
              }
        }
        options={citiesList}
        onChange={(v) => setFieldValue("city", v)}
        onBlur={handleBlur}
        placeholder={"Select City"}
        invalid={(errors || {}).city && (touched || {}).city}
        error={(errors || {}).city}
      />

      <FormInput
        type="text"
        label={"Postal code"}
        name={"postcode_zip"}
        value={values.postcode_zip}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={"Enter Postal code"}
        invalid={(errors || {}).postcode_zip && (touched || {}).postcode_zip}
        error={(errors || {}).postcode_zip}
      />
    </CModalBody>
  );
};

Address.defaultProps = {
  values: {
    first_name: "",
    last_name: "",
    company: "",
    address_line_one: "",
    address_line_second: "",
    city: "",
    postcode_zip: "",
    country_region: "India",
    state_country: {},
    country_code: "+91",
    phone_number: "",
    email_address: "",
  },
};

export default Address;
