import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import {
  fetchList,
  blockUnblockRequest,
  addEditRequest,
} from "src/store/actions/admin";
import {
  getBadge,
  confirmModal,
  getBlockButtonText,
  handleFileExport,
  priceString,
  hasPermission,
} from "src/utils/helper";
import PageContainer from "src/components/PageContainer";
import user from "../../assets/icons/user.svg";
import FormInput from "src/components/FormInput";
import _map from "lodash/map";
import _get from "lodash/get";
import { endpoints } from "src/config/api";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import { ROUTES } from "src/router/routes";
import { searchCategories, searchSubCategories, searchTags } from "src/utils";

const type = "products";

const Products = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: productsList, count: total },
  } = useSelector((state) => state.admin.products);
  const { loading: apiLoading, message } = useSelector(
    (state) => state.admin.modal
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  const [page, setPage] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        search: searchFilter,
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        category_id: _map(categoryFilter, "value"),
        sub_category_id: _map(subCategoryFilter, "value"),
        tag_id: _map(tagFilter, "value"),
      })
    );
    setSelectedItems([]);
  }, [
    dispatch,
    page,
    message,
    searchFilter,
    categoryFilter,
    subCategoryFilter,
    tagFilter,
    limitPerPage,
  ]);

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      (status) =>
        dispatch(
          blockUnblockRequest(type, {
            product_id: item._id,
            status,
          })
        ),
      "Publish",
      "Unpublish"
    );
  };

  const deleteProduct = (e, item) => {
    e.stopPropagation();
    confirmModal(
      0,
      () =>
        dispatch(
          blockUnblockRequest(type, {
            product_id: item._id,
            status: 2,
          })
        ),
      "Delete"
    );
  };

  const resetFilter = () => {
    setSearch("");
    setSearchFilter("");
    setCategoryFilter("");
    setSubCategoryFilter("");
    setTagFilter("");
  };

  const handleFileImport = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("files", file);
      dispatch(addEditRequest("importFromExcel", formData));
    }
  };

  const fileExport = async () => {
    setExportLoading(true);
    await handleFileExport(endpoints.exportProductsToExcel);
    setExportLoading(false);
  };

  const selectRow = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems((v) => [...v, item._id]);
    } else {
      setSelectedItems((v) => v.filter((i) => i !== item._id));
    }
  };

  const multiRowsSelect = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems(productsList.map((v) => v._id));
    } else {
      setSelectedItems([]);
    }
  };

  const bulkActions = (actiontype) => {
    if (actiontype === "delete") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              product_ids: selectedItems,
              status: 2,
            })
          ),
        "Delete"
      );
    } else if (actiontype === "block") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              product_ids: selectedItems,
              status: 0,
            })
          ),
        "Unpublish"
      );
    } else if (actiontype === "unblock") {
      confirmModal(
        0,
        () =>
          dispatch(
            blockUnblockRequest(type, {
              product_ids: selectedItems,
              status: 1,
            })
          ),
        "Publish"
      );
    }
  };

  const getActionsButtons = () => {
    const actionButtons = [
      {
        label: "Export",
        variant: "outline",
        onClick: fileExport,
        loading: exportLoading,
      },
    ];
    if (writeAccess) {
      actionButtons.push(
        {
          label: "Import",
          type: "file",
          onClick: handleFileImport,
          loading: apiLoading,
          accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        },
        {
          label: "Add Product",
          onClick: () => history.push(`${ROUTES.PRODUCTS}${ROUTES.ADD}`),
        }
      );
    }
    return actionButtons;
  };

  return (
    <PageContainer
      title="Products"
      rightButtons={getActionsButtons()}
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
      loading={loading}
    >
      <CRow>
        <CCol>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol>
          Filter by Category
          <FormInput
            type="select-async"
            placeholder="Search Category"
            isMulti
            loadOptions={searchCategories}
            value={categoryFilter}
            onChange={(v) => setCategoryFilter(v)}
          />
        </CCol>
        <CCol>
          Filter by Sub Category
          <FormInput
            type="select-async"
            placeholder="Search SubCategory"
            isMulti
            loadOptions={searchSubCategories}
            value={subCategoryFilter}
            onChange={(v) => setSubCategoryFilter(v)}
          />
        </CCol>
        <CCol>
          Filter by Tags
          <FormInput
            type="select-async"
            placeholder="Search Tags"
            loadOptions={searchTags}
            value={tagFilter}
            isMulti
            onChange={(v) => setTagFilter(v)}
          />
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={resetFilter}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      {!!selectedItems.length && (
        <CRow className={"mb-2"}>
          <CCol>
            <CButton
              color="primary"
              variant="outline"
              className="resetButton"
              onClick={() => bulkActions("unblock")}
            >
              Publish
            </CButton>

            <CButton
              color="primary"
              variant="outline"
              className="ml-2 resetButton"
              onClick={() => bulkActions("block")}
            >
              Unpublish
            </CButton>

            <CButton
              color="danger"
              variant="outline"
              className="ml-2 resetButton"
              onClick={() => bulkActions("delete")}
            >
              Delete
            </CButton>
          </CCol>
        </CRow>
      )}

      <CDataTable
        items={productsList}
        fields={[
          // "sr_no",
          {
            key: "select",
            sorter: false,
            filter: false,
            label: writeAccess ? (
              <input
                type="checkbox"
                className="multiSelect"
                onChange={multiRowsSelect}
              />
            ) : (
              "Sr no"
            ),
          },
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "image",
          "item_name",
          "product_sku",
          "price",
          "category",
          "sub_category",
          "detailed_sub_category",
        ]}
        hover
        clickableRows
        loading={loading}
        addTableClasses={"products"}
        onRowClick={(item) => {
          if (writeAccess) {
            window
              ?.open(
                `${ROUTES.PRODUCTS}${ROUTES.EDIT}/${_get(item, "_id")}`,
                "_blank"
              )
              ?.focus?.();
          }
        }}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          select: (item, index) =>
            writeAccess ? (
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="multiSelect"
                  checked={selectedItems.includes(item._id)}
                  onChange={(e) => selectRow(e, item, index)}
                />
              </td>
            ) : (
              <td>{limitPerPage * (page - 1) + (index + 1)}</td>
            ),
          image: (item) => (
            <td>
              <img
                alt="thumb"
                className="c-avatar-img avatar"
                src={_get(item, "product_image[0].thumbnail_url") || user}
              />
            </td>
          ),
          category: (item) => (
            <td>{_get(item, "category_id.category_name", "")}</td>
          ),
          price: (item) => <td>{priceString(_get(item, "price", 0))}</td>,
          sub_category: (item) => (
            <td>{_get(item, "sub_category_id.sub_category_name", "")}</td>
          ),

          detailed_sub_category: (item) => (
            <td>
              {_get(
                item,
                "detailed_sub_category_id.detailed_sub_category_name",
                "-"
              )}
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),

          actions: (item) =>
            writeAccess ? (
              <td className="py-2">
                <CButton
                  className={"mr-2"}
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {getBlockButtonText(item.status)}
                </CButton>
                <CButton
                  color="danger"
                  variant="outline"
                  size="sm"
                  onClick={(e) => deleteProduct(e, item)}
                >
                  <CIcon name="cil-trash" /> Delete
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default Products;
