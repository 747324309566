import React from "react";
import { subAdminSchema } from "../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import {
  CForm,
  CButton,
  CSpinner,
  CCardTitle,
  CRow,
  CCol,
} from "@coreui/react";
import { Formik } from "formik";
import { defaultRoles, PERMISSIONS } from "src/utils/constants";
import cloneDeep from "lodash/cloneDeep";

const type = "subAdmins";
const defaultFormValues = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  super_admin: false,
  role: [...defaultRoles],
};

const AddEditSubAdmin = ({
  isEdit,
  history,
  location: { state: subAdmin },
}) => {
  if (isEdit && !subAdmin) {
    history.push("/sub-admins");
  }
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);

  const onSubmit = (values) => {
    let data = {};
    if (isEdit) {
      data.id = values?._id;
      data.email = values?.email;
      data.password = values?.password;
      data.first_name = values?.first_name;
      data.last_name = values?.last_name;
      data.super_admin = values?.super_admin;
      data.role = values?.role;
    } else data = values;
    dispatch(addEditRequest(type, data, history));
  };

  const onRoleChange = (e, type, values, index, setFieldValue) => {
    const tempAllRoles = cloneDeep(values?.role);
    const tempRole = cloneDeep(tempAllRoles[index]);
    const tempIndex = tempRole.permission.indexOf(type);
    if (e.target.checked) {
      if (tempIndex < 0) {
        tempRole.permission.push(type);
      }
    } else if (tempIndex !== -1) {
      tempRole.permission.splice(tempIndex, 1);
    }
    tempAllRoles[index] = tempRole;
    setFieldValue("role", tempAllRoles);
  };

  return (
    <PageContainer lg={6} title={isEdit ? "Edit Sub Admin" : "Add Sub Admin"}>
      <Formik
        initialValues={isEdit ? subAdmin : defaultFormValues}
        validationSchema={subAdminSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"First Name"}
              name={"first_name"}
              id={"first_name"}
              value={values["first_name"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["first_name"] && touched["first_name"]}
              error={errors["first_name"]}
              placeholder={"Enter first name"}
            />
            <FormInput
              type="text"
              label={"Last Name"}
              name={"last_name"}
              id={"last_name"}
              value={values["last_name"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["last_name"] && touched["last_name"]}
              error={errors["last_name"]}
              placeholder={"Enter first name"}
            />
            <FormInput
              type="text"
              label={"Email"}
              name={"email"}
              id={"email"}
              value={values["email"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["email"] && touched["email"]}
              error={errors["email"]}
              placeholder={"Enter email address"}
            />

            <FormInput
              type="password"
              label={"Password"}
              name={"password"}
              id={"password"}
              value={values["password"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["password"] && touched["password"]}
              error={errors["password"]}
              placeholder={"Enter Password"}
            />

            <FormInput
              type="switch"
              label={"Super admin"}
              name={"super_admin"}
              id={"super_admin"}
              value={values["super_admin"]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!values["super_admin"] && (
              <>
                <CCardTitle>Roles</CCardTitle>
                <hr />
                <CRow>
                  <CCol>
                    <h5>Menu</h5>
                  </CCol>
                  <CCol>
                    <h5>View</h5>
                  </CCol>
                  <CCol>
                    <h5>Add/Edit</h5>
                  </CCol>
                </CRow>

                {values?.role?.map((role, index) => (
                  <CRow key={index}>
                    <CCol>{role?.module}</CCol>

                    <CCol>
                      <input
                        checked={role.permission?.includes(PERMISSIONS.READ)}
                        type="checkbox"
                        onChange={(e) =>
                          onRoleChange(
                            e,
                            PERMISSIONS.READ,
                            values,
                            index,
                            setFieldValue
                          )
                        }
                      />
                    </CCol>
                    <CCol>
                      <input
                        checked={role?.permission?.includes(PERMISSIONS.WRITE)}
                        type="checkbox"
                        onChange={(e) =>
                          onRoleChange(
                            e,
                            PERMISSIONS.WRITE,
                            values,
                            index,
                            setFieldValue
                          )
                        }
                      />
                    </CCol>
                  </CRow>
                ))}
              </>
            )}

            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditSubAdmin;
