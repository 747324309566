import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  addProductToOrderSchema,
  addressSchema,
} from "../../config/formSchema";
import { addEditRequest, fetchList } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import {
  orderStatusDropdown,
  payemntMethodDropdown,
} from "src/utils/constants";
import {
  CForm,
  CButton,
  CSpinner,
  CCard,
  CCardHeader,
  CCardBody,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
  CDataTable,
  CRow,
  CCol,
  CCardTitle,
} from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import CIcon from "@coreui/icons-react";
import { GET } from "src/config/services";
import { endpoints } from "src/config/api";
import { LIST, SUCCESS } from "src/store/actions/actionTypes";
import { confirmModal, getAddress, priceString } from "src/utils/helper";
import _get from "lodash/get";
import Address from "src/components/Address";
import Avatar from "../../assets/icons/user.svg";
import { toast } from "react-toastify";
import { searchUsers, searchProducts, searchCoupons } from "src/utils";

const type = "order";

const defaultModalValues = {
  product_id: {},
  quantity: 1,
  variant_details: {},
  custom: false,
  custom_on_front: "",
  custom_on_back: "",
};

const AddEditOrder = ({ history }) => {
  const dispatch = useDispatch();
  const { loading, message } = useSelector((s) => s.admin.modal);
  const {
    product_details_id: cartProducts,
    cart_total,
    coupan_id,
    gift_wrap,
    cash_free,
    wallet_amount,
    _id: cart_id,
  } = useSelector((s) => s.admin.cart.data);
  const [editItem, setEditItem] = useState(defaultModalValues);
  const [productModal, setProductModal] = useState(false);
  const [user, setUser] = useState({});
  const [coupon, setCoupon] = useState({});
  const [orderStatus, setOrderStatus] = useState(orderStatusDropdown[1]);
  const [paymentMethod, setPaymentMethod] = useState(payemntMethodDropdown[0]);
  const [notes, setNotes] = useState("");
  const [giftWrap, setGiftWrap] = useState("");
  const [addressModal, setAddressModal] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [billingAddress, setBillingAddress] = useState();

  useEffect(() => {
    if (user.value) dispatch(fetchList("cart", { user_id: user.value }));
  }, [dispatch, user.value, message]);

  useEffect(() => {
    dispatch({ type: LIST[SUCCESS], cart: [], name: "cart" });
  }, [dispatch]);

  useEffect(() => {
    if (coupan_id)
      setCoupon({
        label: `${_get(coupan_id, "coupan_code", "")} - ${_get(
          coupan_id,
          "coupan_amount",
          ""
        )}`,
        value: _get(coupan_id, "_id", ""),
      });
  }, [coupan_id]);

  const onUserChange = (value) => {
    setUser(value);
    setBillingAddress(_get(value, "data.billing_address"));
    setShippingAddress(_get(value, "data.shipping_address"));
  };

  // add product to cart
  const addEditProduct = (data, { resetForm }, type) => {
    const product_details_id = [];
    let product;
    if (type === "delete") {
      product = {
        product_id: _get(data, "product_id._id"),
        quantity: 0,
        size_id: _get(data, "size_id._id"),
      };
      product_details_id.push(product);
    } else {
      product = {
        product_id: _get(data, "product_id.value"),
        quantity: _get(data, "quantity"),
        size_id: _get(data, "variant_details._id"),
      };
      if (data.custom) {
        product = {
          ...product,
          custom_rule_id: _get(data, "product_id.data.custom_id"),
          custom_on_front: _get(data, "custom_on_front"),
          custom_on_back: _get(data, "custom_on_back"),
        };
      }
      product_details_id.push(product);
    }
    let payload = {
      user_id: user.value,
      product_details_id,
    };
    if (type === "delete" || data.isEdit) {
      payload["id"] = cart_id;
    }
    dispatch(addEditRequest("addToCart", payload));
    resetForm && resetForm();
    setProductModal(false);
  };

  // edit card items
  const editCartItem = async (product) => {
    let data = {
      variant_details: _get(product, "size_id"),
      quantity: _get(product, "quantity"),
      isEdit: true,
      custom: _get(product, "custom_rule_id") ? true : false,
      custom_on_front: _get(product, "custom_on_front", ""),
      custom_on_back: _get(product, "custom_on_back", ""),
    };
    await GET(endpoints.listProducts, {
      product_id: _get(product, "product_id._id"),
    }).then((res) => {
      data = {
        ...data,
        product_id: {
          label: _get(res, "data.data.item_name"),
          value: _get(res, "data.data._id"),
          product: _get(res, "data.data"),
        },
      };
      setEditItem(data);
    });
    setProductModal(true);
  };

  //delete items from cart
  const deleteCartItem = (p) => {
    confirmModal(0, () => addEditProduct(p, {}, "delete"), "delete");
  };

  //address change handler
  const onAddressChange = (values) => {
    let data = {
      ...values,
      city: _get(values, "city.label", values.city),
      state_country: _get(values, "state_country.label", values.state_country),
    };
    if (addressModal === "shipping_address") {
      setShippingAddress(data);
      dispatch(
        addEditRequest("editCart", {
          user_id: _get(user, "value", ""),
          state_country: _get(
            values,
            "state_country.label",
            values.state_country
          ),
        })
      );
    } else {
      setBillingAddress(data);
    }
    setAddressModal("");
  };

  // cart actions
  const cartAddons = (e) => {
    let data = {
      user_id: _get(user, "value", ""),
      gift_wrap,
      cash_free,
      is_wallet: wallet_amount > 0,
    };
    dispatch(
      addEditRequest("editCart", { ...data, [e.target.name]: e.target.checked })
    );
  };

  // Place Order
  const onSubmit = () => {
    if (!_get(user, "value")) {
      toast.error("Select Customer First", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (!_get(shippingAddress, "postcode_zip")) {
      toast.error("Please Add Shipping Address", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (!cart_id) {
      toast.error("Please Add atleast one product", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    let data = {
      cart_id,
      order_status: orderStatus.value,
      payment_method: paymentMethod.value,
      shipping_address: shippingAddress,
      billing_address: billingAddress,
      order_notes: notes,
      gift_wrap_notes: giftWrap,
    };
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <>
      <PageContainer sm={12} title={"Add New Order"} loading={loading}>
        <FormInput
          xs={5}
          md={5}
          type="select-async"
          label={"Select Customer"}
          placeholder={"Search Customer"}
          loadOptions={searchUsers}
          value={user}
          name={"customer_id"}
          onChange={onUserChange}
        />

        <CRow>
          <CCol xs={8}>
            <CCard style={{ overflow: "scroll" }}>
              <CCardHeader
                className={"d-flex align-items-center justify-content-between"}
              >
                <CCardTitle className="cardtitle">Products</CCardTitle>
                <CButton
                  color="primary"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setEditItem(defaultModalValues);
                    setProductModal(true);
                  }}
                  disabled={user.value ? false : true}
                >
                  Add Products
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  items={cartProducts || []}
                  fields={[
                    // "#",
                    "actions",
                    "image",
                    "product",
                    "variant",
                    "base_price",
                    "total_price",
                    "quantity",
                    { key: "custom_on_front", label: "Personalization (F)" },
                    { key: "custom_on_back", label: "Personalization (B)" },
                  ]}
                  addTableClasses={"add-to-cart"}
                  scopedSlots={{
                    "#": (_, i) => <td>{i + 1}</td>,
                    image: (p) => (
                      <td>
                        <img
                          alt="thumb"
                          className="avatar"
                          src={_get(
                            p,
                            "product_id.product_image[0].thumbnail_url",
                            Avatar
                          )}
                        ></img>
                      </td>
                    ),
                    product: (p) => (
                      <td>{`${_get(p, "product_id.item_name", "")} (${_get(
                        p,
                        "product_id.product_sku",
                        ""
                      )})`}</td>
                    ),
                    variant: ({ size_id }) => (
                      <td>{_get(size_id, "size", "-")}</td>
                    ),
                    base_price: (p) => (
                      <td>{priceString(_get(p, "product_id.price", 0))}</td>
                    ),
                    custom_on_front: (p) => (
                      <td>
                        {`${_get(p, "custom_on_front", "")} -
                      ${
                        _get(p, "custom_on_front_price", 0) > 0
                          ? priceString(_get(p, "custom_on_front_price", 0))
                          : ""
                      }`}
                      </td>
                    ),
                    custom_on_back: (p) => (
                      <td>
                        {`${_get(p, "custom_on_back", "")} -
                      ${
                        _get(p, "custom_on_back_price", 0) > 0
                          ? priceString(_get(p, "custom_on_back_price", 0))
                          : ""
                      }`}
                      </td>
                    ),
                    total_price: (p) => (
                      <td>{priceString(_get(p, "total_price", 0))}</td>
                    ),
                    actions: (p, i) => (
                      <td>
                        <CButton
                          color="primary"
                          variant="outline"
                          size="sm"
                          onClick={() => editCartItem(p)}
                        >
                          <CIcon name="cil-pencil" />
                        </CButton>
                        <CButton
                          className="ml-2"
                          color="danger"
                          variant="outline"
                          size="sm"
                          onClick={() => deleteCartItem(p)}
                        >
                          <CIcon name="cil-x" />
                        </CButton>
                      </td>
                    ),
                  }}
                />
              </CCardBody>
            </CCard>
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol xs={3}>Apply Promocode</CCol>
                  <CCol xs={6}>
                    <FormInput
                      type="select-async"
                      loadOptions={searchCoupons}
                      name={"coupan_id"}
                      value={coupon}
                      onChange={(v) => {
                        setCoupon(v);
                        dispatch(
                          addEditRequest("promocode", {
                            cart_id,
                            promo_id: v.value,
                            promo_status: true,
                          })
                        );
                      }}
                    />
                  </CCol>
                  {!!_get(coupon, "value") && (
                    <CCol sx={2}>
                      <CButton
                        color="danger"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setCoupon("");
                          dispatch(
                            addEditRequest("promocode", {
                              cart_id,
                              promo_id: coupon.value,
                              promo_status: false,
                            })
                          );
                        }}
                      >
                        Remove
                      </CButton>
                    </CCol>
                  )}
                </CRow>
                <FormInput
                  type="select"
                  xs={"6"}
                  md={"6"}
                  label={"Order Status"}
                  options={orderStatusDropdown}
                  value={orderStatus}
                  name={"status"}
                  onChange={(v) => setOrderStatus(v)}
                />

                <FormInput
                  type="select"
                  xs={"6"}
                  md={"6"}
                  label={"Payment Method"}
                  options={payemntMethodDropdown}
                  value={paymentMethod}
                  name={"payment_method"}
                  // disabled={isEdit}
                  onChange={(v) => setPaymentMethod(v)}
                />
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardTitle className="cardtitle">Order Summary:</CCardTitle>
              </CCardHeader>

              <CCardBody>
                {/* <CRow className="mb-2">
                  <CCol xs={10}>
                    CGST ({_get(cart_total, "CGST.cgst_percentage", 0)}
                    %)
                  </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "CGST.cgst", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>
                    IGST ({_get(cart_total, "IGST.igst_percentage", 0)}
                    %)
                  </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "IGST.igst", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>
                    SGST ({_get(cart_total, "SGST.sgst_percentage", 0)}
                    %)
                  </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "SGST.sgst", 0))}
                    </span>
                  </CCol>
                </CRow> */}
                <CRow className="mb-2">
                  <CCol xs={10}>Sub Total : </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "sub_total", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>
                    Discount ({priceString(_get(coupan_id, "coupan_code", 0))}
                    ):
                  </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "discount", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>Gift Wrap Fee : </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "gift_wrap", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>COD Fee : </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "cash_free", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>Shipping Charges: </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "free_shipping", 0))}
                    </span>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol xs={10}>Wallet Amount Used: </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(wallet_amount || 0)}
                    </span>
                  </CCol>
                </CRow>
                <hr />
                <CRow className="mb-2">
                  <CCol xs={10}>Total: </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "total", 0))}
                    </span>
                  </CCol>
                </CRow>
                <hr />

                <CRow className="mb-2">
                  <CCol xs={10}>
                    <b>Amount Paid by user:</b>
                  </CCol>
                  <CCol>
                    <span className={"walletAmount"}>
                      {priceString(_get(cart_total, "paid_by_user", 0))}
                    </span>
                  </CCol>
                </CRow>
                <hr />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={4}>
            <CCard>
              <CCardHeader>
                <CCardTitle className="cardtitle">Customer</CCardTitle>
              </CCardHeader>
              <CCardBody>
                <p>
                  Name :
                  <span className="txt">{` ${_get(
                    user,
                    "data.first_name",
                    ""
                  )} ${_get(user, "data.last_name", "")}  `}</span>
                </p>
                <hr />
                <p>
                  Email :
                  <span className="txt">
                    {` ${_get(user, "data.email_address", "")}`}
                  </span>
                </p>
                <hr />
                <p>
                  Mobile No. :
                  <span className="txt">{` ${_get(
                    user,
                    "data.mobile",
                    ""
                  )}`}</span>
                </p>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>
                <CCardTitle className="cardtitle">Order Actions: </CCardTitle>
              </CCardHeader>
              <CCardBody>
                <FormInput
                  labelsize={9}
                  xs={"2"}
                  md={"2"}
                  type="switch"
                  label={"Gift Wrap"}
                  value={gift_wrap}
                  name={"gift_wrap"}
                  onChange={cartAddons}
                />

                <FormInput
                  labelsize={9}
                  xs={"2"}
                  md={"2"}
                  type="switch"
                  label={"COD Fee"}
                  name={"cash_free"}
                  value={cash_free}
                  onChange={cartAddons}
                />
                <FormInput
                  type="switch"
                  labelsize={9}
                  xs={"2"}
                  md={"2"}
                  label={
                    <>
                      {`Use Wallet Amount - `}
                      <span className={"walletAmount"}>
                        {priceString(_get(user, "data.wallet_amount", 0))}
                      </span>
                    </>
                  }
                  name={"is_wallet"}
                  value={wallet_amount > 0}
                  onChange={cartAddons}
                />
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader
                className={"d-flex align-items-center justify-content-between"}
              >
                <CCardTitle className="cardtitle">Shipping Address:</CCardTitle>
                <CButton
                  color="primary"
                  size="sm"
                  variant="outline"
                  onClick={() => setAddressModal("shipping_address")}
                >
                  Edit
                </CButton>
              </CCardHeader>
              <CCardBody>
                <span className="txt" style={{ whiteSpace: "pre-line" }}>
                  {getAddress(shippingAddress)}
                </span>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader
                className={"d-flex align-items-center justify-content-between"}
              >
                <CCardTitle className="cardtitle">Billing Address: </CCardTitle>
                <CButton
                  color="primary"
                  size="sm"
                  variant="outline"
                  onClick={() => setAddressModal("billing_address")}
                >
                  Edit
                </CButton>
              </CCardHeader>
              <CCardBody>
                <span className="txt" style={{ whiteSpace: "pre-line" }}>
                  {getAddress(billingAddress)}
                </span>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>
                <CCardTitle className="cardtitle">Metadata</CCardTitle>
              </CCardHeader>
              <CCardBody>
                Gift Message:
                <FormInput
                  type="textarea"
                  value={giftWrap}
                  name={"gift_wrap_notes"}
                  maxLength={150}
                  onChange={(e) => setGiftWrap(e.target.value)}
                />
                Order Notes:
                <FormInput
                  type="textarea"
                  value={notes}
                  name={"notes"}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <hr />
        <CButton color="primary" disabled={loading} onClick={onSubmit}>
          {loading && (
            <CSpinner
              component="span"
              className="mr-2"
              size="sm"
              aria-hidden="true"
            />
          )}
          Place Order
        </CButton>
      </PageContainer>
      <CModal show={productModal} onClose={() => setProductModal(false)}>
        <CModalHeader closeButton>
          <CModalTitle>Add Products</CModalTitle>
        </CModalHeader>
        <Formik
          initialValues={editItem}
          enableReinitialize
          validationSchema={addProductToOrderSchema}
          onSubmit={addEditProduct}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit}>
              <CModalBody>
                <FormInput
                  type="select-async"
                  label={"Product"}
                  loadOptions={searchProducts}
                  name={"product_id"}
                  value={values["product_id"]}
                  onChange={(v) => setFieldValue("product_id", v)}
                />
                <FormInput
                  type="number"
                  label={"Quantity"}
                  name={"quantity"}
                  id={"quantity"}
                  value={values["quantity"]}
                  onChange={handleChange}
                  invalid={errors["quantity"] && touched["quantity"]}
                  error={errors["quantity"]}
                />

                {_get(values, "product_id.data") && (
                  <FormInput
                    type="select"
                    label={"Variant"}
                    name={"variant_details"}
                    value={values["variant_details"]}
                    options={_get(
                      values,
                      "product_id.data.variant_details",
                      []
                    )}
                    getOptionLabel={(v) =>
                      `${v.size ? `Size -` : ""} ${v.size || ""} ${
                        v.quantity ? `, Stock -` : ""
                      } ${v.quantity || ""}`
                    }
                    getOptionValue={(v) => v._id}
                    onChange={(v) => setFieldValue("variant_details", v)}
                  />
                )}
                {_get(values, "product_id.data") &&
                  _get(values, "product_id.data.custom_status") && (
                    <>
                      <FormInput
                        type="switch"
                        label={"Allow Personalization"}
                        name={"custom"}
                        value={values["custom"]}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={errors["custom"] && touched["custom"]}
                        error={errors["custom"]}
                      />
                      {values["custom"] && (
                        <>
                          <FormInput
                            type="text"
                            label={"On Front"}
                            name={"custom_on_front"}
                            placeholder={"Enter data to show on front"}
                            value={values["custom_on_front"]}
                            onChange={handleChange}
                            invalid={
                              errors["custom_on_front"] &&
                              touched["custom_on_front"]
                            }
                            error={errors["custom_on_front"]}
                          />
                          <FormInput
                            type="text"
                            label={"On Back"}
                            name={"custom_on_back"}
                            placeholder={"Enter data to show on back"}
                            value={values["custom_on_back"]}
                            onChange={handleChange}
                            invalid={
                              errors["custom_on_back"] &&
                              touched["custom_on_back"]
                            }
                            error={errors["custom_on_back"]}
                          />
                        </>
                      )}
                    </>
                  )}
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="secondary"
                  onClick={() => setProductModal(false)}
                >
                  cancel
                </CButton>
                <CButton type="submit" color="primary">
                  Save
                </CButton>
              </CModalFooter>
            </CForm>
          )}
        </Formik>
      </CModal>
      <CModal
        show={addressModal ? true : false}
        onClose={() => setAddressModal("")}
      >
        <CModalHeader closeButton>
          <CModalTitle>Update Address</CModalTitle>
        </CModalHeader>
        <Formik
          initialValues={
            addressModal === "shipping_address"
              ? shippingAddress
              : billingAddress
          }
          enableReinitialize
          validationSchema={addressSchema}
          onSubmit={onAddressChange}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit}>
              <CModalBody>
                <Address
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </CModalBody>
              <CModalFooter>
                <CButton
                  color="secondary"
                  onClick={() => setAddressModal(false)}
                >
                  cancel
                </CButton>
                <CButton type="submit" color="primary">
                  Save
                </CButton>
              </CModalFooter>
            </CForm>
          )}
        </Formik>
      </CModal>
    </>
  );
};

export default AddEditOrder;
