import { REQUEST, SUCCESS, FAIL, LOGIN, LOGOUT } from "../actions/actionTypes";
import { REHYDRATE } from "redux-persist";

const initialState = {
  user: { loading: false, error: null, data: null },
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case LOGIN[REQUEST]:
      return { ...state, user: { ...state.user, loading: true, error: null } };

    case LOGIN[FAIL]:
      return { ...state, user: { ...state.user, loading: false, error } };

    case LOGIN[SUCCESS]:
      return { ...state, user: { data, loading: false, error: null } };

    case LOGOUT[SUCCESS]:
      return initialState;

    case REHYDRATE:
      return {
        ...state,
        ...action?.payload?.auth,
      };

    default:
      return state;
  }
};

export default reducer;
