import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { getBadge, confirmModal, hasPermission } from "src/utils/helper";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import { ROUTES } from "src/router/routes";

const type = "sizeGuides";

const SizeGuides = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: subCategoriesList, count: total },
  } = useSelector((state) => state.admin.sizeGuides);
  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
      })
    );
  }, [dispatch, page, message, limitPerPage, searchFilter]);

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(item.status, (status) =>
      dispatch(blockUnblockRequest(type, { size_id: item._id, status }))
    );
  };

  return (
    <PageContainer
      title="Size Guides"
      rightButtons={
        hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE)
          ? [
              {
                label: "Add Size Guide",
                onClick: () =>
                  history.push(`${ROUTES.SIZE_GUIDES}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={2}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setSearch("");
              setSearchFilter("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>

      <CDataTable
        items={subCategoriesList}
        fields={[
          "sr_no",
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "name",
          "createdAt",
        ]}
        hover
        clickableRows
        addTableClasses={"default"}
        loading={loading}
        onRowClick={(item) => {
          if (hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE)) {
            history.push(`${ROUTES.SIZE_GUIDES}${ROUTES.EDIT}`, item);
          }
        }}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD/MM/YYYY HH: MM A")}</td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          actions: (item, index) =>
            hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE) ? (
              <td className="py-2">
                <CButton color="primary" variant="outline" size="sm">
                  <CIcon name="cil-pencil" /> Edit
                </CButton>
                <CButton
                  className={"ml-2"}
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Block" : " Unblock"}
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default SizeGuides;
