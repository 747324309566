import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES } from "./routes";

const PublicRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user.data);
  if (user) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
