import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { getBadge, confirmModal, hasPermission } from "src/utils/helper";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import _get from "lodash/get";
import _map from "lodash/map";
import { MODULES, PERMISSIONS } from "src/utils/constants";
import {
  searchCategories,
  searchSubCategories,
  searchDetailedSubCategories,
} from "src/utils";

const type = "variants";

const Variants = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: variantsList, count: total },
  } = useSelector((state) => state.admin.variants);

  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [detailedSubCategoryFilter, setDetailedSubCategoryFilter] = useState(
    ""
  );
  const [limitPerPage, setLimitPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const totalpage = Math.ceil(total / limitPerPage);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        search: searchFilter,
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        category_id: _map(categoryFilter, "value"),
        sub_category_id: _map(subCategoryFilter, "value"),
        detailed_category_id: _map(detailedSubCategoryFilter, "value"),
      })
    );
  }, [
    dispatch,
    page,
    message,
    limitPerPage,
    searchFilter,
    categoryFilter,
    subCategoryFilter,
    detailedSubCategoryFilter,
  ]);

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const blockUnblock = (e, item) => {
    e.stopPropagation();
    confirmModal(item.status, (status) =>
      dispatch(
        blockUnblockRequest(type, {
          id: item._id,
          status,
        })
      )
    );
  };

  const deleteVariant = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      () =>
        dispatch(
          blockUnblockRequest(type, {
            id: item._id,
            status: 2,
          })
        ),
      "Delete",
      "Delete"
    );
  };

  const resetFilter = () => {
    setSearch("");
    setSearchFilter("");
    setCategoryFilter("");
    setSubCategoryFilter("");
    setDetailedSubCategoryFilter("");
  };

  return (
    <PageContainer
      title="Variants"
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-2"}>
        <CCol>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>

        <CCol>
          Filter by Category
          <FormInput
            type="select-async"
            placeholder="Search"
            isMulti
            loadOptions={searchCategories}
            value={categoryFilter}
            onChange={(v) => setCategoryFilter(v)}
          />
        </CCol>
        <CCol>
          Filter by Sub Category
          <FormInput
            type="select-async"
            placeholder="Search"
            isMulti
            loadOptions={searchSubCategories}
            value={subCategoryFilter}
            onChange={(v) => setSubCategoryFilter(v)}
          />
        </CCol>
        <CCol>
          Filter by Detailed Sub Category
          <FormInput
            type="select-async"
            placeholder="Search"
            loadOptions={searchDetailedSubCategories}
            value={detailedSubCategoryFilter}
            isMulti
            onChange={(v) => setDetailedSubCategoryFilter(v)}
          />
        </CCol>

        <CCol xs={2}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => resetFilter()}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>

      <CDataTable
        items={variantsList}
        fields={[
          "sr_no",
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          "status",
          "product_name",
          "size",
          "quantity",
          "sku",
          "color",
          "price",
        ]}
        hover
        clickableRows
        loading={loading}
        addTableClasses={"default"}
        onRowClick={(item) => {
          if (hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE)) {
            history.push("/variants/edit", item);
          }
        }}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)}>
                {item.status === 1 ? "Active" : "Inactive"}
              </CBadge>
            </td>
          ),
          product_name: (item) => (
            <td>{_get(item, "product_id.item_name", "")}</td>
          ),
          actions: (item) =>
            hasPermission(MODULES.PRODUCTS, PERMISSIONS.WRITE) ? (
              <td className="py-2">
                <CButton
                  className={"ml-2"}
                  color={item.status ? "danger" : "primary"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => blockUnblock(e, item)}
                >
                  <CIcon
                    name={item.status ? "cil-x-circle" : "cil-check-circle"}
                  />
                  {item.status ? " Block" : " Unblock"}
                </CButton>
                <CButton
                  className={"ml-2"}
                  color={"danger"}
                  variant="outline"
                  size="sm"
                  onClick={(e) => deleteVariant(e, item)}
                >
                  <CIcon name={"cil-x"} />
                  Delete
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default Variants;
