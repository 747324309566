import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { taxSchema } from "../../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import { CForm, CButton, CCol, CFormGroup, CSpinner } from "@coreui/react";

const type = "taxes";
const defaultFormValues = {
  tax_label: "",
  state_name: "",
  state_code: "",
  special: false,
  min_amount: "",
  less_than_min_amount: {
    sgst: "",
    cgst: "",
    igst: "",
  },
  greater_than_min_amount: {
    sgst: "",
    cgst: "",
    igst: "",
  },
};

const AddEditTaxes = ({ isEdit, history, location: { state: taxData } }) => {
  if (isEdit && !taxData) {
    history.push("/sub-admins");
  }
  if (isEdit && !taxData.state_name) {
    taxData = { ...taxData, state_name: "", state_code: "" };
  }

  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);

  const onSubmit = (values) => {
    let data = {};
    if (isEdit) {
      data.id = values._id;
      data.tax_label = values.tax_label;
      data.special = values.special;
      data.state_name = values.state_name;
      data.state_code = values.state_code;
      data.min_amount = values.min_amount;
      data.less_than_min_amount = values.less_than_min_amount;
      data.greater_than_min_amount = values.greater_than_min_amount;
    } else data = values;
    if (!data.special) {
      delete data.state_name;
      delete data.state_code;
    }
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer sm={12} title={isEdit ? "Edit Tax" : "Add New Tax"}>
      <Formik
        initialValues={taxData || defaultFormValues}
        validationSchema={taxSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"Tax Label"}
              name={"tax_label"}
              id={"tax_label"}
              value={values["tax_label"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["tax_label"] && touched["tax_label"]}
              error={errors["tax_label"]}
              placeholder={"Enter Tax Label"}
            />
            <FormInput
              type="switch"
              label={"Special"}
              name={"special"}
              id={"special"}
              value={values["special"]}
              onBlur={handleBlur}
              onChange={handleChange}
              invalid={errors["special"] && touched["special"]}
              error={errors["special"]}
            />

            {values["special"] && (
              <>
                <FormInput
                  type="text"
                  label={"State Name"}
                  name={"state_name"}
                  id={"state_name"}
                  value={values["state_name"]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={errors["state_name"] && touched["state_name"]}
                  error={errors["state_name"]}
                  placeholder={"Enter State Name"}
                />
                <FormInput
                  type="text"
                  label={"State Code"}
                  name={"state_code"}
                  id={"state_code"}
                  value={values["state_code"]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={errors["state_code"] && touched["state_code"]}
                  error={errors["state_code"]}
                  placeholder={"Enter State Code"}
                />
              </>
            )}

            <FormInput
              type="number"
              label={"Minimun Amount"}
              name={"min_amount"}
              id={"min_amount"}
              value={values["min_amount"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["min_amount"] && touched["min_amount"]}
              error={errors["min_amount"]}
              placeholder={"Enter Minimun Amount"}
            />

            <CFormGroup row>
              <CCol md="3" className="d-flex align-items-center mb-0">
                <label htmlFor="state_name" className="mb-0">
                  Less than minimum amount
                </label>
              </CCol>
              <CCol>
                <FormInput
                  type="number"
                  label={"Sgst"}
                  name={"less_than_min_amount.sgst"}
                  id={"less_than_min_amount"}
                  value={values["less_than_min_amount"].sgst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    (errors.less_than_min_amount || {}).sgst &&
                    (touched.less_than_min_amount || {}).sgst
                  }
                  error={(errors.less_than_min_amount || {}).sgst}
                />
              </CCol>
              <CCol>
                <FormInput
                  type="number"
                  label={"Cgst"}
                  name={"less_than_min_amount.cgst"}
                  id={"less_than_min_amount"}
                  value={values["less_than_min_amount"].cgst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    (errors.less_than_min_amount || {}).cgst &&
                    (touched.less_than_min_amount || {}).cgst
                  }
                  error={(errors.less_than_min_amount || {}).cgst}
                />
              </CCol>
              <CCol>
                <FormInput
                  type="number"
                  label={"Igst"}
                  name={"less_than_min_amount.igst"}
                  id={"less_than_min_amount"}
                  value={values["less_than_min_amount"].igst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    (errors.less_than_min_amount || {}).igst &&
                    (touched.less_than_min_amount || {}).igst
                  }
                  error={(errors.less_than_min_amount || {}).igst}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3" className="d-flex align-items-center mb-0">
                <label htmlFor="state_name" className="mb-0">
                  Greater than minimum amount
                </label>
              </CCol>
              <CCol>
                <FormInput
                  type="number"
                  label={"Sgst"}
                  name={"greater_than_min_amount.sgst"}
                  id={"greater_than_min_amount"}
                  value={values["greater_than_min_amount"].sgst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    (errors.greater_than_min_amount || {}).sgst &&
                    (touched.greater_than_min_amount || {}).sgst
                  }
                  error={(errors.greater_than_min_amount || {}).sgst}
                />
              </CCol>
              <CCol>
                <FormInput
                  type="number"
                  label={"Cgst"}
                  name={"greater_than_min_amount.cgst"}
                  id={"greater_than_min_amount"}
                  value={values["greater_than_min_amount"].cgst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    (errors.greater_than_min_amount || {}).cgst &&
                    (touched.greater_than_min_amount || {}).cgst
                  }
                  error={(errors.greater_than_min_amount || {}).cgst}
                />
              </CCol>
              <CCol>
                <FormInput
                  type="number"
                  label={"Igst"}
                  name={"greater_than_min_amount.igst"}
                  id={"greater_than_min_amount"}
                  value={values["greater_than_min_amount"].igst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    (errors.greater_than_min_amount || {}).igst &&
                    (touched.greater_than_min_amount || {}).igst
                  }
                  error={(errors.greater_than_min_amount || {}).igst}
                />
              </CCol>
            </CFormGroup>

            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditTaxes;
