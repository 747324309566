import React from "react";
import {
  CRow,
  CBadge,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CDataTable,
} from "@coreui/react";
import _get from "lodash/get";
import {
  getOrderBadge,
  getOrderStatus,
  priceString,
  getAddress,
} from "src/utils/helper";
import Moment from "moment";
import OrderSummary from "./orderSummary";
import CustomerCard from "./customerCard";

const OrderDetails = ({ item }) => {
  return (
    <>
      <CRow>
        <CCol>
          <h4 style={{ color: "blue" }}>
            # {_get(item, "order_id")}
            <CBadge
              className={"ml-2"}
              color={getOrderBadge(_get(item, "order_status"))}
            >
              {getOrderStatus(_get(item, "order_status"))}
            </CBadge>
          </h4>

          <p>
            {Moment(_get(item, "createdAt")).format("DD/MM/YYYY  -  HH:MM A")}
          </p>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={8}>
          <CCard style={{ overflow: "scroll" }}>
            <CCardHeader>
              <CCardTitle className="cardtitle">Products</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                items={_get(item, "product_details_id", [])}
                fields={[
                  "item_name",
                  "quantity",
                  "total_price",
                  "tax",
                  {
                    key: "custom_front",
                    label: "Personalization (F)",
                  },
                  {
                    key: "custom_back",
                    label: "Personalization (B)",
                  },
                  "exchange_status",
                  "return_status",
                ]}
                hover
                sorter
                clickableRows
                // responsive
                addTableClasses={"orders-details-products"}
                scopedSlots={{
                  item_name: (item) => (
                    <td>{`
                                  ${_get(item, "product_id.item_name", "-")}
                                (${_get(item, "size_id.sku", "-")}) - ${_get(
                      item,
                      "size_id.size",
                      "-"
                    )}`}</td>
                  ),

                  total_price: (item) => (
                    <td>{priceString(_get(item, "total_price", 0))}</td>
                  ),
                  tax: (item) => (
                    <td>
                      {_get(item, "CGST.cgst")
                        ? `CGST - ${priceString(_get(item, "CGST.cgst", 0))}
                                   (${_get(item, "CGST.cgst_percentage", 0)}%)`
                        : ""} &nbsp;

                      {_get(item, "SGST.sgst")
                        ? `SGST - ${priceString(_get(item, "SGST.sgst", 0))}
                                   (${_get(item, "SGST.sgst_percentage", 0)}%)`
                        : ""}

                      {_get(item, "IGST.igst")
                        ? `IGST -  ${priceString(_get(item, "IGST.igst", 0))}
                                   (${_get(item, "IGST.igst_percentage", 0)}%)`
                        : ""}
                    </td>
                  ),

                  custom_front: (item) => (
                    <td>{`${_get(item, "custom_on_front", "")} -
                          ${
                            _get(item, "custom_on_front_price", 0) > 0
                              ? priceString(
                                  _get(item, "custom_on_front_price", 0)
                                )
                              : ""
                          }`}</td>
                  ),
                  custom_back: (item) => (
                    <td>{`${_get(item, "custom_on_back", "")} -
                          ${
                            _get(item, "custom_on_back_price", 0) > 0
                              ? priceString(
                                  _get(item, "custom_on_back_price", 0)
                                )
                              : ""
                          }`}</td>
                  ),
                  exchange_status: (item) => (
                    <td>
                      {_get(item, "exchange_status") ? (
                        <CBadge color="warning">EXCHANGE</CBadge>
                      ) : (
                        "-"
                      )}
                    </td>
                  ),
                  return_status: (item) => (
                    <td>
                      {_get(item, "return_status") ? (
                        <CBadge color="danger">RETURN</CBadge>
                      ) : (
                        "-"
                      )}
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={5}>
          <OrderSummary order={item} />
          <CCard>
            <CCardHeader>
              <CCardTitle className="cardtitle">Metadata</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <p>
                Gift Message:
                <span className="txt">
                  {` ${_get(item, "gift_wrap_notes", "-") || "-"}`}
                </span>
              </p>
              <p>
                Order Notes:
                <span className="txt">
                  {` ${_get(item, "order_notes", "-") || "-"}`}
                </span>
              </p>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={3}>
          <CustomerCard order={item} />
          <CCard>
            <CCardHeader>
              <CCardTitle className="cardtitle">Shipping Address:</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <span className="txt" style={{ whiteSpace: "pre-line" }}>
                {getAddress(item.shipping_address)}
              </span>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default OrderDetails;
