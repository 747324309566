import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { couponSchema } from "../../../config/formSchema";
import { addEditRequest } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import moment from "moment";
import _get from "lodash/get";
import _map from "lodash/map";
import _size from "lodash/size";
import { amountTypeDropdown } from "src/utils/constants";
import { filterDropdownData, modifyDropDownValues } from "src/utils/helper";
import { searchCategories, searchProducts } from "src/utils";

const type = "coupons";
const defaultFormValues = {
  category_not_included: [],
  product_not_included: [],
  type: "",
  min_amount: 500,
  limit_count: 1,
  user_limit_count: 1,
  coupan_code: "",
  coupan_amount: 0,
  start_date: "",
  end_date: "",
  apply_on_sale: false,
};

const AddEditCoupon = ({ isEdit, history, location: { state } }) => {
  if (isEdit && !state) {
    history.push("/sub-categories");
  }
  const dispatch = useDispatch();

  const { loading } = useSelector((s) => s.admin.modal);
  const coupon = isEdit
    ? {
        ...state,
        type: amountTypeDropdown.find((v) => v.value === state.type),
        start_date: moment(state.start_date).format("YYYY-MM-DD"),
        end_date: moment(state.end_date).format("YYYY-MM-DD"),
        product_not_included: filterDropdownData(
          _get(state, "product_not_included", []),
          ["category_id.category_name", "item_name", "product_sku"]
        ),
        category_not_included: modifyDropDownValues(
          _get(state, "category_not_included", []),
          "category_name",
          "_id"
        ),
      }
    : defaultFormValues;

  const onSubmit = (values) => {
    let data = {
      ...values,
      type: values.type.value,
      start_date: new Date(values.start_date).toISOString(),
      end_date: new Date(values.end_date).toISOString(),
      product_not_included: _size(values.product_not_included)
        ? _map(values.product_not_included, "value")
        : [],
      category_not_included: _size(values.category_not_included)
        ? _map(values.category_not_included, "value")
        : [],
    };
    if (isEdit) {
      data.id = values._id;
      delete data.status;
      delete data._id;
    }
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer
      lg={10}
      title={isEdit ? "Edit Coupon" : "Add Coupon"}
      loading={loading}
    >
      <Formik
        initialValues={coupon}
        validationSchema={couponSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"Coupon Code"}
              name={"coupan_code"}
              value={values["coupan_code"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["coupan_code"] && touched["coupan_code"]}
              error={errors["coupan_code"]}
              placeholder={"Enter Coupon Code"}
            />
            <FormInput
              type="select-async"
              label={"Exclude Categories"}
              placeholder="Search Category"
              isMulti
              loadOptions={searchCategories}
              value={values["category_not_included"]}
              onChange={(v) => setFieldValue("category_not_included", v)}
            />
            <FormInput
              type="select-async"
              label={"Exclude Products"}
              placeholder="Search Products"
              isMulti
              loadOptions={searchProducts}
              value={values["product_not_included"]}
              onChange={(v) => setFieldValue("product_not_included", v)}
            />
            <FormInput
              type="number"
              label={"Coupon Amount"}
              name={"coupan_amount"}
              value={values["coupan_amount"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["coupan_amount"] && touched["coupan_amount"]}
              error={errors["coupan_amount"]}
              placeholder={"Enter Coupon Amount Code"}
            />

            <FormInput
              type="select"
              label={"Coupon Discount Type"}
              options={amountTypeDropdown}
              value={values["type"]}
              onChange={(v) => setFieldValue("type", v)}
              onBlur={handleBlur}
              invalid={errors["type"] && touched["type"]}
              error={errors["type"]}
            />

            <FormInput
              type="number"
              label={"Minimum Cart Value"}
              name={"min_amount"}
              value={values["min_amount"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["min_amount"] && touched["min_amount"]}
              error={errors["min_amount"]}
              placeholder={"Enter Min. Cart Value"}
            />
            <FormInput
              type="number"
              label={"Coupon Limit Count"}
              name={"limit_count"}
              value={values["limit_count"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["limit_count"] && touched["limit_count"]}
              error={errors["limit_count"]}
              placeholder={"Enter Coupon Limit Count"}
            />
            <FormInput
              type="number"
              label={"Coupon limit count per user"}
              name={"user_limit_count"}
              value={values["user_limit_count"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                errors["user_limit_count"] && touched["user_limit_count"]
              }
              error={errors["user_limit_count"]}
              placeholder={"Enter Coupon limit count per user"}
            />
            <FormInput
              type="date"
              label={"Coupon Start Date"}
              name={"start_date"}
              value={values["start_date"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["start_date"] && touched["start_date"]}
              error={errors["start_date"]}
              placeholder={"Enter Start Date"}
            />
            <FormInput
              type="date"
              label={"Coupon End Date"}
              name={"end_date"}
              value={values["end_date"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["end_date"] && touched["end_date"]}
              error={errors["end_date"]}
              placeholder={"Enter End Date"}
            />
            <FormInput
              type="switch"
              label={"Apply on Sale"}
              name={"apply_on_sale"}
              value={values["apply_on_sale"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["apply_on_sale"] && touched["apply_on_sale"]}
              error={errors["apply_on_sale"]}
            />
            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditCoupon;
