export const UserApis = {
  LIST_USERS: "/admin/admin_user_list",
};

export const ProductApis = {
  LIST_PRODUCTS: "/admin/list_product",
};

export const CategoriesApis = {
  LIST_CATEGORIES: "/admin/list_all_category",
  LIST_SUB_CATEGORIES: "/admin/list_sub_category",
  LIST_DETAILED_SUB_CATEGORIES: "/admin/list_detailed_sub_category",
};

export const TagsApis = {
  LIST_TAGS: "/admin/list_tags",
};

export const SizeGuidesApis = {
  LIST_SIZE_GUIDES: "/admin/list_size_guides",
};

export const CouponsApis = {
  LIST_COUPONS: "/admin/list_coupan",
};
