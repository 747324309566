import React from "react";
import {
  CForm,
  // CCard,
  // CCardBody,
  // CCardHeader,
  CButton,
  // CCol,
  // CRow,
  CSpinner,
} from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import { useSelector, useDispatch } from "react-redux";
import { addEditRequest } from "src/store/actions/admin";
import PageContainer from "src/components/PageContainer";
import { tagSchema } from "../../../config/formSchema";

const defaultValues = {
  tag_name: "",
};
const type = "tags";
const Variant = ({ isEdit, history, location: { state: tag } }) => {
  if (isEdit && !tag) {
    history.push("/tags");
  }
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);

  const onSubmit = (values) => {
    const data = {
      tag_name: values.tag_name,
    };
    if (isEdit) {
      data.id = values._id;
    }
    dispatch(addEditRequest(type, data, history));
  };

  return (
    <PageContainer sm={6} title={isEdit ? "Edit Tag" : "Add Tag"}>
      <Formik
        initialValues={isEdit ? tag : defaultValues}
        validationSchema={tagSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="text"
              label={"Name"}
              name={"tag_name"}
              value={values["tag_name"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors["tag_name"] && touched["tag_name"]}
              error={errors["tag_name"]}
              placeholder={"Enter Tag Name"}
            />
            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default Variant;
