import React, { useState, useEffect } from "react";
import { CButton, CBadge, CDataTable, CInput, CRow, CCol } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { getBadge, priceString, hasPermission } from "src/utils/helper";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import { confirmAlert } from "react-confirm-alert";
import FormInput from "src/components/FormInput";
import _get from "lodash/get";
import { searchUsers } from "src/utils";

const type = "exchangeReturns";

const exchangeStatusDropdown = [
  { label: "NEW REQUEST", value: 0 },
  { label: "PROCESSING", value: 2 },
  { label: "REJECTED", value: 3 },
  { label: "COMPLETED", value: 4 },
];
const returnStatusDropdown = [
  { label: "NEW REQUEST", value: 0 },
  { label: "PROCESSING", value: 1 },
  { label: "REJECTED", value: 3 },
  { label: "COMPLETED", value: 4 },
];

const ExchangeReturns = ({ history, isExchange }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: exchangeReturnsList, count: total },
  } = useSelector((state) => state.admin.exchangeReturns);
  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [userFilter, setUserFilter] = useState({
    label: "Search User",
    value: "",
  });
  const [statusFilter, setStatusFilter] = useState({
    label: "Select Status",
    value: "",
  });
  const [page, setPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(total / limitPerPage);

  const writeAccess = hasPermission(MODULES.ORDERS, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
        type: isExchange ? 0 : 1,
        user_id: userFilter.value || "",
        status: _get(statusFilter, "value", ""),
      })
    );
    setSelectedItems([]);
  }, [
    dispatch,
    isExchange,
    page,
    message,
    userFilter,
    statusFilter,
    limitPerPage,
    searchFilter,
  ]);

  const acceptReject = (e, { _id }, accept) => {
    e.stopPropagation();
    const status = isExchange ? (accept ? 2 : 3) : accept ? 1 : 3;
    confirmAlert({
      title: `${accept ? "Accept" : "Reject"} ${
        isExchange ? "Exchange" : "Return"
      }`,
      message: `Are you sure you want to ${
        accept ? "accept" : "reject"
      } this request?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              blockUnblockRequest(type, {
                request_id: _id,
                status: status,
              })
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const completeReturn = (e, { _id }) => {
    e.stopPropagation();
    confirmAlert({
      title: "Complete Return",
      message: `Are you sure you want to complete this request?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              blockUnblockRequest(type, {
                request_id: _id,
                status: 4,
              })
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  //search handler
  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const resetFilter = () => {
    setSearch("");
    setSearchFilter("");
    setUserFilter("");
    setStatusFilter("");
  };

  const selectRow = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems((v) => [...v, item._id]);
    } else {
      setSelectedItems((v) => v.filter((i) => i !== item._id));
    }
  };

  const multiRowsSelect = (e, item, index) => {
    if (e.target.checked === true) {
      setSelectedItems(exchangeReturnsList.map((v) => v._id));
    } else {
      setSelectedItems([]);
    }
  };

  const bulkActions = () => {
    confirmAlert({
      title: <h3>Are you sure you want to Delete?</h3>,
      buttons: [
        {
          label: "Yes",
          className: "bg-danger",
          onClick: () =>
            dispatch(
              blockUnblockRequest(type, {
                request_ids: selectedItems,
                is_deleted: true,
              })
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <PageContainer
      title={isExchange ? "Exchanges" : "Returns"}
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={3}>
          Filter by User
          <FormInput
            type="select-async"
            loadOptions={searchUsers}
            value={userFilter}
            onChange={(v) => setUserFilter(v)}
          />
        </CCol>
        <CCol sm={3}>
          Filter by Status
          <FormInput
            type="select"
            options={isExchange ? exchangeStatusDropdown : returnStatusDropdown}
            value={statusFilter}
            onChange={(v) => setStatusFilter(v)}
          />
        </CCol>
        <CCol sm={2}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={resetFilter}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      {!!selectedItems.length && (
        <CRow className={"mt-0 mb-3"}>
          <CCol>
            <CButton
              color="danger"
              variant="outline"
              className="resetButton ml-2"
              onClick={bulkActions}
            >
              Delete
            </CButton>
          </CCol>
        </CRow>
      )}

      <CDataTable
        items={exchangeReturnsList}
        fields={[
          {
            key: "select",
            sorter: false,
            filter: false,
            label: writeAccess ? (
              <input
                type="checkbox"
                className="multiSelect"
                onChange={multiRowsSelect}
              />
            ) : (
              "#"
            ),
          },
          {
            key: "actions",
            label: "Actions",
            sorter: false,
            filter: false,
          },
          { key: "unique_id", label: isExchange ? "Exchange Id" : "Return Id" },
          "status",
          "parent_order_id",
          "initiated_by",
          "user_name",
          "email",
          "product_name",
          "quantity",
          "size_purchased",
          "exchange_with",
          "createdAt",
        ]}
        hover
        clickableRows
        loading={loading}
        addTableClasses={"exchange-return"}
        scopedSlots={{
          select: (item, index) =>
            writeAccess ? (
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="multiSelect"
                  checked={selectedItems.includes(item._id)}
                  onChange={(e) => selectRow(e, item, index)}
                />
              </td>
            ) : (
              <td>{limitPerPage * (page - 1) + (index + 1)}</td>
            ),
          createdAt: (item) => (
            <td>{Moment(item.createdAt).format("DD/MM/YYYY hh:mm a")}</td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.request_status_by_admin)}>
                {item.request_status_by_admin === 4
                  ? "Completed"
                  : item.request_status_by_admin === 3
                  ? "Rejected"
                  : item.request_status_by_admin === 0
                  ? "New Request"
                  : "Processing"}
              </CBadge>
            </td>
          ),
          parent_order_id: (item) => (
            <td>{_get(item, "order_id.order_id", "")}</td>
          ),
          exchange_id: (item) => <td>{_get(item, "id", "")}</td>,
          return_id: (item) => <td>{_get(item, "id", "")}</td>,
          product_name: (item) => (
            <td>{_get(item, "product_id.item_name", "")}</td>
          ),
          unique_id: (item) => <td>{_get(item, "unique_id", "-")}</td>,
          quantity: (item) => <td>{_get(item, "quantity", "")}</td>,
          product_price: (item) => (
            <td>{priceString(_get(item, "product_id.price", ""))}</td>
          ),
          initiated_by: (item) => (
            <td>{_get(item, "user_id.first_name") ? "BY USER" : "BY ADMIN"}</td>
          ),
          user_name: (item) => (
            <td>{`${_get(item, "user_id.first_name", "ADMIN")} ${_get(
              item,
              "user_id.last_name",
              ""
            )}`}</td>
          ),
          email: (item) => (
            <td>{`${_get(item, "user_id.email_address", "")}`}</td>
          ),
          size_purchased: (item) => (
            <td>{`${_get(item, "size_id.size", "")}`}</td>
          ),
          exchange_with: (item) => (
            <td>{`${_get(item, "requested_size_id.size", "")}`}</td>
          ),
          actions: (item) =>
            writeAccess ? (
              <td className="py-2">
                {(item.request_status_by_admin === 1 ||
                  item.request_status_by_admin === 2) && (
                  <CButton
                    color={"success"}
                    variant="outline"
                    size="sm"
                    onClick={(e) => completeReturn(e, item)}
                  >
                    <CIcon name={"cil-check-circle"} />
                    {" Completed"}
                  </CButton>
                )}

                {item.request_status_by_admin !== 4 &&
                  !(
                    item.request_status_by_admin === 1 ||
                    item.request_status_by_admin === 2
                  ) && (
                    <CButton
                      color="success"
                      variant="outline"
                      size="sm"
                      onClick={(e) => acceptReject(e, item, true)}
                    >
                      <CIcon name="cil-check-circle" />
                      {" Processing"}
                    </CButton>
                  )}

                {item.request_status_by_admin !== 4 && (
                  <CButton
                    className={"ml-2"}
                    color={"danger"}
                    variant="outline"
                    size="sm"
                    onClick={(e) => acceptReject(e, item)}
                  >
                    <CIcon name={"cil-x-circle"} />
                    {" Reject"}
                  </CButton>
                )}

                {item.request_status_by_admin === 4 && "-"}
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default ExchangeReturns;
