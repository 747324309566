import React, { useEffect } from "react";
import { addEditRequest, fetchList } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import _get from "lodash/get";
import { hasPermission } from "src/utils/helper";
import { MODULES, PERMISSIONS } from "src/utils/constants";

const type = "settings";

const Defaults = ({ history }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((s) => s.admin.modal);
  const {
    data: { data },
    loading,
  } = useSelector((s) => s.admin.settings);

  useEffect(() => {
    dispatch(fetchList(type));
  }, [dispatch]);

  const onSubmit = ({
    cash_free,
    gift_wrap,
    cod_enable,
    free_shipping,
    free_shipping_criteria,
    email_list,
  }) => {
    dispatch(
      addEditRequest(type, {
        cash_free,
        gift_wrap,
        cod_enable,
        free_shipping,
        free_shipping_criteria,
        email_list,
      })
    );
  };

  return (
    <PageContainer lg={8} title={"Edit Default Settings"}>
      {!data ? (
        <CSpinner
          component="span"
          className="mr-2"
          size="sm"
          aria-hidden="true"
        />
      ) : (
        <Formik initialValues={data} onSubmit={onSubmit} enableReinitialize>
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit}>
              <FormInput
                type="text"
                label={"Email list (Comma Separated)"}
                name={"email_list"}
                id={"email_list"}
                value={values["email_list"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["email_list"] && touched["email_list"]}
                error={errors["email_list"]}
                placeholder={"Enter Email List (Comma Separated)"}
              />
              <FormInput
                type="number"
                label={"Gift Wrap amount"}
                name={"gift_wrap"}
                id={"gift_wrap"}
                value={values["gift_wrap"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["gift_wrap"] && touched["gift_wrap"]}
                error={errors["gift_wrap"]}
                placeholder={"Enter Gift Wrap"}
              />
              <FormInput
                type="number"
                label={"Free Shipping min. order"}
                name={"free_shipping_criteria"}
                id={"free_shipping_criteria"}
                value={values["free_shipping_criteria"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  errors["free_shipping_criteria"] &&
                  touched["free_shipping_criteria"]
                }
                error={errors["free_shipping_criteria"]}
                placeholder={"Enter Shipping Criteria"}
              />
              <FormInput
                type="number"
                label={"Flat Shipping Charges"}
                name={"free_shipping"}
                id={"free_shipping"}
                value={values["free_shipping"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["free_shipping"] && touched["free_shipping"]}
                error={errors["free_shipping"]}
                placeholder={"Enter Free Shipping"}
              />
              <FormInput
                type="number"
                label={"COD Fee Amount"}
                name={"cash_free"}
                id={"cash_free"}
                value={values["cash_free"]}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors["cash_free"] && touched["cash_free"]}
                error={errors["cash_free"]}
                placeholder={"Enter COD Fee amount"}
              />
              <FormInput
                type="switch"
                label={"COD Enable/Disable"}
                value={_get(values, "cod_enable", false)}
                name={"cod_enable"}
                onChange={handleChange}
              />

              <hr />
              {!!hasPermission(MODULES.SETTINGS, PERMISSIONS.WRITE) && (
                <CButton
                  type="submit"
                  color="primary"
                  disabled={!dirty || loading}
                >
                  {isLoading && (
                    <CSpinner
                      component="span"
                      className="mr-2"
                      size="sm"
                      aria-hidden="true"
                    />
                  )}
                  Submit
                </CButton>
              )}
            </CForm>
          )}
        </Formik>
      )}
    </PageContainer>
  );
};

export default Defaults;
