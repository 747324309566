import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { detailedSubCategorySchema } from "../../../config/formSchema";
import { addEditRequest, fetchList } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import FormInput from "src/components/FormInput";
import _get from "lodash/get";
import moment from "moment";
import { searchCategories } from "src/utils";

const type = "detailedSubCategories";
const defaultFormValues = {
  detailed_sub_category_name: "",
  detailed_sub_category_slug: "",
  category_id: "",
  sub_category_id: "",
  createdAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
};

const AddEditDetailedSubCategory = ({
  isEdit,
  history,
  location: { state },
}) => {
  if (isEdit && !state) {
    history.push("/sub-categories");
  }
  const dispatch = useDispatch();

  const subCategories = useSelector((s) => s.admin.subCategories.data.data);
  const { loading } = useSelector((s) => s.admin.modal);
  const subCategoriesList = [];

  const deatiledSubCategory = isEdit
    ? {
        ...state,
        category_id: {
          label: _get(state, "category_id.category_name", ""),
          value: _get(state, "category_id._id", ""),
        },
        sub_category_id: {
          label: _get(state, "sub_category_id.sub_category_name", ""),
          value: _get(state, "sub_category_id._id", ""),
        },
        createdAt: moment(_get(state, "createdAt")).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      }
    : defaultFormValues;

  const onSubmit = (values) => {
    let data = {
      detailed_sub_category_name: _get(values, "detailed_sub_category_name"),
      detailed_sub_category_slug: _get(values, "detailed_sub_category_slug"),
      category_id: _get(values, "category_id.value"),
      sub_category_id: _get(values, "sub_category_id.value"),
      createdAt: moment(_get(values, "createdAt")).format(),
    };
    if (isEdit) {
      data.id = values._id;
    }
    dispatch(addEditRequest(type, data, history));
  };

  for (let i = 0; i < subCategories.length; i++) {
    subCategoriesList.push({
      label: subCategories[i].sub_category_name,
      value: subCategories[i]._id,
    });
  }

  return (
    <PageContainer
      lg={8}
      title={
        isEdit ? "Edit Detailed Sub Category" : "Add Detailed Sub Category"
      }
      loading={loading}
    >
      <Formik
        initialValues={deatiledSubCategory}
        validationSchema={detailedSubCategorySchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <CForm onSubmit={handleSubmit}>
            <FormInput
              type="select-async"
              label="Category"
              placeholder="Search Category"
              loadOptions={searchCategories}
              value={values["category_id"]}
              onChange={(v) => {
                setFieldValue("category_id", v);
                dispatch(fetchList("subCategories", { id: v.value }));
              }}
              invalid={errors["category_id"] && touched["category_id"]}
              error={errors["category_id"]}
            />
            <FormInput
              type="select"
              label={"Sub Category"}
              options={subCategoriesList}
              value={values["sub_category_id"]}
              onChange={(v) => setFieldValue("sub_category_id", v)}
              invalid={errors["sub_category_id"] && touched["sub_category_id"]}
              error={errors["sub_category_id"]}
            />
            <FormInput
              type="text"
              label={"Name"}
              name={"detailed_sub_category_name"}
              value={values["detailed_sub_category_name"]}
              onChange={(e) => {
                handleChange(e);
                setFieldValue(
                  "detailed_sub_category_slug",
                  e.target.value
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/[^\w-]+/g, "")
                );
              }}
              onBlur={handleBlur}
              invalid={
                errors["detailed_sub_category_name"] &&
                touched["detailed_sub_category_name"]
              }
              error={errors["detailed_sub_category_name"]}
              placeholder={"Enter Detailed Sub Category Name"}
            />
            <FormInput
              type="text"
              label={"Slug"}
              name={"detailed_sub_category_slug"}
              value={values["detailed_sub_category_slug"]}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                errors["detailed_sub_category_slug"] &&
                touched["detailed_sub_category_slug"]
              }
              error={errors["detailed_sub_category_slug"]}
              placeholder={"Enter Detailed Sub Category Slug"}
            />
            <FormInput
              type="datetime-local"
              label={"Created At"}
              name={"createdAt"}
              value={values["createdAt"]}
              onChange={handleChange}
              invalid={errors["createdAt"] && touched["createdAt"]}
              error={errors["createdAt"]}
              placeholder={"Created At"}
            />
            <hr />
            <CButton type="submit" color="primary" disabled={!dirty || loading}>
              {loading && (
                <CSpinner
                  component="span"
                  className="mr-2"
                  size="sm"
                  aria-hidden="true"
                />
              )}
              Submit
            </CButton>
          </CForm>
        )}
      </Formik>
    </PageContainer>
  );
};

export default AddEditDetailedSubCategory;
