import React, { useState, useEffect } from "react";
import { CButton, CDataTable, CRow, CCol, CInput } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { fetchList, blockUnblockRequest } from "src/store/actions/admin";
import { confirmModal, priceString, hasPermission } from "src/utils/helper";
import { defaultLimitPerPage, MODULES, PERMISSIONS } from "src/utils/constants";
import PageContainer from "src/components/PageContainer";
import { ROUTES } from "src/router/routes";

const type = "taxes";

const Taxes = ({ history }) => {
  const dispatch = useDispatch();
  const {
    loading,
    data: { data: taxesList, count },
  } = useSelector((state) => state.admin.taxes);
  const message = useSelector((state) => state.admin.modal);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(defaultLimitPerPage);
  const totalpage = Math.ceil(count / limitPerPage);

  const writeAccess = hasPermission(MODULES.SETTINGS, PERMISSIONS.WRITE);

  const pageChange = (newPage) => {
    if (newPage !== 0) setPage(newPage);
  };

  useEffect(() => {
    dispatch(
      fetchList(type, {
        skip: (page - 1) * limitPerPage,
        limit: limitPerPage,
        search: searchFilter,
      })
    );
  }, [dispatch, message, page, limitPerPage, searchFilter]);

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchFilter(search);
  };

  const deleteTax = (e, item) => {
    e.stopPropagation();
    confirmModal(
      item.status,
      () =>
        dispatch(blockUnblockRequest(type, { tax_id: item._id, status: 2 })),
      "delete",
      "delete"
    );
  };

  return (
    <PageContainer
      title="Tax Slabs"
      rightButtons={
        writeAccess
          ? [
              {
                label: "Add Tax",
                onClick: () => history.push(`${ROUTES.TAX_SLABS}${ROUTES.ADD}`),
              },
            ]
          : []
      }
      pagination={{
        activePage: page,
        onActivePageChange: pageChange,
        pages: totalpage,
        setLimitPerPage,
      }}
    >
      <CRow className={"mb-3"}>
        <CCol sm={3}>
          <form onSubmit={searchHandler}>
            Search
            <CInput
              placeholder="press enter to search.."
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </CCol>
        <CCol sm={1}>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            className="resetButton"
            onClick={() => {
              setSearch("");
              setSearchFilter("");
            }}
          >
            Reset
          </CButton>
        </CCol>
      </CRow>
      <CDataTable
        items={taxesList}
        fields={[
          "sr_no",
          "actions",
          "tax_label",
          "min_amount",
          "less_than_amount",
          "greater_than_amount",
          "state_name",
          "state_code",
          "special",
        ]}
        hover
        sorter
        clickableRows
        responsive
        loading={loading}
        addTableClasses={"taxes"}
        onRowClick={(item) => {
          if (writeAccess) {
            history.push(`${ROUTES.TAX_SLABS}${ROUTES.EDIT}`, item);
          }
        }}
        scopedSlots={{
          sr_no: (_, index) => (
            <td>{limitPerPage * (page - 1) + (index + 1)}</td>
          ),
          state_name: (item) => <td>{item.state_name || "-"}</td>,
          state_code: (item) => <td>{item.state_code || "-"}</td>,
          min_amount: (item) => <td>{priceString(item.min_amount)}</td>,
          less_than_amount: ({
            less_than_min_amount: { sgst, cgst, igst },
          }) => <td>{`Sgst: ${sgst} | Cgst: ${cgst} | Igst: ${igst}`}</td>,

          greater_than_amount: ({
            greater_than_min_amount: { sgst, cgst, igst },
          }) => <td>{`Sgst: ${sgst} | Cgst: ${cgst} | Igst: ${igst}`}</td>,
          actions: (item, index) =>
            writeAccess ? (
              <td className="py-2">
                <CButton
                  className={"ml-2"}
                  color="danger"
                  variant="outline"
                  size="sm"
                  onClick={(e) => deleteTax(e, item)}
                >
                  <CIcon name="cil-x" />
                  Delete
                </CButton>
              </td>
            ) : (
              <td>-</td>
            ),
        }}
      />
    </PageContainer>
  );
};

export default Taxes;
