import React, { useEffect } from "react";
import { addEditRequest, fetchList } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import { CForm, CButton, CSpinner } from "@coreui/react";
import { Formik } from "formik";
import _get from "lodash/get";
import { hasPermission } from "src/utils/helper";
import { MODULES, PERMISSIONS } from "src/utils/constants";

const type = "settings";

const Content = ({ history }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((s) => s.admin.modal);
  const {
    data: { data },
    loading,
  } = useSelector((s) => s.admin.settings);

  useEffect(() => {
    dispatch(fetchList(type));
  }, [dispatch]);

  const onSubmit = ({
    legal_disclaimer,
    privacy_policy,
    terms_and_condition,
    ship_return,
    personalisation,
    story,
    site_notice,
    site_notice_enable,
  }) => {
    dispatch(
      addEditRequest(type, {
        legal_disclaimer,
        privacy_policy,
        terms_and_condition,
        ship_return,
        personalisation,
        story,
        site_notice,
        site_notice_enable,
      })
    );
  };

  return (
    <Formik initialValues={data} onSubmit={onSubmit} enableReinitialize>
      {({ values, dirty, handleChange, handleSubmit, setFieldValue }) => (
        <CForm onSubmit={handleSubmit}>
          <PageContainer
            lg={12}
            loading={loading}
            title={"Edit Content"}
            rightButtons={
              hasPermission(MODULES.SETTINGS, PERMISSIONS.WRITE)
                ? [
                    {
                      label: "Submit",
                      type: "submit",
                      onClick: () => {},
                      disabled: !dirty || isLoading,
                    },
                  ]
                : []
            }
          >
            {!data ? (
              <CSpinner
                component="span"
                className="mr-2"
                size="sm"
                aria-hidden="true"
              />
            ) : (
              <>
                <FormInput
                  type="switch"
                  label={"Site Notice Enable/Disable"}
                  value={_get(values, "site_notice_enable", false)}
                  name={"site_notice_enable"}
                  onChange={(e) =>
                    setFieldValue("site_notice_enable", e.target.checked)
                  }
                />

                <FormInput
                  type="html-editor"
                  label={"Site Notice"}
                  name={"site_notice"}
                  value={values["site_notice"]}
                  onChange={(v) => setFieldValue("site_notice", v)}
                />
                <FormInput
                  type="html-editor"
                  label={"Terms and Conditions"}
                  name={"terms_and_condition"}
                  value={values["terms_and_condition"]}
                  onChange={(v) => setFieldValue("terms_and_condition", v)}
                />
                <FormInput
                  type="html-editor"
                  label={"Privacy Policy"}
                  name={"privacy_policy"}
                  value={values["privacy_policy"]}
                  onChange={(v) => setFieldValue("privacy_policy", v)}
                />
                <FormInput
                  type="html-editor"
                  label={"Product Safety"}
                  name={"legal_disclaimer"}
                  value={values["legal_disclaimer"]}
                  onChange={(v) => setFieldValue("legal_disclaimer", v)}
                />
                <FormInput
                  type="html-editor"
                  label={"Personalisation"}
                  name={"personalisation"}
                  value={values["personalisation"]}
                  onChange={(v) => setFieldValue("personalisation", v)}
                />
                <FormInput
                  type="html-editor"
                  label={"Our Story"}
                  name={"story"}
                  value={values["story"]}
                  onChange={(v) => setFieldValue("story", v)}
                />
                <FormInput
                  type="html-editor"
                  label={"Shipping,Exchange & Returns"}
                  name={"ship_return"}
                  value={values["ship_return"]}
                  onChange={(v) => setFieldValue("ship_return", v)}
                />
                <hr />
                <CButton
                  type="submit"
                  color="primary"
                  disabled={!dirty || isLoading}
                >
                  {isLoading && (
                    <CSpinner
                      component="span"
                      className="mr-2"
                      size="sm"
                      aria-hidden="true"
                    />
                  )}
                  Submit
                </CButton>
              </>
            )}
          </PageContainer>
        </CForm>
      )}
    </Formik>
  );
};

export default Content;
