import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CInvalidFeedback,
  CSpinner,
  CAlert,
  CModal,
  CModalHeader,
  CModalBody,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Formik } from "formik";
import { loginFields, loginSchema } from "src/config/formSchema";
import { useDispatch, useSelector } from "react-redux";
import { login } from "src/store/actions/auth";
import Logo from "../../assets/icons/main-logo.svg";
import FormInput from "src/components/FormInput";
import { addEditRequest } from "src/store/actions/admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = (props) => {
  if (localStorage.getItem("userData")) {
    props.history.push("/dashboard");
  }

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.user.loading);
  const { loading, message } = useSelector((state) => state.admin.modal);
  const error = useSelector((state) => state.auth.user.error);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (message) setModal(false);
  }, [message]);

  const loginHandler = (data) => {
    dispatch(login(data.email, data.pass));
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    dispatch(addEditRequest("forgotPassword", { email: e.target[0].value }));
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Formik
                    initialValues={{ email: "", pass: "" }}
                    validationSchema={loginSchema}
                    onSubmit={loginHandler}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <CForm onSubmit={handleSubmit}>
                        <h2>Admin Login</h2>
                        <p className="text-muted">Sign In to your account</p>
                        {loginFields.map((field, index) => (
                          <CInputGroup className="mb-3" key={index}>
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name={field.icon} />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              name={field.name}
                              type={field.type}
                              value={values[field.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={field.placeholder}
                              invalid={
                                errors[field.name] && touched[field.name]
                                  ? true
                                  : false
                              }
                            />
                            <CInvalidFeedback className="help-block">
                              {errors[field.name] &&
                                touched[field.name] &&
                                errors[field.name]}
                            </CInvalidFeedback>
                          </CInputGroup>
                        ))}

                        {error && <CAlert color="danger">{error}</CAlert>}

                        <CRow>
                          <CCol xs="6">
                            {isLoading ? (
                              <CSpinner color="primary" className="ml-4" />
                            ) : (
                              <CButton
                                color="primary"
                                type="submit"
                                className="px-4"
                              >
                                Login
                              </CButton>
                            )}
                          </CCol>
                          <CCol xs="6" className="text-right">
                            <CButton
                              color="link"
                              className="px-0"
                              onClick={() => setModal(true)}
                            >
                              Forgot password?
                            </CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    )}
                  </Formik>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <img src={Logo} alt="logo" />
                  <p>Clothes and fabric manufacturer</p>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <CModal show={modal} onClose={() => setModal(false)}>
        <CModalHeader closeButton>Forgot Password</CModalHeader>
        <CModalBody>
          <CForm onSubmit={forgotPassword}>
            <FormInput
              type="email"
              label="Email"
              required
              placeholder="Enter email address"
            />
            <CButton type="submit" color="primary">
              {loading && (
                <CSpinner size="sm" color="light" className={"mr-2"} />
              )}
              Submit
            </CButton>
          </CForm>
        </CModalBody>
      </CModal>
      <ToastContainer autoClose={5000} />
    </div>
  );
};

export default Login;
