import React, { useEffect, useState } from "react";
import { addEditRequest, fetchList } from "src/store/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "src/components/PageContainer";
import FormInput from "src/components/FormInput";
import {
  CForm,
  CButton,
  CSpinner,
  CDataTable,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from "@coreui/react";
import _get from "lodash/get";
import { filterDropdownData, priceString } from "src/utils/helper";
import { Formik } from "formik";
import { exchangeSchema, returnSchema } from "../../../config/formSchema";

const type = "exchangeReturns";
const requestTypeDropDown = [
  { label: "Exchange", value: 0 },
  { label: "Return", value: 1 },
];

const AddEditExchangeReturn = ({ isEdit, history, location: { state } }) => {
  if (!state) {
    history.push("/orders");
  }

  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.admin.modal);
  const variants = useSelector((s) => s.admin.variants.data.data);
  const [requestType, setRequestType] = useState(requestTypeDropDown[0]);
  const [products, setProducts] = useState([]);
  const [variant, setVariant] = useState();

  useEffect(() => {
    _get(state, "product_details_id", []).map((i) => (i.isSelected = false));
    setProducts(_get(state, "product_details_id", []));
  }, [state, requestType]);

  const selectProduct = (e, p) => {
    if (requestType.value === 0 && e.target.checked) {
      setVariant({
        id: p._id,
        quantity: p.quantity,
        originalQuantity: p.quantity,
        variant: "",
      });
      dispatch(
        fetchList("variants", { product_id: _get(p, "product_id._id", "") })
      );
    } else if (requestType.value === 0 && !e.target.checked) {
      setProducts(
        [...products].map((o) => {
          if (o._id === p._id) {
            return {
              ...o,
              requested_size_id: null,
              isSelected: e.target.checked,
            };
          } else return o;
        })
      );
    } else if (requestType.value === 1 && e.target.checked && p.quantity > 1) {
      setVariant({
        id: p._id,
        quantity: p.quantity,
        originalQuantity: p.quantity,
      });
    } else {
      setProducts(
        [...products].map((o) => {
          if (o._id === p._id) {
            return {
              ...o,
              isSelected: e.target.checked,
            };
          } else return o;
        })
      );
    }
  };

  const chooseVariant = (v) => {
    setProducts(
      [...products].map((o) => {
        if (o._id === v.id) {
          return {
            ...o,
            requested_size_id: _get(v, "variant.data"),
            requested_quantity: _get(v, "quantity"),
            isSelected: true,
          };
        } else return o;
      })
    );
    setVariant(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let ids = [];
    for (let i = 0; i < products.length; i++) {
      if (products[i].isSelected) {
        if (requestType.value === 0) {
          ids.push({
            product_id: _get(products[i], "product_id._id", ""),
            size_id: _get(products[i], "size_id._id"),
            quantity: _get(products[i], "requested_quantity"),
            requested_size_id: _get(products[i], "requested_size_id._id", ""),
          });
        } else {
          ids.push({
            product_id: _get(products[i], "product_id._id", ""),
            size_id: _get(products[i], "size_id._id"),
            quantity: _get(products[i], "requested_quantity")
              ? _get(products[i], "requested_quantity")
              : _get(products[i], "quantity"),
          });
        }
      }
    }
    if (_get(ids, "length")) {
      let data = {
        order_id: _get(state, "_id", ""),
        product_id: ids,
        status: requestType.value,
      };

      dispatch(addEditRequest(type, data, history));
    }
  };

  return (
    <PageContainer
      lg={12}
      title={isEdit ? "Edit Echange/Returns" : "Add Exchange/Return"}
    >
      <CForm onSubmit={onSubmit}>
        <FormInput
          type={"select"}
          label={"Action Type"}
          name={"status"}
          value={requestType}
          options={requestTypeDropDown}
          onChange={(v) => setRequestType(v)}
        />
        Ordered Products:
        <CDataTable
          items={products}
          fields={[
            "#",
            "select",
            "product_name",
            "size_purchased",
            "exchange_with",
            "requested_quantity",
            "price",
            "quantity",
            { key: "custom_on_front", label: "Personalization (F)" },
            { key: "custom_on_back", label: "Personalization (B)" },
            "total_price",
          ]}
          addTableClasses={"add-edit-exchange"}
          scopedSlots={{
            "#": (_, i) => <td>{i + 1}</td>,
            product_name: (p) => <td>{_get(p, "product_id.item_name", "")}</td>,
            size_purchased: ({ size_id }) => (
              <td>{`${_get(size_id, "size", "")} `}</td>
              //                   ${priceString(_get(size_id, "price", ""))}
            ),
            exchange_with: ({ requested_size_id }) => (
              <td>
                {requested_size_id
                  ? `${_get(requested_size_id, "size", "")}
                 `
                  : // ${priceString(_get(requested_size_id, "price", ""))}
                    ""}
              </td>
            ),
            requested_quantity: (item) => (
              <td>{_get(item, "requested_quantity", "")}</td>
            ),
            price: (p) => (
              <td>{priceString(_get(p, "product_id.price", 0))}</td>
            ),
            custom_on_front: (p) => (
              <td>
                {`${_get(p, "custom_on_front", "")} -
                      ${
                        _get(p, "custom_on_front_price", 0) > 0
                          ? priceString(_get(p, "custom_on_front_price", 0))
                          : ""
                      }`}
              </td>
            ),
            custom_on_back: (p) => (
              <td>
                {`${_get(p, "custom_on_back", "")} -
                 ${
                   _get(p, "custom_on_back_price", 0) > 0
                     ? priceString(_get(p, "custom_on_back_price", 0))
                     : ""
                 }`}
              </td>
            ),
            total_price: (p) => (
              <td>{priceString(_get(p, "total_price", 0))}</td>
            ),
            select: (p, i) => (
              <td>
                <FormInput
                  type="switch"
                  value={_get(p, "isSelected")}
                  onChange={(e) => selectProduct(e, p)}
                />
              </td>
            ),
          }}
        />
        <hr />
        <CButton type="submit" color="primary" disabled={loading}>
          {loading && (
            <CSpinner
              component="span"
              className="mr-2"
              size="sm"
              aria-hidden="true"
            />
          )}
          Submit
        </CButton>
      </CForm>
      <CModal
        show={_get(variant, "id", false)}
        onClose={() => setVariant(null)}
      >
        <CModalHeader closeButton>
          <CModalTitle>Choose Size</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {_get(variant, "id", false) && (
            <Formik
              initialValues={variant}
              validationSchema={
                requestType.value === 0 ? exchangeSchema : returnSchema
              }
              onSubmit={chooseVariant}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <CForm onSubmit={handleSubmit}>
                  {requestType.value === 0 && (
                    <FormInput
                      type="select"
                      label="Variant"
                      name={"variant"}
                      value={values["variant"]}
                      options={filterDropdownData(
                        variants,
                        ["size", "quantity"],
                        true
                      )}
                      onChange={(v) => setFieldValue("variant", v)}
                      invalid={errors["variant"] && touched["variant"]}
                      error={errors["variant"]}
                    />
                  )}
                  {values["originalQuantity"] > 1 && (
                    <FormInput
                      type="number"
                      label="Quantity"
                      name="quantity"
                      min={1}
                      max={values["originalQuantity"]}
                      value={values["quantity"]}
                      onChange={handleChange}
                      invalid={errors["quantity"] && touched["quantity"]}
                      error={errors["quantity"]}
                    />
                  )}
                  <hr />
                  <CButton type="submit" color="primary">
                    Submit
                  </CButton>
                </CForm>
              )}
            </Formik>
          )}
        </CModalBody>
      </CModal>
    </PageContainer>
  );
};

export default AddEditExchangeReturn;
