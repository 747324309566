import React, { Fragment } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CPagination,
  CElementCover,
  CSpinner,
  CSelect,
} from "@coreui/react";
const PageContainer = ({
  title,
  rightButtons,
  pagination,
  children,
  loading,
  ...rest
}) => {
  return (
    <CRow>
      <CCol {...rest}>
        <CCard>
          <CCardHeader
            className={"d-flex align-items-center justify-content-between"}
          >
            <h4 className={"mb-0"}> {title}</h4>
            <div className="d-flex flex-row align-items-center">
              {!!rightButtons &&
                rightButtons.map((b, i) => {
                  return b.type === "file" ? (
                    <Fragment key={i}>
                      <label htmlFor="file-input" className="file_upload">
                        {b.label}
                        {b.loading && (
                          <CSpinner
                            component="span"
                            className="ml-2"
                            size="sm"
                            aria-hidden="true"
                          />
                        )}
                      </label>
                      <input
                        type="file"
                        id="file-input"
                        accept={b.accept || ""}
                        hidden
                        onChange={b.onClick}
                      />
                    </Fragment>
                  ) : (
                    <CButton
                      key={i}
                      color={b.color || "primary"}
                      variant={b.variant || ""}
                      className={"ml-2"}
                      onClick={b.onClick}
                      disabled={b.disabled || b.loading}
                      type={b.type}
                    >
                      {b.label}
                      {b.loading && (
                        <CSpinner
                          component="span"
                          className="ml-2"
                          size="sm"
                          aria-hidden="true"
                        />
                      )}
                    </CButton>
                  );
                })}
            </div>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <CElementCover
                boundaries={[
                  { sides: ["top"], query: "td" },
                  { sides: ["bottom"], query: "tbody" },
                ]}
              />
            ) : (
              children
            )}
            {!!pagination && (
              <>
                <hr className="mt-0" />
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="mr-1">Limit</span>
                  <CSelect
                    onChange={(e) => pagination.setLimitPerPage(e.target.value)}
                  >
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={35}>35</option>
                    <option value={50}>50</option>
                  </CSelect>
                </div>
                <CPagination
                  activePage={pagination.activePage}
                  onActivePageChange={pagination.onActivePageChange}
                  pages={pagination.pages}
                  doubleArrows={true}
                  align="center"
                />
              </>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default PageContainer;
